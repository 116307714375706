const ProjectsTable = () => {

    return (
        <tr className="table-accord-tbody table-accord-tbody-js">
            <td className="table-accord" colSpan={7}>
                <div className="table-accord-tbody--slider table-accord-tbody-slider-js" style={{ paddingLeft: 28 }}>
                    <table cellPadding="0" cellSpacing="0" className="table">
                        <thead>
                            <tr>
                                <td className="--sort col-size col-size-0">
                                    <p className="th-text">Project Name</p>
                                </td>
                                <td className="--sort col-size col-size-1">
                                    <p className="th-text">Project Type</p>
                                </td>
                                <td className="--sort col-size col-size-2">
                                    <p className="th-text">Est. Volume</p>
                                </td>
                                <td className="--sort col-size col-size-3">
                                    <p className="th-text">Des. Retainer</p>
                                </td>
                                <td className="--sort col-size col-size-4">
                                    <p className="th-text">Status</p>
                                </td>
                                <td className="--sort col-size col-size-5"></td>
                                <td className="--sort col-size col-size-6 --dop-class-last-sticky"></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-size col-size-0">
                                    <div className="table-select-container">
                                        <label className="check-wrapper table-gap-x">
                                            <input type="checkbox" name="check" hidden/>
                                            <span className="check check--xxs check-custom-2"></span>
                                        </label>
                                        <div className="table-user-info-texts">
                                            <a href="#" className="btn btn-link color-secondary tdu">
                                                <span className="text--md font-400">Modern Kitchen Remodel</span>
                                            </a>
                                            <p className="color-control-2 text--sm font-400">
                                                8502 Preston Rd. Inglewood, Maine
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className="col-size col-size-1">
                                    <div className="pill-custom pill--custom-3 pill--sm">
                                        <span>Project Type</span>
                                    </div>
                                </td>
                                <td className="col-size col-size-2">
                                    <p className="font-500 table-text-nowrap">$350,000</p>
                                </td>
                                <td className="col-size col-size-3">
                                    <p className="font-500 table-text-nowrap">$5,000</p>
                                </td>
                                <td className="col-size col-size-4" colSpan={2}>
                                    <div className="pill-custom pill--custom-1 pill--sm">
                                        <span>Customer Agreement</span>
                                    </div>
                                </td>
                                <td className="col-size col-size-6 --dop-class-last-sticky">
                                    <div className="dropdown" data-position="bottom-end" data-dropdown="dropdown">
                                        <button type="button" className="btn btn--xs
                                                                                        btn--custom-1 rounded-full max-w-max dropdown-btn" data-role="button">
                                            <span>Actions</span>
                                            <span className="ico">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.82495 6.91248L8.99995 11.0875L13.175 6.91248" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                        </button>
                                        <div className="dropdown__body dropdown__table" data-role="dropdown">
                                            <div className="dropdown-block">
                                                <div className="dropdown-block--body">
                                                    <ul className="dropdown-list">
                                                        <li className="dropdown-list--item">
                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                </span>
                                                                <span className="info">Edit Info</span>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-list--item">
                                                            <div className="btn btn--xs drp-list-wrapp-separator">
                                                                <hr className="drp-list-separator"/>
                                                            </div>
                                                        </li>
                                                        <li className="dropdown-list--item">
                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                </span>
                                                                <span className="info">Edit Info</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </td>
        </tr>
    );

};

export default ProjectsTable;
