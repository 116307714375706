import EditIcon from "@/assets/img/ico-edit.svg?react";
import UserIcon from "@/assets/img/ico-user.svg?react";
import projectImage from "@/assets/img/project-img-1.jpg";
import DropdownActions from "@/components/common/DropdownActions";
import { routerConfig } from "@/routes";
import { getFullUrl } from "@/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router";

import Table from "@/components/common/Table";

interface FinancialProps {
	isGirdView: boolean
	isTableView: boolean
}

interface ProjectData {
	id: string;
	projectName: string;
	clientName: string;
	totalBudget: number;
	expensesToDate: number;
	revenueToDate: number;
	profitLoss: number;
	outstanding: number;
	status: "onTrack" | "delayed" | "completed" | "paused";
}


const Financial = ({ isGirdView, isTableView }: FinancialProps) => {

    const data: ProjectData[] = [{
        id: "1",
        projectName: "Modern Kitchen Remodel",
        clientName: "John Doe",
        totalBudget: 50000,
        expensesToDate: 32000,
        revenueToDate: 30000,
        profitLoss: -2000,
        outstanding: 2000,
        status: "onTrack"
    }, {
        id: "2",
        projectName: "Office Building Expansion",
        clientName: "Jane Smith",
        totalBudget: 75000,
        expensesToDate: 45000,
        revenueToDate: 42000,
        profitLoss: -3000,
        outstanding: 3000,
        status: "delayed"
    }, {
        id: "3",
        projectName: "Hospital Renovation",
        clientName: "Robert Brown",
        totalBudget: 120000,
        expensesToDate: 90000,
        revenueToDate: 85000,
        profitLoss: 5000,
        outstanding: 10000,
        status: "completed"
    }, {
        id: "4",
        projectName: "Library Revamp",
        clientName: "Alice Johnson",
        totalBudget: 30000,
        expensesToDate: 18000,
        revenueToDate: 16000,
        profitLoss: -2000,
        outstanding: 2000,
        status: "paused"
    }
        // More sample projects can be added here
    ];


    const actions = (arg: ProjectData) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => console.log(arg)
    }];

    if (isGirdView) {

        return (
            <div className="row gutters-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">
                    <div className="card-widget card-widget-product w-full h-full">
                        <div className="card-widget-body">
                            <div className="card-details-wrpr">
                                <div className="card-details--item">
                                    <div className="card-details--head">
                                        <div className="user-option-block user-option-block--sm">
                                            <div className="user-block-relatives">
                                                <div className="ico ico--lg user-block-img-wrpr">
                                                    <img src={projectImage} alt="project" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-details-actions">
                                            <DropdownActions actions={[]} />
                                        </div>
                                    </div>
                                    <div className="card-details--body">
                                        <div className="card-details-heading">
                                            <p className="heading user-block-title text--xl">
                                                Modern Kitchen Remodel
                                            </p>
                                        </div>

                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <UserIcon />
                                                    </span>
                                                    <p className="project-variable--text">Client Title</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-statuses">
                                                    <div className="pill-custom pill--custom-1 pill--sm"><span>On Track</span></div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="card-details--item">
                                    <div className="card-details--body">
                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Total Budget:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">$50,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Expenses to Date:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">$32,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Revenue to Date:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">$30,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Profit/Loss:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500 color-status-6">-$2,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Outstanding:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">$2,000</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="card-details--actions">
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span>Change Status</span>
                                                </a>
                                            </div>
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span className="ico"><EditIcon /></span>
                                                    <span>Edit</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    const columns: ColumnDef<ProjectData>[] = [{
        header: "Project Name",
        accessorKey: "projectName",
        minSize: 228,
        cell: ({ row: { original } }) => <div className="table-user-info-texts">
            <Link to={getFullUrl([routerConfig.projects.path, original.id])} className="btn btn-link color-secondary tdu">
                <span className="text--md font-400">{original.projectName}</span>
            </Link>
        </div>

    },
    {
        header: "Client",
        accessorKey: "clientName",
        minSize: 150,
        cell: ({ row: { original } }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{original.clientName}</span>
        </a>

    },
    {
        header: "Total Budget",
        accessorKey: "totalBudget",
        cell: ({ row: { original } }) => <p className="font-500">{`$${original.totalBudget}`}</p>

    },
    {
        header: "Expenses to Date",
        accessorKey: "expensesToDate",
        cell: ({ row: { original } }) => <p className="color-control-3">{`$${original.expensesToDate}`}</p>

    },
    {
        header: "Revenue to Date",
        accessorKey: "revenueToDate",
        cell: ({ row: { original } }) => <p className="color-control-3">{`$${original.revenueToDate}`}</p>

    },
    {
        header: "Profit/Loss",
        accessorKey: "profitLoss",
        cell: ({ row: { original } }) => <p className={original.profitLoss < 0
            ? "color-status-6"
            : "color-status-2"}>
            {original.profitLoss < 0
                ? `-$${Math.abs(original.profitLoss)}`
                : `+$${original.profitLoss}`}
        </p>

    },
    {
        header: "Outstanding",
        accessorKey: "outstanding",
        cell: ({ row: { original } }) => <p className="color-control-3">{`$${original.outstanding}`}</p>

    },
    {
        header: "Status",
        accessorKey: "status",
        minSize: 180,
        cell: ({ row: { original } }) => <div className="pill-custom pill--custom-1 pill--sm">
            <span>{original.status}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            variant="secondary"
            actions={actions(original)}
        />

    }];


    return (
        <Table columns={columns} data={data} selectable />
    );

};

export default Financial;
