import CalendarIcon from "@/assets/img/ico-calendar.svg?react";
import clsx from "clsx";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type DatepickerOutlineProps = {
    selectedDate?: Date | null | string;
    onChange?: (date: Date | null) => void;
    dateFormat?: string;
    placeholder?: string;
    className?: string;
    wrapperClassName?: string;
    error?: string | boolean;
};

const DatepickerOutline: React.FC<DatepickerOutlineProps> = ({
    selectedDate = null,
    onChange,
    dateFormat = "MM/dd/yyyy",
    placeholder = "Select a date",
    className,
    wrapperClassName,
    error
}) => {

    return (
        <div className={clsx("form-group input--md input--icon-right", wrapperClassName)}>
            <div className="input-wrapper">
                <ReactDatePicker
                    selected={selectedDate ? new Date(selectedDate) : null}
                    onChange={onChange}
                    dateFormat={dateFormat}
                    placeholderText={placeholder}
                    className={clsx("input input--outline w-full", className)}
                    wrapperClassName="datepicker-1 w-full"
                    showIcon
                    icon={
                        <span className="ico color-secondary">
                            <CalendarIcon />
                        </span>
                    }
                />
            </div>
            {typeof error === "string" && <p className="error-message">{error}</p>}
        </div>
    );

};

export default DatepickerOutline;
