
import IconSelect from "@/components/common/IconSelect";
import Input from "@/components/common/Input";
import RadioGroup from "@/components/common/RadioGroup";
import Select, { OptionCustomProps } from "@/components/common/Select";
import { departmentOptions, statusOptions } from "@/constants/options";
import { useGetAllUsersQuery } from "@/api/usersApi";
import { ICONS } from "@/utils/teamsIcons";
import { ClientFormRequest } from "@/validation/clientSchema";
import { TeamFormRequest } from "@/validation/teamSchema";
import { Controller, UseFormReturn } from "react-hook-form";


interface TeamDetailsProps {
    formState: UseFormReturn<TeamFormRequest>
}

const TeamDetails = ({ formState }: TeamDetailsProps) => {
    const { data: response } = useGetAllUsersQuery()

    const { register, watch, setValue, control, formState: { errors, defaultValues } } = formState;

    const handleClientTypeChange = (option: OptionCustomProps<number>) => {

        setValue("TeamLeadId", option.value as TeamFormRequest["TeamLeadId"]);

    };
    const handleDepartamentChange = (option: OptionCustomProps<number>) => {

        setValue("DepartmentId", option.value as TeamFormRequest["DepartmentId"]);

    };
    const handleStatusChange = (value: string) => {

        setValue("status", value as ClientFormRequest["status"]);

    };

    const teamLeadOptions: OptionCustomProps<number>[] = response?.data?.map(user => ({ label: user.firstName, value: user.id })) || []

    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Icon</span>
                    </div>
                    <div className="col-8">
                        <Controller 
                            name="icon"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <IconSelect initialIcon={value as keyof typeof ICONS} onSelect={onChange} />
                            )}
                        />
                        
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Team Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("name")}
                            placeholder="Enter team name..."
                            error={errors.name?.message}
                        />

                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Department</span>
                    </div>
                    <div className="col-8">
                        {/* <div className="tabs-navigation">
                            <div className="tabs-navigation-list --list-2"> */}
                                <Select
                                    options={departmentOptions}
                                    onChange={handleDepartamentChange}
                                    value={watch("DepartmentId")}
                                    error={errors.DepartmentId?.message}
                                />
                            {/* </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Team Lead</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={teamLeadOptions}
                            onChange={handleClientTypeChange}
                            value={watch("TeamLeadId")}
                            error={errors.TeamLeadId?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Status</span>
                    </div>
                    <div className="col-8">
                        <RadioGroup
                            options={statusOptions}
                            onChange={handleStatusChange}
                            value={watch("status")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default TeamDetails;
