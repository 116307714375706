

import EditIcon from "@/assets/img/ico-edit.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import WidgetCard from "@/components/cards/WidgetCard";


const Notes = () => {

    return (
        <div className="block-container tab-body-fullpage">
            <div className="row g-20">
                <div className="col-xl-8 col-lg-8 col-md-12 col-12 flex flex-col">
                    <div className="description-info">
                        <div className="description-info__actions">
                            <a href="#" className="btn btn--secondary-advance-1 btn--xs radius-full">
                                <span className="ico"><StarsIcon /></span>
                                <span>Analyze</span>
                            </a>


                            <a href="#" className="btn btn--custom-1 btn--xs radius-full">
                                <span className="ico"><EditIcon /></span>
                                <span className="info">Edit Info</span>
                            </a>

                        </div>
                        <div className="description-info__body">
                            <h2>Custom Note Title 1</h2>

                            <p className="description-info__text">Michael Johnson, the Lead Designer, provided several updates throughout the project. On October 10, 2024, he shared the initial design concepts for the kitchen remodel, requesting feedback from the client by the following Monday. This initial set of designs focused on enhancing functionality and aesthetics, providing a modern and open layout that aligns with the client’s vision.<br /><br />
                                By October 15, 2024, Michael revised the kitchen layout, incorporating the client’s feedback to increase storage capacity. He specifically added additional cabinets and optimized the pantry area to meet the client’s need for better organization. The revised design received positive feedback, and on October 20, the client approved the final concept, allowing the project to move into detailed planning.<br /><br />
                                On November 1, 2024, Michael uploaded the updated kitchen remodel blueprint, which included structural modifications based on feedback from the engineering team. He also addressed a client request for more drawers instead of shelves in the cabinetry, which he completed on November 3, 2024. These changes aimed to enhance both functionality and convenience for the client, ensuring the kitchen meets their expectations.</p>


                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 flex flex-col">
                    <NotesWidget />
                </div>
            </div>
        </div>
    );

};

function NotesWidget () {

    return (
        <WidgetCard title="Notes" showAdd className="card-widget-scroller">
            <ul className="projects-list">
                {Array.from({ length: 30 }).map((_, index) => <li key={index} className="project-item">
                    <label className="fake-btn">
                        <input
                            type="radio"
                            checked={index === 0}
                            hidden
                        />
                        <div className="project-link btn">
                            <div className="project-link-content">
                                <p className="project-link-title">Custom Note Title 1</p>
                                <p className="project-link-desk">01.02.2024</p>
                            </div>
                        </div>
                    </label>
                </li>)}
            </ul>
        </WidgetCard>
    );

}

export default Notes;
