import { ModalProps } from "@/components/modals/Modal";
import TeamData from "./components/TeamData";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";

import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import { ITeamFormData, Team } from "@/types";
import { useTeamCsvData } from "@/hooks/useTeamCsvData";
import { useState } from "react";
import ModalLoader from "@/components/layout/ModalLoader";

interface NewTeamModal extends ModalProps {}

const completeActionButtons = [{
    text: "Add Project",
    handler: () => { }
}, {
    text: "Add Task",
    handler: () => { }
}];

const NewTeamModal = ({ ...props }: NewTeamModal) => {
    const [data, setData] = useState<ITeamFormData>();

    const { currentStage,
        handleCompleteClick,
        handleContinueClick,
        handleCancel,
        handleGoToSelection
    } = useNewEntityModal();

    
    const {isProcessing, getTeamDataFromCsv } = useTeamCsvData()

    const handleParseTeam = async (file: File) => {
        try {
            const parsedTeam = await getTeamDataFromCsv(file);

            setData(parsedTeam);
            handleContinueClick();
        } catch(error) {
            console.error(error);
        }
    }

    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New Team Created!"
            completeDescription="Congratulations on successfully creating a new team! You can now start managing members, projects or assign tasks."
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
            handleBackToSelectionClick={handleGoToSelection}
            handleCompleteClick={handleCompleteClick}
            handleParseClick={handleParseTeam}
            exampleFileLink="csvTeamExample.csv"
        >
            {isProcessing ? <ModalLoader /> 
                : 
                <TeamData 
                    data={data} 
                    handleCancel={handleCancel} 
                    handleCompleteClick={handleCompleteClick} 
                />
            }
            
        </NewEntityModal>
    );

};

export default NewTeamModal;
