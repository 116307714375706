
import Button from "@/components/common/Button";
import Input from "@/components/common/Input";
import RadioGroup from "@/components/common/RadioGroup";
import Select from "@/components/common/Select";
import UploadPhoto from "@/components/common/UploadPhoto";
import { clientTypeOptions, insuranceOptions, riskLevelOptions, statusMaterialOptions, statusMateriaOrderlOptions, statusOptions } from "@/constants/options";
import { ClientFormRequest } from "@/validation/clientSchema";
import { MaterialSchemaRequest } from "@/validation/materialSchema";
import { MaterialOrderSchemaRequest } from "@/validation/orderMaterialscema";
import { Controller, UseFormReturn } from "react-hook-form";


interface OrderDetailsProps {
    formState: UseFormReturn<MaterialOrderSchemaRequest>
}

const OrderDetails = ({ formState }: OrderDetailsProps) => {

    const { register, watch, setValue, control, formState: { errors, defaultValues } } = formState;

    const handleStatusChange = (value: string) => {
        setValue("status", value as MaterialOrderSchemaRequest["status"]);
    };
   
    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Order Number</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("orderNumber")}
                            placeholder="Enter order number here..."
                            error={errors.orderNumber?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Material</span>
                    </div>
                    <div className="col-8">
                        <Controller
                            name="material"
                            control={formState.control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={[
                                        { label: "Material", value: "Material" },
                                        { label: "Customer", value: "Customer" },
                                    ]}
                                    placeholder={'Select material'}
                                    onChange={(option) => {
                                        field.onChange(option.value)
                                    }}
                                    value={field.value}
                                    error={errors.material?.message}
                                />
                            )}
                        >
                        </Controller>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Supplier</span>
                    </div>
                    <div className="col-8">
                        <Controller
                            name="supplier"
                            control={formState.control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={[
                                        { label: "Supplier", value: "Supplier" },
                                        { label: "Customer", value: "Customer" },
                                    ]}
                                    onChange={(option) => {
                                        field.onChange(option.value)
                                    }}
                                    placeholder={'Select supplier'}
                                    value={field.value}
                                    error={errors.supplier?.message}
                                />
                            )}
                        >
                        </Controller>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Ordered Quantity</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("orderedQuantity")}
                            placeholder="Enter quantity here..."
                            error={errors.orderedQuantity?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Related Project</span>
                    </div>
                    <div className="col-8">
                        <Controller
                            name="relatedProject"
                            control={formState.control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={[
                                        { label: "Related Project", value: "Related Project" },
                                        { label: "Customer", value: "Customer" },
                                    ]}
                                    onChange={(option) => {
                                        field.onChange(option.value)
                                    }}
                                    placeholder = {'Select project'}
                                    value={field.value}
                                    error={errors.relatedProject?.message}
                                />
                            )}
                        >
                        </Controller>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Order Date</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("orderDate")}
                            placeholder="Enter date here..."
                            error={errors.orderDate?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Status</span>
                    </div>
                    <div className="col-8">
                        <RadioGroup
                            options={statusMateriaOrderlOptions}
                            onChange={handleStatusChange}
                            value={watch("status")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default OrderDetails;
