
import Textarea from "@/components/common/Textarea";
import { ProjectFormRequest } from "@/validation/projectSchema";
import { UseFormReturn } from "react-hook-form";


interface DescriptionProps {
    formState: UseFormReturn<ProjectFormRequest>
}

const Description = ({ formState }: DescriptionProps) => {

    const { register, watch, setValue, formState: { errors, defaultValues } } = formState;


    return (
        <div className="row g-20">
            <div className="col-12">
                <Textarea
                    label="Project Description"
                    labelClassName="color-default fw-500"
                    className="input--md"
                    {...register("description")}
                    placeholder="Enter description..."
                    error={errors.description?.message}
                    rows={12}
                />
            </div>
        </div>
    );

};

export default Description;
