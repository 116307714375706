import { useTabs } from "@/hooks/useTabs";
import clsx from "clsx";
import React from "react";
import Profile from "./tabs/Profile";
import { routerConfig } from "@/routes";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import PasswordSecurity from "./tabs/PasswordSecurity";
import Customization from "./tabs/Customization";
import Notifications from "./tabs/Notifications";
import Integrations from "./tabs/Integrations";

const tabs = [{
    label: "Profile",
    id: "profile"
},
{
    label: "Password & Security",
    id: "password-security"
},
{
    label: "Notifications",
    id: "notifications"
},
{
    label: "Customization",
    id: "customization"
},
{
    label: "Integrations",
    id: "integrations"
}] as const;
type TabId = typeof tabs[number]["id"];

const AccountSettingsPage = () => {

    useBreadcrumbs([routerConfig.settings]);
    const { activeTab, setActiveTab } = useTabs(tabs);
    const tabComponents: Record<TabId, JSX.Element> = {
        profile: <Profile />,
        "password-security": <PasswordSecurity />,
        notifications: <Notifications />,
        customization: <Customization />,
        integrations: <Integrations />
    };

    const renderTab = () => {

        return tabComponents[activeTab];

    };

    return (
        <div className="content">
            <div className="tabs">
                <div className="container">
                    <div className="header-options-wrap">
                        <div className="header-options">
                            {
                                tabs.map(({ id, label }) => <button
                                    key={id}
                                    className={clsx("btn tab-link btn--xs", { "active-tab": activeTab === id })}
                                    onClick={() => setActiveTab(id)}
                                >
                                    {label}
                                </button>)
                            }
                        </div>
                    </div>
                    <div className="tab-body">
                        <div className="tab active-tab">
                            {renderTab()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default AccountSettingsPage;
