import React, {useState} from "react";
import clsx from "clsx";
import {UseFormReturn} from "react-hook-form";
import {ColumnDef} from "@tanstack/react-table";

import {useGetAllUsersQuery} from "@/api/usersApi";
import {Team, User} from "@/types";
import {EventFormRequest} from "@/validation/eventSchema";
import {statusMap} from "@/constants";
import {getFullName} from "@/utils";

import PageLoader from "@/components/layout/PageLoader";
import Table from "@/components/common/Table";
import Select, {OptionCustomProps} from "@/components/common/Select";
import DropdownActions, {DropdownActionsProps} from "@/components/common/DropdownActions";
import Image from "@/components/common/Image";
import AddIcon from "@/assets/img/ico-add.svg?react";
import {useGetAllTeamsQuery} from "@/api/teamsApi";
import {ICONS} from "@/utils/teamsIcons";

interface ParticipantsProps {
    formState: UseFormReturn<EventFormRequest>;
}

const userTypeOptions: OptionCustomProps<string>[] = [
    {label: "Members", value: "members"},
    {label: "Teams", value: "teams"},
];

const Participants = ({formState}: ParticipantsProps) => {
    const {setValue, formState: {errors}} = formState;
    const [role, setRole] = useState(userTypeOptions[0].value);
    const {data: response, isLoading} = useGetAllUsersQuery();
    const {data: teamResponse} = useGetAllTeamsQuery();
    const users = response?.data || [];
    const teams = teamResponse?.data || [];

    const [selected, setSelected] = useState< any| null>(null);


    const [participants, setParticipants] = useState<User[]>([]);

    if (isLoading) {
        return <PageLoader/>;
    }

    const participantOptions = [role === "members" ? [...users] : [...teams]].flat().map((user: any): any => ({
        value: user.id,
        label: (
            <div className="row-group gap-20">
                <span className="ico ico-ava ico-ava-24">
                    {user?.photo ?
                        <Image src={user.photo} alt={"photo"}/>
                        :
                        <span className="ico">
                            {user.icon === "pm" && <ICONS.pm/>}
                            {user.icon === "expediter" && <ICONS.expediter/>}
                            {user.icon === "supply" && <ICONS.supply/>}
                        </span>
                    }
                </span>
                <span className="text--md font-400">{getFullName(user)}</span>
            </div>
        ),
        user,
    }));

    const handleAddMember = () => {
        if (!selected) return;

        const newParticipants: any[] = selected.members?.length > 0
            ? [...selected.members]
            : [selected];

        setParticipants((prev) => [...prev, ...newParticipants]);

        const ids = [
            ...participants.map((one) => one.id),
            ...newParticipants.map((one) => one.id),
        ];

        setValue("participants", Array.from(new Set(formState.getValues().participants.concat(ids))));

        setSelected(null);
    };


    const actions = (user: User): DropdownActionsProps["actions"] => [
        {
            label: "Delete",
            icon: "",
            fn: () => setParticipants((prev) => prev.filter((p) => p.id !== user.id)),
        },
    ];

    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row-group gap--xs">
                    <div className="relative w-full">
                        <Select
                            className="input--md flex-auto"
                            placeholder={role === "members" ? "Add participant by name or email..." : "Add team by name..."}
                            options={participantOptions.filter(
                                (option) => !participants.some((participant) => participant.id === option.user.id)
                            )}
                            filterOption={(option: any, inputValue) => {
                                if (!inputValue) return true;
                                const {user} = option.data;
                                const fullName = getFullName(user).toLowerCase();
                                const email = user.email.toLowerCase();
                                return (
                                    fullName.includes(inputValue.toLowerCase()) ||
                                    email.includes(inputValue.toLowerCase())
                                );
                            }}
                            value={selected?.id || null}
                            onChange={(option: any) => {
                                if (!option) return;
                                setSelected(option.user);
                            }}
                        />

                        <Select
                            options={userTypeOptions}
                            onChange={(option) => setRole(option.value)}
                            value={role}
                            isSearchable={false}
                            wrapperClassName="input-select"
                            error={errors?.pipelinePhase?.message}
                        />
                    </div>

                    <div>
                        <button
                            type="button"
                            className="btn btn--primary-advance-1 btn--md rounded-full nowrap"
                            onClick={handleAddMember}
                            disabled={!selected}
                        >
                          <span className="ico">
                            <AddIcon/>
                          </span>
                            <span> Add {role === "members" ? "Member" : "Team"}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <ParticipantsTable data={participants} actions={actions}/>
            </div>
        </div>
    );
};

interface ParticipantsTableProps {
    data: User[];
    actions: (arg: User) => DropdownActionsProps["actions"];
}

function ParticipantsTable({data, actions}: ParticipantsTableProps) {
    const columns: ColumnDef<User>[] = [
        {
            header: "User Name",
            accessorKey: "firstName",
            cell: ({row}) => {
                const original = row.original as any;
                return (
                    <div className="table-user-info-texts flex gap-12 flex-row items-center">
                           <span className="ico ico-ava ico-ava-24">
                            {original?.photo ?
                                <Image src={original.photo} alt={"photo"}/>
                                :
                                <span className="ico">
                                    {original.icon === "pm" && <ICONS.pm/>}
                                    {original.icon === "expediter" && <ICONS.expediter/>}
                                    {original.icon === "supply" && <ICONS.supply/>}
                                </span>
                            }
                       </span>
                        <span className="text--md font-400">{getFullName(original)}</span>
                    </div>
                );
            },
        },
        {
            header: "Role",
            accessorKey: "profession",
            cell: ({row}) => {
                const original = row.original;
                return (
                    <div className="pill-custom pill--custom-3 pill--sm">
                        <span className="text--md font-500">{original.profession}</span>
                    </div>
                );
            },
        },
        {
            header: "Status",
            accessorKey: "status",
            cell: ({row}) => {
                const original = row.original;
                return (
                    <div className={clsx("pill-custom pill--sm", statusMap["active"].className)}>
                        <span>{statusMap[original.status]?.label}</span>
                    </div>
                );
            },
        },
        {
            header: "Actions",
            accessorKey: "actions",
            enableSorting: false,
            enablePinning: true,
            cell: ({row}) => {
                const original = row.original;
                return <DropdownActions variant="secondary" actions={actions(original)}/>;
            },
        },
    ];

    return (
        <div className="table-accord">
            <Table columns={columns} data={data} hideFooter/>
        </div>
    );
}

export default Participants;
