
import Button from "@/components/common/Button";
import Input from "@/components/common/Input";
import RadioGroup from "@/components/common/RadioGroup";
import Select from "@/components/common/Select";
import UploadPhoto from "@/components/common/UploadPhoto";
import { clientTypeOptions, insuranceOptions, riskLevelOptions, statusMaterialOptions, statusOptions } from "@/constants/options";
import { ClientFormRequest } from "@/validation/clientSchema";
import { MaterialSchemaRequest } from "@/validation/materialSchema";
import { MaterialOrderSchemaRequest } from "@/validation/orderMaterialscema";
import { Controller, UseFormReturn } from "react-hook-form";


interface MaterialDetailsProps {
    formState: UseFormReturn<MaterialSchemaRequest>
}

const MaterialDetails = ({ formState }: MaterialDetailsProps) => {

    const { register, watch, setValue, control, formState: { errors, defaultValues } } = formState;

    const handleStatusChange = (value: string) => {
        setValue("status", value as MaterialSchemaRequest["status"]);
    };
   
    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Photo</span>
                    </div>
                    <div className="col-8">
                        <UploadPhoto
                            onChange={(file) => setValue("photo", file)}
                            value={watch("photo") as File | string}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Material Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("materialName")}
                            placeholder="Enter material name here..."
                            error={errors.materialName?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Current Quantity</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("currentQuantity")}
                            placeholder="Enter quantity here..."
                            error={errors.currentQuantity?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Supplier</span>
                    </div>
                    <div className="col-8">
                        <Controller
                            name="supplier"
                            control={formState.control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={[
                                        { label: "Supplier1", value: "Supplier1" },
                                        { label: "Supplier2", value: "Supplier2" },
                                    ]}
                                    onChange={(option) => {
                                        field.onChange(option.value)
                                    }}
                                    value={field.value}
                                    error={errors.supplier?.message}
                                />
                            )}
                        >
                        </Controller>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Status</span>
                    </div>
                    <div className="col-8">
                        <RadioGroup
                            options={statusMaterialOptions}
                            onChange={handleStatusChange}
                            value={watch("status")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default MaterialDetails;
