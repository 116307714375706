import React from "react";
import Spinner from "./Spinner";
import clsx from "clsx";

type ButtonHtmlTypes = "button" | "submit" | "reset";
type ButtonSize = 'tiny' | 'small' | 'medium' | 'large' | 'extra-large'
type ButtonUiTypes = 'primary' | 'primary-outlined' | 'primary-advance-1' | 'primary-advance-2'  | 'secondary' | 'secondary-outlined' | 'secondary-advance-1' | 'secondary-advance-2' | 'custom-1' | 'custom-2' | 'transparent' | 'default' | 'default-outlined';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    type?: ButtonHtmlTypes;
    uiType?: ButtonUiTypes;
    size?: ButtonSize;
    loading?: boolean;
    isSquare?: boolean;
    isRounded?: boolean;
    isWithoutPaddings?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    children,
    className = "",
    onClick,
    type = "button",
    size = "medium",
    uiType = 'primary',
    loading,
    isSquare = false,
    isRounded = true,
    isWithoutPaddings = false,
    ...props
}) => {

    return (
        <button
            className={clsx("btn", className, { 
                disabled: props.disabled,
                'btn--xs': size === 'tiny',
                'btn--sm': size === 'small',
                'btn--md': size === 'medium',
                'btn--lg': size === 'large',
                'btn--xl': size === 'extra-large',
                'btn--square': isSquare,
                'btn--primary': uiType === 'primary',
                'btn--outline-primary': uiType === 'primary-outlined',
                'btn--primary-advance-1': uiType === 'primary-advance-1',
                'btn--primary-advance-2': uiType === 'primary-advance-2',
                'btn--secondary': uiType === 'secondary',
                'btn--outline-secondary': uiType === 'secondary-outlined',
                'btn--secondary-advance-1': uiType === 'secondary-advance-1',
                'btn--secondary-advance-2': uiType === 'secondary-advance-2',
                'btn--custom-1': uiType === 'custom-1',
                'btn--custom-2': uiType === 'custom-2',
                'btn--custom-1-trsp': uiType === 'transparent',
                'btn--default': uiType === 'default',
                'btn--outline-default': uiType === 'default-outlined',
                'p-0': isWithoutPaddings,
                'rounded-full': isRounded,
            })}
            type={type}
            onClick={onClick}
            {...props}
        >
            {loading && <div className="btn-loader"><Spinner /></div>}
            {children}
        </button>
    );

};

export default Button;
