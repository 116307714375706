import Filters from "@/components/common/Filters";
import Search from "@/components/common/Search";
import ViewModeSwitcher, { useViewMode } from "@/components/common/ViewModeSwitcher";

import AddIcon from "@/assets/img/ico-add.svg?react";

import DatepickerOutline from "@/components/common/DatepickerOutline";
import TabNavigation from "@/components/common/TabNavigation";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { useTabs } from "@/hooks/useTabs";
import { routerConfig } from "@/routes";
import clsx from "clsx";
import InvoicesTab from "./tabs/InvoicesTab";
import OverviewTab from "./tabs/OverviewTab";
import StatisticsTab from "./tabs/StatisticsTab";


const tabs = [{
    id: "overview",
    label: "Overview"
}, {
    id: "invoices",
    label: "Invoices"
}, {
    id: "statistics",
    label: "Statistics"
}] as const;

type TabId = typeof tabs[number]["id"];

const BudgetBillingPage = () => {

    useBreadcrumbs([routerConfig.budget]);

    const { activeTab, setActiveTab } = useTabs(tabs);
    const { isTableView, isGridView } = useViewMode();

    const tabComponents: Record<TabId, JSX.Element> = {
        overview: <OverviewTab isGridView={isGridView} isTableView={isTableView} />,
        invoices: <InvoicesTab isGridView={isGridView} isTableView={isTableView} />,
        statistics: <StatisticsTab />
    };

    const renderTab = () => {

        return tabComponents[activeTab];

    };


    return (
        <div className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="rectangle-block-containers block-container-fullpage tabs">
                    <div className="block-container navigation-block">
                        <div className="heading--row">
                            <div className="heading-option">
                                <DatepickerOutline />
                                <span className="option-separator"></span>
                                <TabNavigation
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    renderTabCount={() => 3}
                                />
                            </div>
                            <div className="heading-option">
                                {activeTab !== "statistics" &&
                                    <>
                                        <ViewModeSwitcher />
                                        <span className="option-separator"></span>
                                    </>
                                }

                                <div className="flex-controls gap--xs">
                                    <Filters />
                                    <Search />
                                    <button type="button" className="btn btn--primary-advance-1 btn--md rounded-full btn--dop-class">
                                        <span className="ico"><AddIcon /></span>
                                        <span>New Project</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-container page-results">
                        <div className="view-page-block tabs-fullpage active-page">
                            <div className={"tab active-tab"}>
                                {renderTab()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};


export default BudgetBillingPage;
