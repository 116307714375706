import { ReactNode } from "react";
import AddIcon from "@/assets/img/ico-add.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import clsx from "clsx";
import AssistantButton from "../common/AssistantButton";


interface WidgetCardProps {
    children: ReactNode
    title: string
    subtitle?: ReactNode
    showMore?: boolean
    showAdd?: boolean
    showEdit?: boolean
    className?: string
    bodyClassName?: string
    headerClassName?: string
    headerColumn?: boolean
}

const WidgetCard = ({ children, title, subtitle, showMore, showAdd, showEdit, className, bodyClassName, headerClassName, headerColumn }: WidgetCardProps) => {

    return (
        <div className={clsx("card-widget w-full h-full", className)}>
            <div className={clsx("card-widget-header", headerClassName)}>
                <div className={"card-widget-header-item"}>
                    {headerColumn
                        ? <div className="heading-block btn btn--xs card-widget-heading">
                            <h5 className="heading card-widget-title">{title}</h5>
                            {subtitle}
                        </div>

                        : <>
                            <h5 className="heading card-widget-title btn btn--xs">{title}</h5>
                            {subtitle}
                        </>
                    }
                </div>
                <div className="card-widget-header-item">
                    <div className="card-widget-actions">
                        {showMore &&
                            <a href="#" className="btn btn--custom-1 btn--xs rounded-full">
                                <span>Show More</span>
                            </a>
                        }
                        <AssistantButton />
                        {showAdd &&
                            <button type="button" className="btn btn--primary-advance-1 btn--xs rounded-full btn--square">
                                <span className="ico"><AddIcon /></span>
                            </button>
                        }
                        {showEdit &&
                            <button type="button" className="btn btn--custom-1 btn--xs rounded-full">
                                <span className="ico"><EditIcon /></span>
                                <span>Edit</span>
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div className={clsx("card-widget-body", bodyClassName)}>
                {children}
            </div>
        </div>
    );

};

export default WidgetCard;
