import React from "react";

import GridIcon from "@/assets/img/ico-grid.svg?react";
import ListIcon from "@/assets/img/ico-list.svg?react";
import { useTabs } from "@/hooks/useTabs";
import { useSearchParams } from "react-router";

export enum ViewType {
    Grid = "grid",
    Table = "table",
}

const ViewModeSwitcher: React.FC = () => {

    const tabs = [{ id: ViewType.Grid,
        label: "Grid View" }, { id: ViewType.Table,
        label: "Table View" }];

    const { activeTab, setActiveTab } = useTabs(tabs, "v");

    const handleChange = (newTab: ViewType) => {

        setActiveTab(newTab);

    };

    return (
        <div className="control-group btn btn--md">
            <label className="control-btn-wrpr btn--md">
                <input
                    type="radio"
                    className="change-view"
                    name="view-mode"
                    checked={activeTab === ViewType.Grid}
                    onChange={() => handleChange(ViewType.Grid)}
                    id="grid"
                    hidden
                />
                <div className="btn btn--square btn--xs control-btn">
                    <span className="ico">
                        <GridIcon />
                    </span>
                </div>
            </label>
            <label className="control-btn-wrpr btn--md">
                <input
                    type="radio"
                    className="change-view"
                    name="view-mode"
                    checked={activeTab === ViewType.Table}
                    onChange={() => handleChange(ViewType.Table)}
                    id="table"
                    hidden
                />
                <div className="btn btn--square btn--xs control-btn">
                    <span className="ico">
                        <ListIcon />
                    </span>
                </div>
            </label>
        </div>
    );

};


const getViewMode = (searchParams: URLSearchParams): ViewType => {

    const view = searchParams.get("v");
    return view && Object.values(ViewType).includes(view as ViewType)
        ? (view as ViewType)
        : ViewType.Grid;

};

export const useViewMode = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const viewMode = getViewMode(searchParams);

    const setViewMode = (newView: ViewType) => {

        setSearchParams((prev) => {

            prev.set("t", newView);
            return prev;

        });

    };
    const isGridView = viewMode === ViewType.Grid;
    const isTableView = viewMode === ViewType.Table;
    return { viewMode,
        setViewMode,
        isTableView,
        isGridView };

};

export default ViewModeSwitcher;
