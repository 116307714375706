import { useTabs } from "@/hooks/useTabs";
import { useCallback, useEffect, useState } from "react";

import CloseIcon from "@/assets/img/close-icon.svg?react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { VoidFunctionWithoutArguments } from "@/types";
import StepsNavigation from "@/components/common/StepsNavigation";
import EntityFooter from "@/components/modals/NewEntityModal/EntityFooter";
import { materialSchema } from "@/validation/materialSchema";
import MaterialDetails from "../tabs/MaterialDetails";
import { MaterialOrderStatus, MaterialStatus } from "@/types/enums";
import { materialOrderSchema } from "@/validation/orderMaterialscema";

interface MaterialDataProps {
    handleCancel: VoidFunctionWithoutArguments;
    handleCompleteClick: VoidFunctionWithoutArguments;
    data: any;
    title?: string;
}

const detailsFields =  ["photo",
            "materialName",
            "currentQuantity",
            "supplier",
            "status"]  as const
const tabs = [{
    label: "Material Details",
    id: "details",
    fields: detailsFields
}] as const;

type TabId = typeof tabs[number]["id"];


const MaterialData: React.FC<MaterialDataProps> = ({
    handleCancel,
    handleCompleteClick,
    data,
    title = 'New Material'
}) => {    
    const { activeTab, setActiveTab, activeTabIndex, destroy } = useTabs(tabs, "mt");

    const currentSchema = materialSchema.pick(tabs[activeTabIndex].fields);
    const resolver = yupResolver(currentSchema);
    const formState = useForm({
        resolver,
        defaultValues: {
            status: MaterialStatus.InStock,
        }
    });
    const { register, handleSubmit, formState: { errors }, reset } = formState;


    const onSubmit = async (data: any) => {
        console.log("Form data:", data);
        handleCompleteClick();
    };

    useEffect(() => {

        return () => destroy('modal');

    }, []);

    useEffect(() => {

        console.log("errors", errors);

    }, [errors]);


    const tabComponents: Record<TabId, JSX.Element | null> = {
        details: <MaterialDetails formState={formState} />,
    };

    const handleGoBack = useCallback(() => {

        setActiveTab(tabs[activeTabIndex - 1].id);

    }, [activeTabIndex]);

    const renderTab = () => {

        return tabComponents[activeTab];

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__header">
                <h4 className="heading flex-auto">{title}</h4>
                <div className="modal__header-actions">
                    <button type="button" onClick={handleCancel} className="btn btn--custom-1 btn--md rounded-full btn--square">
                        <span className="ico"><CloseIcon /></span>
                    </button>
                </div>
            </div>

            <div className="modal__body pt-0">
                <div className="w-full">
                    {tabs.length > 1 && <StepsNavigation
                        activeStep={activeTab}
                        setActiveStep={setActiveTab}
                        steps={tabs}
                    />}
                    <div className="modal-tabs">
                        {renderTab()}
                    </div>
                </div>
            </div>
            <EntityFooter
                // isLoading={isLoading}
                handleGoBack={handleGoBack}
                handleCancel={handleCancel}
                isFirstStep={activeTab === "details"}
                completeText="Complete"
                nextStepText="Next Step"
                isFinalStep={activeTab === "details"}
            />
        </form>
    );

};

export default MaterialData;
