

import CheckboxIcon from "@/assets/img/card-radio__checked.svg?react";
import AddSecondaryIcon from "@/assets/img/ico-add-2.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import DoughnutChart from "@/components/charts/DoughnutChart";
import DropdownActions from "@/components/common/DropdownActions";
import Table from "@/components/common/Table";
import WidgetCard from "@/components/cards/WidgetCard";
import { Tab, useTabs } from "@/hooks/useTabs";
import { toCurrency } from "@/utils";
import { ColumnDef } from "@tanstack/react-table";

const BudgetFinancials = () => {

    return (
        <div className="block-container tab-body-fullpage">
            <div className="row g-20">
                <div className="col-xl-4 col-lg-5 col-md-12 col-12 flex flex-col">
                    <div className="row g-20">
                        <div className="col-12">
                            <Budget />
                        </div>
                        <div className="col-12">
                            <ClientPayments />
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-7 col-md-12 col-12 flex flex-col">
                    <FinancialDetails />
                </div>
            </div>
        </div>
    );

};

function Budget () {

    const data = {
        labels: ["Materials", "Labor", "Permits & Fees", "Miscellaneous"],
        data: [50000, 70000, 10000, 20000],
        format: (val: number) => toCurrency(val)
    };

    const total = {
        label: "Total Budget",
        format: (val: number) => toCurrency(val)
    };

    return (
        <WidgetCard title="Budget Overview">
            <DoughnutChart data={data} total={total} variant="secondary" />
        </WidgetCard>
    );

}

interface ClientPayment {
    title: string;
    amount: number;
    date: string;
    isChecked: boolean;
    isExpected?: boolean;
}


function ClientPayments () {

    const data: ClientPayment[] = [{
        title: "Deposit",
        amount: 30000,
        date: "01.02.2024",
        isChecked: true
    }, {
        title: "Building Phase 1",
        amount: 50000,
        date: "01.02.2024",
        isChecked: false,
        isExpected: true
    }, {
        title: "Building Phase 2",
        amount: 50000,
        date: "01.02.2024",
        isChecked: false,
        isExpected: true
    }, {
        title: "Final Payment",
        amount: 50000,
        date: "01.02.2024",
        isChecked: false,
        isExpected: true
    }];
    return (
        <WidgetCard title="Client Payments">
            <div className="row g-9">
                {data.map((payment, index) => <div key={index} className="col-12 col-xl-6">
                    <label className="card-radio-label">
                        <input
                            type="radio"
                            name="cadio-client-1"
                            hidden
                            readOnly
                            disabled
                            defaultChecked={payment.isChecked}
                        />
                        <div className="card-radio">
                            <div className="card-radio__head">
                                <h5 className="card-radio__title">{payment.title}</h5>
                                <div className="card-radio__checked">
                                    <CheckboxIcon />
                                </div>
                            </div>
                            <div className="card-radio__footer">
                                <span className="card-radio__sum">${payment.amount.toLocaleString()}</span>
                                <span className="card-radio__date">
                                    {payment.isExpected
                                        ? "Expected: "
                                        : ""}
                                    <br />
                                    {payment.date}
                                </span>
                            </div>
                        </div>
                    </label>
                </div>)}
            </div>
        </WidgetCard>
    );

}

interface ExpenseCategory {
    category: string;
    allocated: number;
    spent: number;
    remaining: number;
}
function FinancialDetails () {

    const tabs = [{
        label: "Expense Tracking",
        id: "expense-tracking"
    }, {
        label: "Invoices",
        id: "invoices"
    }, {
        label: "Cost Variance Analysis",
        id: "cost-variance-analysis"
    }] as const;
    const { activeTab, setActiveTab } = useTabs(tabs, "fdt");
    const data: ExpenseCategory[] = [{
        category: "Materials",
        allocated: 50000,
        spent: 20000,
        remaining: 30000
    }, {
        category: "Labor",
        allocated: 70000,
        spent: 10000,
        remaining: 60000
    }, {
        category: "Permits & Fees",
        allocated: 10000,
        spent: 5000,
        remaining: 5000
    }, {
        category: "Miscellaneous",
        allocated: 20000,
        spent: 0,
        remaining: 20000
    }];

    const actions = (arg: ExpenseCategory) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => console.log(arg)
    }];

    const columns: ColumnDef<ExpenseCategory>[] = [{
        header: "Expense Category",
        accessorKey: "category",
        minSize: 228,

        cell: ({ row: { original } }) => <p className="font-500 nowrap">{original.category}</p>

    },
    {
        header: "Allocated Amount",
        accessorKey: "allocated",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.allocated}</p>

    },
    {
        header: "Spent",
        accessorKey: "spent",
        minSize: 228,
        cell: ({ row: { original } }) => <p className="font-500 nowrap">{original.spent}</p>

    },
    {
        header: "Remaining",
        accessorKey: "remaining",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.remaining}</p>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            variant="secondary"
            actions={actions(original)}
        />

    }];

    return (
        <div className="card-widget card-widget--md w-full h-full card-widget-scroller card-widget-sticky">
            <div className="card-widget-header">
                <div className="card-widget-header-item">
                    <h5 className="heading card-widget-title btn btn--xs">Financial Details</h5>
                    <div className="tabs-nav-wrapper">
                        {tabs.map(({ id, label }) => <label key={id} className="fake-btn">
                            <input
                                type="radio"
                                name="tab-table-3"
                                onChange={() => setActiveTab(id)} checked={activeTab === id}
                                hidden
                            />
                            <div className="tab-link-2 btn btn--xs">
                                <span className="text--sm">{label}</span>
                            </div>
                        </label>)}
                    </div>
                </div>
                <div className="card-widget-header-item">
                    <div className="card-widget-actions">
                        <button type="button" className="btn btn--secondary-advance-1 btn--xs rounded-full">
                            <span className="ico"><StarsIcon /></span>
                            <span>Ask Your CFO</span>
                        </button>
                        <button type="button" className="btn btn--primary-advance-1 btn--square btn--xs rounded-full">
                            <span className="ico"><AddSecondaryIcon /></span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-widget-body">
                <Table columns={columns} data={data} />
            </div>
        </div>
    );

}

export default BudgetFinancials;
