

import DownloadIcon from "@/assets/img/ico-download.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";

import DropdownActions from "@/components/common/DropdownActions";

const DocumentsList = () => {

    return (
        <div className="table-custom table-custom-2">
            <div className="table-custom-body">
                <table className="table table-2" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td style={{ width: "100%" }}>
                                <div className="table-user-info-texts">
                                    <a href="#" className="btn btn-link color-secondary tdu">
                                        <span className="text--md font-400">Modern Kitchen Remodel Concept</span>
                                    </a>
                                    <p className="color-control-2 text--sm font-400">01.02.2024</p>
                                </div>
                            </td>
                            <td className="--dop-class-last-sticky">
                                <div className="actions-table">
                                    <a href="#" download className="btn btn btn--square btn--xs btn-download">
                                        <span className="ico"><DownloadIcon /></span>
                                    </a>
                                    <DropdownActions
                                        actions={[{
                                            icon: <EditIcon />,
                                            label: "Edit",
                                            fn: () => console.log("Edit")
                                        }]}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

};

export default DocumentsList;
