import React from "react";

const steps = [
    { status: 1, title: "Customer Agreement", count: 4 },
    { status: 2, title: "Design Proposals", count: 6 },
    { status: 3, title: "Project Development", count: 3 },
    { status: 4, title: "Permit Expedition", count: 2 },
    { status: 5, title: "Building Rough-in", count: 7 },
    { status: 6, title: "Building Finishes", count: 5 },
    { status: 7, title: "Building Punch Out", count: 10 },
    { status: 8, title: "Completed", count: 54 },
];

const HomeFilter = () => {
    return (
        <div className="block-container">
            <div className="main-boards-wrpr">
                <ul className="main-board-list-wrpr">
                    {steps.map((step) => (
                        <li
                            key={step.status}
                            className={`main-board-list-item --status-${step.status}`}
                        >
                            <a href="#" className="btn main-board--btn">
                                <div className="board-content">
                                    <p className="board-heading-text">
                                        {step.title}
                                    </p>
                                    <div className="ico ico-board">
                                        {step.count}
                                    </div>
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default HomeFilter;
