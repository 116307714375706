import { ColumnDef } from '@tanstack/react-table';
import DropdownActions, { DropdownActionsProps } from './DropdownActions';
import DocIconColored from "@/assets/img/ico-doc-colored.svg?react";
import Table from './Table';
import { Document, Material } from "@/types";
import Image from './Image';


type Actions = DropdownActionsProps['actions']
interface MaterialsTableProps {
    data: Material[]
    actions: (arg: Material) => Actions
}

function MaterialsTable({ data, actions }: MaterialsTableProps) {

    const columns: ColumnDef<Material>[] = [
        {
            header: "Name",
            accessorKey: "name",
            cell: ({ row: { original } }) =>
                <div className="table-user-info-texts">
                    <div className='flex items-center gap--md'>
                        <span className='ico ico--lg user-block-img-wrpr'>
                            <Image directSrc={original.fileName} alt={original.name} />
                        </span>
                        <span className="text--md font-400">{original.name}</span>
                    </div>
                </div>
        },
        {
            header: "Quantity",
            accessorKey: "quantity",
            cell: ({ row: { original } }) =>
                <div className="table-user-info-texts">
                    <span className="text--md font-400">{original.quantity} sq. ft</span>
                </div>
        },
        {
            header: "Status",
            accessorKey: "status",
            cell: ({ row: { original } }) => <div className="pill-custom pill--custom-1 pill--sm">
                <span>{original.status}</span>
            </div>
    
        },
        {
            header: "Actions",
            accessorKey: "actions",
            enableSorting: false,
            enablePinning: true,
            cell: ({ row: { original } }) => <DropdownActions
                variant="secondary"
                actions={actions(original)}
            />

        }];


    return (
        <div className="table-accord">
            <Table columns={columns} data={data} hideFooter />
        </div>
    )
}

export default MaterialsTable;