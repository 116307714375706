import {FrequencyEnum, LocationEnum} from "@/types/enums";
import * as yup from "yup";

export const eventSchema = yup.object({
    id: yup.number().required("ID is required"),

    eventName: yup.string().required("Event name is required"),
    project: yup.string().required("Project is required"),
    pipelinePhase: yup.string().required("Pipeline phase is required"),
    description: yup.string().required("Description is required"),

    date: yup.string().required("Date is required"),
    timeTo: yup.string().required("Time to is required"),
    timeFrom: yup.string().required("Time from is required"),

    frequency: yup
        .string()
        .oneOf(Object.values(FrequencyEnum), "Invalid frequency")
        .required("Frequency is required"),

    repeatInterval: yup
        .number()
        .nullable()
        .when("frequency", {
            is: FrequencyEnum.REPEAT,
            then: (schema) =>
                schema
                    .required("Interval is required for recurring events")
                    .min(1, "Interval must be at least 1"),
            otherwise: (schema) => schema.nullable(),
        }),

    repeatEndsAfter: yup
        .number()
        .nullable()
        .when("frequency", {
            is: FrequencyEnum.REPEAT,
            then: (schema) =>
                schema
                    .required("Repeat ends after is required")
                    .min(1, "Must be at least 1"),
            otherwise: (schema) => schema.nullable(),
        }),

    repeatEvery: yup
        .string()
        .nullable()
        .when("frequency", {
            is: FrequencyEnum.REPEAT,
            then: (schema) =>
                schema
                    .required("Repeat period is required")
                    .oneOf(["days", "weeks", "months"], "Invalid repeat period"),
            otherwise: (schema) => schema.nullable(),
        }),

    location: yup
        .string()
        .oneOf(Object.values(LocationEnum), "Invalid location")
        .required("Location is required"),

    addressUrl: yup
        .string()
        .nullable()
        .when("location", {
            is: LocationEnum.VIRTUAL,
            then: (schema) =>
                schema
                    .required("Virtual address is required"),
            otherwise: (schema) => schema.nullable(),
        }),

    address: yup
        .string()
        .nullable()
        .when("location", {
            is: LocationEnum.PHYSICAL,
            then: (schema) =>
                schema
                    .required("Address is required"),
            otherwise: (schema) => schema.nullable(),
        }),

    participants: yup
        .array()
        .of(yup.number().required("Each participant ID is required"))
        .min(1, "At least one participant is required")
        .required("Participants are required"),
});

export type EventFormRequest = yup.InferType<typeof eventSchema>;
