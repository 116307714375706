import React, { ReactNode } from "react";
import { Swiper as ReactSwiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination } from "swiper/modules";

interface SwiperProps {
    slides: ReactNode[]
}

const Swiper = ({ slides }: SwiperProps) => {

    return (
        <ReactSwiper
            modules={[Pagination]}
            spaceBetween={6}
            centeredSlides
            slidesPerView={1.3}
            pagination={{
                clickable: true,
                el: ".swiper-pagination"
            }}
            className="swiper swiper_tasks"
        >
            {slides.map((slide, index) => <SwiperSlide key={index}>{slide}</SwiperSlide>)}
            <div className="swiper-pagination swiper-pagination-custom swiper-pagination-relative"></div>
        </ReactSwiper>
    );

};

export default Swiper;
