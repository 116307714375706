import Input from "@/components/common/Input";
import PhoneInput from "@/components/common/PhoneInput";
import { SupplierSchemaRequest } from "@/validation/supplierSchema";
import { UseFormReturn } from "react-hook-form";

interface ContactsProps {
    formState: UseFormReturn<SupplierSchemaRequest>
}
const Contacts = ({ formState }: ContactsProps) => {

    const { register, watch, setValue, formState: { errors, defaultValues } } = formState;

   
    const handlePhoneNumberChange = (value: SupplierSchemaRequest["phone"]) => {

        setValue("phone", value);

    };
    const handlePhoneCountryChange = (value: SupplierSchemaRequest["phoneCountry"]) => {
        
        setValue("phoneCountry", value);

    };
    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Contact Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("contactName")}
                            placeholder="Enter full name here..."
                            error={errors.contactName?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Address</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("address")}
                            placeholder="Enter address..."
                            error={errors.address?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Phone Number</span>
                    </div>
                    <div className="col-8">
                        <PhoneInput
                            className="input--md"
                            placeholder="Enter phone number..."
                            value={watch("phone")}
                            onChange={handlePhoneNumberChange}
                            onCountryChange={handlePhoneCountryChange}
                            error={errors.phone?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Email</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("email")}
                            placeholder="Enter email..."
                            error={errors.email?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Website</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("website")}
                            placeholder="Enter website..."
                            error={errors.website?.message}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Contacts;
