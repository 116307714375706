import { OptionCustomProps } from "@/components/common/Select";
import {ClientType, ContactMethod, FrequencyEnum, LocationEnum, MaterialOrderStatus, MaterialStatus, InsuranceOptionsText, ProjectTypes, RiskLevel, Status} from "@/types/enums";
import {contactMethodMap, frequencyMap, locationMap, riskLevelMap, statusMap, statusMaterialMap, statusMaterialOrderMap} from ".";
import { City, Country, State } from "country-state-city";

export const statusOptions: OptionCustomProps<Status>[] = Object.values(Status).map(value => ({
    label: statusMap[value].label,
    value: value
}));

export const statusMaterialOptions: OptionCustomProps<MaterialStatus>[] = Object.values(MaterialStatus).map(value => ({
    label: statusMaterialMap[value].label,
    value: value
}));
export const statusMateriaOrderlOptions: OptionCustomProps<MaterialOrderStatus>[] = Object.values(MaterialOrderStatus).map(value => ({
    label: statusMaterialOrderMap[value].label,
    value: value
}));

export const insuranceOptions: OptionCustomProps<boolean>[] = [true, false].map(value => ({
    label: value ? InsuranceOptionsText.Yes : InsuranceOptionsText.No,
    value: value
}));

export const clientTypeOptions: OptionCustomProps<ClientType>[] = Object.values(ClientType).map(value => ({
    label: value,
    value: value
}));

export const projectTypeOptions: OptionCustomProps<string>[] = Object.values(ProjectTypes).map(value => ({
        label: value,
        value: value
    }));

export const riskLevelOptions: OptionCustomProps<RiskLevel>[] = Object.values(RiskLevel).map(value => ({
    label: riskLevelMap[value].label,
    value: value
}));

export const contactMethodOptions: OptionCustomProps<ContactMethod>[] = Object.values(ContactMethod).map(value => ({
    label: contactMethodMap[value],
    value: value
}));

export const departmentOptions = [{
    label: "Los Angeles, CA",
    value: 1
}];

export const skillsOptions = [{ label: "Communication",
    value: "communication" },
{ label: "Team Leadership",
    value: "team_leadership" },
{ label: "Time Management",
    value: "time_management" },
{ label: "Problem Solving",
    value: "problem_solving" },
{ label: "Project Management",
    value: "project_management" },
{ label: "Creativity",
    value: "creativity" },
{ label: "Adaptability",
    value: "adaptability" },
{ label: "Critical Thinking",
    value: "critical_thinking" }];

export const countryOptions = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.isoCode
}));

export const stateOptions = (country: string) => State.getStatesOfCountry(country).map((state) => ({
    label: state.name,
    value: state.isoCode
}));

export const cityOptions = (country: string, state: string) => City.getCitiesOfState(country, state).map((city) => ({
    label: city.name,
    value: city.name
}));

export const frequencyOptions: OptionCustomProps<FrequencyEnum>[] = Object.values(FrequencyEnum).map(value => ({
    label: frequencyMap[value].label,
    value: value
}));
export const locationOptions: OptionCustomProps<LocationEnum>[] = Object.values(LocationEnum).map(value => ({
    label: locationMap[value].label,
    value: value
}));
