import {  MaterialStatus } from "@/types/enums";
import * as yup from "yup";

export const materialSchema = yup.object().shape({
    photo: yup.mixed().optional(),
    materialName: yup.string().required(),
    currentQuantity: yup.string().required(),
    supplier: yup.string().required(),
    status: yup.string().oneOf(Object.values(MaterialStatus)).required("Status is required"),
});

export type MaterialSchemaRequest = yup.InferType<typeof materialSchema>;
