import FireIcon from "@/assets/img/ico-fire.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import NotificationIcon from "@/assets/img/icon-notification-2.svg?react";
import DotsIcon from "@/assets/img/ico-dots.svg?react";
import CopyIcon from "@/assets/img/ico-copy.svg?react";
import DocIcon from "@/assets/img/ico-doc.svg?react";
import BoxIcon from "@/assets/img/ico-box.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import userImg from "@/assets/img/img-user-1.png";
import clsx from "clsx";
import { IProjectBackendData } from "@/types";
import DropdownActions from "@/components/common/DropdownActions";
import { Link } from "react-router";
import { formatAddress, getFullUrl } from "@/utils";
import { routerConfig } from "@/routes";
import Image from "../common/Image";
import { format } from "date-fns";
import { DATE_FORMAT } from "@/constants";

interface ProjectCardProps {
    data: IProjectBackendData
}

const randomizeStatus = () => {

    const statuses = ["err", "warn", "empty"];
    return statuses[Math.floor(Math.random() * statuses.length)];

};
const ProjectCard = ({ data }: ProjectCardProps) => {

    const status = randomizeStatus();
    const actions = (arg: IProjectBackendData) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => console.log(arg)
    }];
    return (
        <div className="card-widget card-widget-product w-full h-full">
            <div className="card-widget-body">
                <div className="card-details-wrpr">
                    <div className="card-details--item">
                        <div className="card-details--head">
                            <div className="user-option-block user-option-block--sm">
                                <div className="user-block-relatives">
                                    <div className="ico ico--lg user-block-img-wrpr">
                                        <Image src={data.cover} alt="project" />
                                    </div>
                                    <div className="ico user-ico-hot-wrpr">
                                        <span className="ico user-ico-hot --active">
                                            <FireIcon />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="card-details-actions">
                                <button type="button" className="btn btn--square btn--secondary-advance-1 btn--xs rounded-full">
                                    <span className="ico"><StarsIcon /></span>
                                </button>
                                <a href="#" className="btn btn--square btn--custom-1 btn--xs rounded-full">
                                    <span className="ico">
                                        <NotificationIcon />
                                    </span>
                                </a>
                                <DropdownActions
                                    button={(
                                        <button type="button" className="btn btn--custom-1-trsp rounded-full max-w-max dropdown-btn"
                                            data-role="button">
                                            <span className="ico"><DotsIcon /></span>
                                        </button>
                                    )}
                                    actions={actions(data)}
                                />
                            </div>
                        </div>
                        <div className="card-details--body">
                            <div className="card-details-heading">
                                <Link to={getFullUrl([routerConfig.projects.path, 1])} className="heading user-block-title text--xl">
                                    Project Name 1
                                </Link>
                                <p className="user-project--text">Client Name</p>
                            </div>
                            <div className="card-details--head card-details--users-row">
                                <div className="user-list-wrpr p-0 btn--xs btn">
                                    <ul className="user-list">
                                        <li className="user-item"><img src={userImg} alt="user" /></li>
                                        <li className="user-item"><img src={userImg} alt="user" /></li>
                                        <li className="user-item"><img src={userImg} alt="user" /></li>
                                    </ul>
                                    <p className="users-counter-2"><span>3</span> users</p>
                                </div>
                                <a href="#" className="btn btn-link color-secondary">
                                    <span className="text--sm text-right">{formatAddress(data.address, ['country', 'city'])}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-details--item">
                        <div className="card-details--body">

                            <div className="presence-sections-wrpr">
                                <div className="presence-titled-row">
                                    <p className="precence-title-2">Customer Agreement</p>
                                </div>
                                <div className="presence-sections">
                                    <div className="presence-section" style={{ backgroundColor: "var(--color-status-1)" }}></div>
                                    <div className="presence-section" style={{ backgroundColor: "var(--color-status-2)" }}></div>
                                    <div className="presence-section" style={{ backgroundColor: "var(--color-status-3)" }}></div>
                                    <div className="presence-section" style={{ backgroundColor: "var(--color-status-4)" }}></div>
                                    <div className="presence-section" style={{ backgroundColor: "var(--color-status-5)" }}></div>
                                    <div className="presence-section" style={{ backgroundColor: "var(--color-status-6)" }}></div>
                                    <div className="presence-section"></div>
                                    <div className="presence-section"></div>
                                </div>
                                <div className="presence-titled-row">
                                    <div className={clsx("status-date-flex", {
                                        "--warn": status === "warn",
                                        "--err": status === "err"
                                    })}>
                                        <p className="status-date--val">due to <span>{format(data.deadline, DATE_FORMAT)}</span></p>
                                    </div>

                                </div>
                            </div>

                            <div className="presence-sections-wrpr">
                                <div className="presence-titled-row">
                                    <p className="precence-title-2">Deposit</p>
                                </div>
                                <div className="presence-sections">
                                    <div className="presence-section" style={{ backgroundColor: "var(--color-status-2)" }}></div>
                                    <div className="presence-section"></div>
                                    <div className="presence-section"></div>
                                    <div className="presence-section"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card-details--item">
                        <ul className="project-list">
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <CopyIcon />
                                    </span>
                                    <p className="project-variable--text">Tasks:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text font-500">0 / 0</p>
                                </div>
                            </li>
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <DocIcon />
                                    </span>
                                    <p className="project-variable--text">Documents:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text font-500">{data.contracts?.length}</p>
                                </div>
                            </li>
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <BoxIcon />
                                    </span>
                                    <p className="project-variable--text">Materials:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text font-500">0</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ProjectCard;
