import Input from "@/components/common/Input";
import PhoneInput from "@/components/common/PhoneInput";
import RadioGroup from "@/components/common/RadioGroup";
import { contactMethodOptions } from "@/constants/options";
import { ClientFormRequest } from "@/validation/clientSchema";
import { UseFormReturn } from "react-hook-form";

interface ContactsProps {
    formState: UseFormReturn<ClientFormRequest>
}
const Contacts = ({ formState }: ContactsProps) => {

    const { register, watch, setValue, formState: { errors, defaultValues } } = formState;

    const handleContactMethodChange = (value: ClientFormRequest["contactMethod"]) => {

        setValue("contactMethod", value);

    };
    const handlePhoneNumberChange = (value: ClientFormRequest["phone"]) => {

        setValue("phone", value);

    };
    const handlePhoneCountryChange = (value: ClientFormRequest["phoneCountry"]) => {
        
        setValue("phoneCountry", value);

    };
    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Email</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("email")}
                            placeholder="Enter email..."
                            error={errors.email?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Phone Number</span>
                    </div>
                    <div className="col-8">
                        <PhoneInput
                            className="input--md"
                            placeholder="Enter phone number..."
                            value={watch("phone")}
                            onChange={handlePhoneNumberChange}
                            onCountryChange={handlePhoneCountryChange}
                            error={errors.phone?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Preferable Contact Method</span>
                    </div>
                    <div className="col-8">
                        <div className="tabs-navigation">
                            <div className="tabs-navigation-list --list-2">
                                <RadioGroup
                                    options={contactMethodOptions}
                                    onChange={handleContactMethodChange}
                                    value={watch("contactMethod")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Contacts;
