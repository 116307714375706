import Loader from "../common/Loader"

const ModalLoader: React.FC = () => {
    return (
        <div className="container container-full flex flex-col items-center justify-center modal-loader">
            <Loader size={100} />
        </div>
    )
}

export default ModalLoader
