import DropdownActions from "@/components/common/DropdownActions";
import ShopIcon from "@/assets/img/ico-shop.svg?react";
import { ColumnDef } from "@tanstack/react-table";
import Table from "@/components/common/Table";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import useModal from "@/hooks/useModal";
import TeamCard from "../components/TeamCard";
import { Team, IUserMainData } from "@/types";
import { useGetAllTeamsQuery } from "@/api/teamsApi";
import PageLoader from "@/components/layout/PageLoader";
import { getFullName } from "@/utils";
import Image from "@/components/common/Image";
import { ModalID } from "@/providers/ModalProvider";


interface TeamsTabProps {
    isGridView: boolean
    isTableView: boolean
}


const TeamsTab = ({ isGridView, isTableView }: TeamsTabProps) => {
    const { data: response, isLoading } = useGetAllTeamsQuery()
    const { openModal } = useModal();

    const data = response?.data || []

    const actions = (arg: Team) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => openModal(ModalID.EditTeam, { data: arg }),
    }];


    if (isLoading) {
        return <PageLoader />
    }

    if (isGridView) {

        return (
            <div className="row gutters-4">
                {data.map(dataItem => <TeamCard data={dataItem} actions={actions} />)}
            </div>
        );

    }

    const columns: ColumnDef<Team>[] = [{
        header: "Team",
        accessorKey: "name",

        cell: ({ row }) => <div className="category-option category-style-1">
            <span className="ico ico-category">
                <span className="ico"><ShopIcon /></span>
            </span>
            <span className="text-category">{row.original.name}</span>
        </div>

    },
    {
        header: "Number of Users",
        accessorKey: "members",
        cell: ({ row }) => <p className="color-control-3 table-text-nowrap">{row.original.members.length}</p>
    },
    {
        header: "Projects",
        accessorKey: "projects",
        cell: ({ row }) => <p className="font-500 table-text-nowrap">{row.original.projects.length}</p>
    },
    {
        header: "Lead User",
        accessorKey: "TeamLeadId",
        cell: ({ row }) => <div className="table-user-info">
            <span className="ico ico-ava ico-ava-24">
                <Image src={row.original.teamLead.photo} alt="team lead" />
            </span>
            <div className="table-user-info-texts">
                <a href="#" className="btn btn-link color-secondary tdu">
                    <span className="text--md font-400">{getFullName(row.original.teamLead)}</span>
                </a>
            </div>
        </div>

    },
    {
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => <div className="pill-custom pill--custom-1 pill--sm">
            <span>{row.original.status}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row }) => <DropdownActions actions={actions(row.original)} variant="secondary" />

    }]

    return <Table
        columns={columns}
        data={data}
        selectable
        renderAccordionContent={(row) => <ExpandedTable data={row.members} />}
    />;

};

interface ExpandedTableProps {
    data: IUserMainData[]
}

function ExpandedTable({ data }: ExpandedTableProps) {

    const columns: ColumnDef<IUserMainData>[] = [{
        header: "User Name",
        accessorKey: "userName",
        cell: (info) => <div className="table-user-info">
            <span className="ico ico-ava ico-ava-24">
                <Image src={info.row.original.photo} alt={getFullName(info.row.original)} />
            </span>
            <div className="table-user-info-texts">
                <a href="#" className="btn btn-link color-secondary tdu">
                    <span className="text--md font-400">{getFullName(info.row.original)}</span>
                </a>
            </div>
        </div>

    },
    {
        header: "Role",
        accessorKey: "role",
        // "TODO"
        cell: (info) => <div className="pill-custom pill--custom-3 pill--sm">
            <span>{info.row.original.profession || 'Designer'}</span>
        </div>

    },
    {
        header: "Tasks",
        accessorKey: "tasks",
        // "TODO"
        cell: (info) => <p className="font-500 table-text-nowrap">{0}</p>
    },
    {
        header: "Status",
        accessorKey: "status",
        cell: (info) => <div className="pill-custom pill--custom-1 pill--sm">
            <span>{info.row.original.status}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: () => <DropdownActions actions={[]} variant="secondary" />
    }];

    return (
        <tr className="table-accord-tbody table-accord-tbody-js expanded">
            <td className="table-accord w-full" colSpan={Object.keys(data[0]).length + 1}>
                <Table data={data} columns={columns} selectable hideSelectAll />
            </td>
        </tr>
    );

}

export default TeamsTab;
