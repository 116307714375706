import Modal, { ModalProps } from "@/components/modals/Modal"
import { IUserUpdatableData } from "@/types"
import { useCallback } from "react";
import UserData from "../NewUserModal/components/UserData";
import { getFullName, getPhoneWithPlusSymbol } from "@/utils";

interface EditUserModal extends ModalProps {
    data: IUserUpdatableData
}

const EditUserModal: React.FC<EditUserModal> = ({data, ...props}) => {
    const handleCancel = useCallback(() => {
        props.onClose();
    }, []);
    
    return (
        <Modal {...props}
            closeOnClickOutside={false}
            dialogClassName={"modal__dialog--700"}
            contentClassName={"modal--rounded"}
        >
            <UserData
                data={{
                    ...data,
                    departmentId: data.departmentId || 1, //TODO: Remove this after bakend fix
                    phone: getPhoneWithPlusSymbol(data.phone),
                    fullName: getFullName({firstName: data.firstName, lastName: data.lastName }),
                }} 
                handleCancel={handleCancel}
                handleCompleteClick={handleCancel}
            />
        </Modal>
    )
}

export default EditUserModal
