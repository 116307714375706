import Modal, { ModalProps } from "@/components/modals/Modal";
import { IProjectBackendData, Project } from "@/types";
import ProjectData from "../NewProjectModal/components/ProjectData";
import { useCallback } from "react";
import { dtoService } from "@/services/dtoService";

interface EditProjectModalProps extends ModalProps {
    data: IProjectBackendData;
}

const EditProjectModal: React.FC<EditProjectModalProps> = ({
    data,
    ...props
}) => {
    const handleCancel = useCallback(() => {
        props.onClose();
    }, []);

    return (
        <Modal {...props}
            closeOnClickOutside={false}
            dialogClassName={"modal__dialog--700"}
            contentClassName={"modal--rounded"}
        >
            <ProjectData
                data={dtoService.convertProjectFromBackend(data)} 
                handleCancel={handleCancel}
                handleCompleteClick={handleCancel}
                title="Edit Project"
            />
        </Modal>
    );
}

export default EditProjectModal;
