import clsx from "clsx";
import React, { useState, useRef, useEffect } from "react";
import Button from "./Button";

interface AccordionProps {
    button: React.ReactNode
    children: React.ReactNode
    wrapperClassName?: string
    bodyClassName?: string
    buttonClassName?: string
    defaultOpen?: boolean
}

const Accordion: React.FC<AccordionProps> = ({
    button,
    children,
    wrapperClassName,
    bodyClassName,
    buttonClassName,
    defaultOpen
}) => {

    const [isOpen, setIsOpen] = useState(defaultOpen);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const toggleAccordion = () => {

        setIsOpen(!isOpen);

    };
    useEffect(() => {

        setIsOpen(defaultOpen);

    }, [defaultOpen]);

    return (
        <div
            className={clsx("accordeon", wrapperClassName, { "active-accordeon": isOpen })}
            data-accordeon
        >
            <label className={clsx("w-full flex", buttonClassName)} onClick={toggleAccordion}>{button}</label>
            <div
                ref={contentRef}
                className={clsx("accordion-body", bodyClassName)}
                style={{
                    maxHeight: isOpen
                        ? `${contentRef.current?.scrollHeight || 1000}px`
                        : "0",
                    transition: "max-height 0.3s ease-out",
                    overflow: "hidden"
                }}

            >
                {children}
            </div>
        </div>
    );

};

export default Accordion;
