import { useTabs } from "@/hooks/useTabs";
import { useCallback, useEffect } from "react";

import CloseIcon from "@/assets/img/close-icon.svg?react";
import StepsNavigation from "@/components/common/StepsNavigation";
import { IUserFormData, IUserUpdatableData, VoidFunctionWithoutArguments } from "@/types";
import { Status, UserRole } from "@/types/enums";
import { userSchema } from "@/validation/userSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Contacts from "../tabs/Contacts";
import PersonalSkills from "../tabs/PersonalSkills";
import UserDetails from "../tabs/UserDetails";
import { useCreateUserMutation, useUpdateUserMutation } from "@/api/usersApi";
import EntityFooter from "@/components/modals/NewEntityModal/EntityFooter";
import { normalizePhone } from "@/utils";

interface UserDataProps {
    data?: IUserFormData;
    handleCancel: VoidFunctionWithoutArguments;
    handleCompleteClick: VoidFunctionWithoutArguments;
}

const tabs = [{
    label: "User Details",
    id: "details",
    fields: ["photo",
        "firstName",
        "lastName",
        "profession",
        "departmentId",
        "status"]
}, {
    label: "Contacts",
    id: "contacts",
    fields: ["email", "phone"]
}, {
    label: "Personal Skills",
    id: "skills",
    fields: ["skills"]
}] as const;
type TabId = typeof tabs[number]["id"];


const UserData: React.FC<UserDataProps> = ({
    data,
    handleCancel,
    handleCompleteClick
}) => {
    const [create, { isLoading }] = useCreateUserMutation();
    const [update, { isLoading: isUpdating }] = useUpdateUserMutation();
    const { activeTab, setActiveTab, activeTabIndex, destroy } = useTabs(tabs, "mt");

    const currentSchema = userSchema.pick(tabs[activeTabIndex].fields);
    const resolver = yupResolver(currentSchema);

    const formState = useForm({
        resolver,
        defaultValues: data || {
            status: Status.ACTIVE
        }
    });
    const { register, handleSubmit, formState: { errors }, reset } = formState;

    const userId = data?.id;

    const onSubmit = async (data: IUserFormData) => {
        let res;

        console.log("Form data:", data);

        const dataToSave = { ...data, phone: normalizePhone(data.phone), skills: JSON.stringify(data.skills) }

        if (activeTabIndex < tabs.length - 1) {

            setActiveTab(tabs[activeTabIndex + 1].id);
        } else {
            if (userId) {
                res = await update({ userId, body: dataToSave }).unwrap()
            } else {
                res = await create(dataToSave).unwrap()
            }
            if (res?.success) {
                handleCompleteClick();
            }
            console.log("Final Submit:", data);

        }

    };

    useEffect(() => {

        return () => destroy();

    }, []);

    useEffect(() => {

        console.log("errors", errors);

    }, [errors]);


    const tabComponents: Record<TabId, JSX.Element | null> = {
        details: <UserDetails formState={formState} />,
        contacts: <Contacts formState={formState} />,
        skills: <PersonalSkills formState={formState} />
    };

    const handleGoBack = useCallback(() => {

        setActiveTab(tabs[activeTabIndex - 1].id);

    }, [activeTabIndex]);

    const renderTab = () => {

        return tabComponents[activeTab];

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__header">
                <h4 className="heading flex-auto">New User</h4>
                <div className="modal__header-actions">
                    <button type="button" onClick={handleCancel} className="btn btn--custom-1 btn--md rounded-full btn--square">
                        <span className="ico"><CloseIcon /></span>
                    </button>
                </div>
            </div>

            <div className="modal__body pt-0">
                <div className="w-full">
                    <StepsNavigation
                        activeStep={activeTab}
                        setActiveStep={setActiveTab}
                        steps={tabs}
                    />
                    <div className="modal-tabs">
                        {renderTab()}
                    </div>
                </div>
            </div>

            <EntityFooter
                isLoading={isLoading || isUpdating}
                handleGoBack={handleGoBack}
                handleCancel={handleCancel}
                completeText="Complete"
                nextStepText="Next Step"
                isFirstStep={activeTab === "details"}
                isFinalStep={activeTab === "skills"}
            />
        </form>
    );

};

export default UserData;
