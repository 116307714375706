import {  MaterialOrderStatus} from "@/types/enums";
import * as yup from "yup";

export const materialOrderSchema = yup.object().shape({
    orderNumber: yup.string().required(),
    material: yup.string().required(),
    supplier: yup.string().required(),
    orderedQuantity: yup.string().required(),
    relatedProject: yup.string().required(),
    orderDate: yup.string().required(),
    status: yup.string().oneOf(Object.values(MaterialOrderStatus)).required("Status is required"),
});

export type MaterialOrderSchemaRequest = yup.InferType<typeof materialOrderSchema>;