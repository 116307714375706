import promoLoginImg from "@/assets/img/room.jfif";

const PromoSection = () => {

    return (
        <div className="promo-login-wrapp">
            <div className="promo-login">
                <div className="promo-login-img">
                    <img src={promoLoginImg} alt="promo-login-img" />
                </div>
                <div className="promo-login-content">
                    <div className="heading-block gap--md">
                        <h1 className="heading font-600">Maximize your home’s design potential</h1>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default PromoSection;
