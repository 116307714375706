import ModalProvider from "@/providers/ModalProvider";
import ToastProvider from "@/providers/ToastProvider";
import { Outlet } from "react-router";

const AppLayout = () => {

    return (
        <>
            <Outlet />
            <ModalProvider />
            <ToastProvider />
        </>
    );

};

export default AppLayout;
