import { useTabs } from "@/hooks/useTabs";
import { useCallback, useEffect, useState } from "react";

import CloseIcon from "@/assets/img/close-icon.svg?react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Client, VoidFunctionWithoutArguments } from "@/types";
import StepsNavigation from "@/components/common/StepsNavigation";
import EntityFooter from "@/components/modals/NewEntityModal/EntityFooter";
import { materialSchema } from "@/validation/materialSchema";
import { MaterialOrderStatus, MaterialStatus } from "@/types/enums";
import OrderDetails from "../tabs/OrderDetails";
import { materialOrderSchema } from "@/validation/orderMaterialscema";

interface OrderDataProps {
    handleCancel: VoidFunctionWithoutArguments;
    handleCompleteClick: VoidFunctionWithoutArguments;
    data: any;
    title?: string;
}

const detailsFields = ["orderNumber",
    "material",
    "supplier",
    "orderedQuantity",
    "relatedProject",
    "orderDate",
    "status"] as const
const tabs = [{
    label: "Client Details",
    id: "details",
    fields: detailsFields
}] as const;

type TabId = typeof tabs[number]["id"];


const OrderData: React.FC<OrderDataProps> = ({
    handleCancel,
    handleCompleteClick,
    data,
    title = 'New Order'
}) => {
        
    const { activeTab, setActiveTab, activeTabIndex, destroy } = useTabs(tabs, "mt");

    const currentSchema = materialOrderSchema.pick(tabs[activeTabIndex].fields);
    const resolver = yupResolver(currentSchema);
    const formState = useForm({
        resolver,
        defaultValues: {
            status: MaterialOrderStatus.Delivered,
        }
    });
    const { register, handleSubmit, formState: { errors }, reset } = formState;


    const onSubmit = async (data: any) => {
        console.log("Form data:", data);
        // handleCompleteClick();
    };

    useEffect(() => {

        return () => destroy('modal');

    }, []);

    useEffect(() => {

        console.log("errors", errors);

    }, [errors]);


    const tabComponents: Record<TabId, JSX.Element | null> = {
        details: <OrderDetails formState={formState} />,
    };

    const handleGoBack = useCallback(() => {

        setActiveTab(tabs[activeTabIndex - 1].id);

    }, [activeTabIndex]);

    const renderTab = () => {

        return tabComponents[activeTab];

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__header">
                <h4 className="heading flex-auto">{title}</h4>
                <div className="modal__header-actions">
                    <button type="button" onClick={handleCancel} className="btn btn--custom-1 btn--md rounded-full btn--square">
                        <span className="ico"><CloseIcon /></span>
                    </button>
                </div>
            </div>

            <div className="modal__body pt-0">
                <div className="w-full">
                    {tabs.length > 1 &&<StepsNavigation
                        activeStep={activeTab}
                        setActiveStep={setActiveTab}
                        steps={tabs}
                    />}
                    <div className="modal-tabs">
                        {renderTab()}
                    </div>
                </div>
            </div>
            <EntityFooter
                // isLoading={isLoading}
                handleGoBack={handleGoBack}
                handleCancel={handleCancel}
                isFirstStep={activeTab === "details"}
                completeText="Complete"
                nextStepText="Next Step"
                isFinalStep={activeTab === "details"}
            />
        </form>
    );

};

export default OrderData;
