import { memo } from 'react';
import GoogleIcon from "@/assets/img/ico-google.svg?react";
import Logo from "@/assets/img/login-logo.svg?react";
import { useTranslation } from 'react-i18next';

const LoginFormHeaderComponent: React.FC = () => {
    const { t } = useTranslation('', { keyPrefix: "loginPage" });

    return (
        <div className="form-header">
            <div className="logo mx-auto">
                <Logo />
            </div>
            <div className="heading-block text-center gap--md">
                <h3 className="heading">{t('title')}</h3>
                <p className="color-control-3 text--md">
                    {t('dontHaveAccountText')}?&nbsp;
                    <a href="#" className="btn btn-link color-primary tdu">
                        {t('createAccountLinkText')}
                    </a>
                </p>
            </div>
            <div className="flex flex-col gap--sm">
                <a href="#" className="btn btn--custom-2 btn--xl rounded-full">
                    <span className="ico"><GoogleIcon /></span>
                    <span>{t('googleLoginButtonText')}</span>
                </a>
            </div>
        </div>
    );

};

const LoginFormHeader = memo(LoginFormHeaderComponent);

export default LoginFormHeader;
