import MultiChoiceSelector from "@/components/common/MultiChoiceSelector";
import { skillsOptions } from "@/constants/options";
import { UserFormRequest } from "@/validation/userSchema";
import { UseFormReturn } from "react-hook-form";

interface PersonalSkillsProps {
    formState: UseFormReturn<UserFormRequest>
}
const PersonalSkills = ({ formState }: PersonalSkillsProps) => {

    const { register, watch, setValue, resetField, formState: { errors, defaultValues } } = formState;


    const handleSkillsChange = (value: string[]) => {

        setValue("skills", value);

    };

    return (
        <div className="row g-20">
            <div className="col-12">
                <MultiChoiceSelector
                    options={skillsOptions}
                    value={watch("skills") || []}
                    onChange={handleSkillsChange}
                />
            </div>
        </div>
    );

};

export default PersonalSkills;
