import { Status, ProjectTypes } from "@/types/enums";
import * as yup from "yup";
import { addressSchema } from "./addressSchema";

export const projectSchema = yup.object().shape({
    address: addressSchema,

    type: yup
        .string().oneOf(Object.values(ProjectTypes))
        .required(),
    name: yup.string().required(),
    deadline: yup.string().required(),
    estVolume: yup.number().positive().required(),
    departmentId: yup.number().integer().positive().required(),
    status: yup.string().oneOf(Object.values(Status)).required(),
    description: yup.string().optional(),
    cover: yup.mixed(),
    photos: yup.array().of(yup.mixed()).optional(),
    contracts: yup.array().of(yup.string()).optional(),
});

export type ProjectFormRequest = yup.InferType<typeof projectSchema>;
