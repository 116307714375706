import AddIcon from "@/assets/img/ico-add.svg?react";
import DotsIcon from "@/assets/img/ico-dots.svg?react";
import DownloadIcon from "@/assets/img/ico-download.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import DropdownActions from "@/components/common/DropdownActions";
import WidgetCard from "@/components/cards/WidgetCard";

const AboutProject = () => {

    return (
        <div className="block-container tab-body-fullpage">
            <div className="row g-20">
                <div className="col-xl-8 col-lg-8 col-md-12 col-12 flex flex-col">
                    <div className="description-info">
                        <div className="description-info__actions">
                            <h5 className="description-info__name">About Project</h5>

                            <div className="card-widget-actions gap-8">
                                <a href="#" className="btn btn--custom-1 btn--xs radius-full">
                                    <span className="ico"><EditIcon /></span>
                                    <span className="info">Edit Info</span>
                                </a>
                                <a href="#" className="btn btn--secondary-advance-1 btn--xs radius-full">
                                    <span className="ico"><StarsIcon /></span>
                                    <span>Ask Your COO</span>
                                </a>

                            </div>
                        </div>
                        <div className="description-info__body">
                            <h2>Modern Kitchen Remodel</h2>

                            <p className="description-info__text">Upgrade and create a functional, stylish kitchen space for a family of four. This project aims to enhance the kitchen's functionality by optimizing space, improving workflow, and incorporating high-quality finishes that reflect the client's modern aesthetic. The design includes new cabinetry, countertops, state-of-the-art appliances, and an open-concept layout to promote family interactions.</p>

                            <div className="">
                                <span className="description-info__label-list">Client Information:</span>
                                <ul className="description-info__list">
                                    <li className="description-info__item">
                                        <span className="description-info__item-label">Name:</span>
                                        <span className="description-info__item-value">John Doe</span>
                                    </li>
                                    <li className="description-info__item">
                                        <span className="description-info__item-label">Email:</span>
                                        <span className="description-info__item-value">john.doe@example.com</span>
                                    </li>
                                    <li className="description-info__item">
                                        <span className="description-info__item-label">Phone:</span>
                                        <span className="description-info__item-value">(123) 456-7890</span>
                                    </li>
                                    <li className="description-info__item">
                                        <span className="description-info__item-label">Address:</span>
                                        <span className="description-info__item-value">Los Angeles, CA</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="">
                                <span className="description-info__label-list">Project Details:</span>
                                <ul className="description-info__list">
                                    <li className="description-info__item">
                                        <span className="description-info__item-label">Project Address:</span>
                                        <span className="description-info__item-value">8502 Preston Rd, Inglewood, Maine</span>
                                    </li>
                                    <li className="description-info__item">
                                        <span className="description-info__item-label">Project Type:</span>
                                        <span className="description-info__item-value">Residential Remodel</span>
                                    </li>
                                    <li className="description-info__item">
                                        <span className="description-info__item-label">Estimated Project Volume:</span>
                                        <span className="description-info__item-value">$150,000</span>
                                    </li>
                                    <li className="description-info__item">
                                        <span className="description-info__item-label">Design Retainer Amount:</span>
                                        <span className="description-info__item-value">$10,000</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 flex flex-col">
                    <Documents />
                </div>
            </div>
        </div>
    );

};


function Documents () {

    return (
        <WidgetCard title="Documents" showAdd showMore>
            <div className="table-custom table-custom-2">
                <div className="table-custom-body">
                    <table className="table table-2" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <div className="table-user-info-texts">
                                        <a href="#" className="btn btn-link color-secondary tdu">
                                            <span className="text--md font-400">Modern Kitchen Remodel Concept</span>
                                        </a>
                                        <p className="color-control-2 text--sm font-400">01.02.2024</p>
                                    </div>
                                </td>
                                <td className="--dop-class-last-sticky">
                                    <div className="actions-table">
                                        <a href="#" download className="btn btn btn--square btn--xs btn-download">
                                            <span className="ico"><DownloadIcon /></span>
                                        </a>
                                        <DropdownActions
                                            actions={[{
                                                icon: <EditIcon />,
                                                label: "Edit",
                                                fn: () => console.log("Edit")
                                            }]}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </WidgetCard>
    );

}


export default AboutProject;
