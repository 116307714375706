import { clientTypeOptions, contactMethodOptions, riskLevelOptions } from "@/constants/options";
import { csvService } from "@/services/csvService";
import { IClientFormData } from "@/types";
import { ClientType, InsuranceOptionsText, RiskLevel, Status } from "@/types/enums";
import { useState } from "react";
import { checkAreTextsEqual, checkIsNameSimilarToUserName, getPhoneWithPlusSymbol } from "@/utils";
import { useLazyGetAllUsersQuery } from "@/api/usersApi";


export const useClientCsvData = () => {
    const [getUsers] = useLazyGetAllUsersQuery();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const getClientDataFromCsv = async (file: File) => {
        try {
            setIsProcessing(true);
            
            const usersResponse = await getUsers().unwrap();
            const users = usersResponse.data;
    
            const clientDataRows = await csvService.clientParser(file);

            if (clientDataRows.length === 0) {
                return null;
            }

            const clientParsedData = clientDataRows[0];

            const {street, building, zip, unit, country, state, city} = clientParsedData;

            const address = csvService.getAddressFormDataFromParsedData(
                street, building, zip, unit, country, state, city);
            
            const status = csvService.getStatusValue(clientParsedData.status);

            const clientType = clientTypeOptions.find(type => checkAreTextsEqual(
                type.label, clientParsedData.clientType))?.value;
            
            const riskLevel = riskLevelOptions.find(level => checkAreTextsEqual(level.value, 
                clientParsedData.riskLevel))?.value;

            const insurance = checkAreTextsEqual(clientParsedData.insurance, InsuranceOptionsText.Yes);
            
            const contactMethodId = contactMethodOptions.find(method => checkAreTextsEqual(method.value,
                clientParsedData.contactMethod))?.value;

            const phone = getPhoneWithPlusSymbol(clientParsedData.phone);
                
            const accountManager = users.find(user => checkIsNameSimilarToUserName(user.firstName, 
                user.lastName, clientParsedData.accountManager) || checkAreTextsEqual(user.email, 
                clientParsedData.accountManager));

            const parsedClient: IClientFormData = {
                email: clientParsedData.email || '',
                fullName: clientParsedData.fullName || '',
                companyName: clientParsedData.companyName || '',
                phone,
                photo: clientParsedData.photo || '',
                clientType: clientType as ClientType,
                riskLevel: riskLevel as RiskLevel,
                insurance: insurance,
                contactMethod: contactMethodId,
                address,
                status: status as Status,
                userId: accountManager?.id,
            }

            return parsedClient;
        } catch(error) {
            console.error(error);
        } finally {
            setIsProcessing(false);
        }
    }

    return {
        isProcessing,
        getClientDataFromCsv,
    }
}