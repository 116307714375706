import clsx from "clsx";
import React from "react";

interface NumericInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
    className?: string;
    inputClassName?: string;
    error?: boolean | string;
    onIncrement?: (value: number) => void;
    onDecrement?: (value: number) => void;
    value: number | string; // Allow value to be a string to handle empty input
    onChange: (value: number | "") => void; // Allow clearing the value
    min?: number;
    max?: number;
}

const NumericInput = React.forwardRef<HTMLInputElement, NumericInputProps>(({
    className = "input--md",
    inputClassName = "",
    error,
    onIncrement,
    onDecrement,
    value = "",
    onChange,
    min,
    max,
    ...props
}, ref) => {
    const handleValueChange = (newValue: number | "") => {
        if (newValue === "") {
            onChange(newValue);
            return;
        }
        if (min !== undefined && newValue < min) return;
        if (max !== undefined && newValue > max) return;
        onChange(newValue);
    };

    const handleIncrement = () => {
        if (value === "") {
            handleValueChange(min !== undefined ? min : 1);
            return;
        }
        const newValue = Number(value) + 1;
        handleValueChange(newValue);
        onIncrement?.(newValue);
    };

    const handleDecrement = () => {
        if (value === "") {
            handleValueChange(min !== undefined ? min : 0);
            return;
        }
        const newValue = Number(value) - 1;
        handleValueChange(newValue);
        onDecrement?.(newValue);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (newValue === "") {
            handleValueChange("");
            return;
        }
        const numericValue = Number(newValue);
        if (!isNaN(numericValue)) {
            handleValueChange(numericValue);
        }
    };

    return (
        <div className={clsx("form-group", className)}>
            <div className="numeric-input-wrapper flex items-center">
                <button
                    type="button"
                    className="numeric-input-button left"
                    onClick={handleDecrement}
                    disabled={min !== undefined && value !== "" && Number(value) <= min}
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.5 8H13.5" stroke="#565656" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <input
                    ref={ref}
                    type="text"
                    className={clsx("input input--outline w-full", inputClassName, {
                        "input-error": error,
                    })}
                    value={value}
                    onChange={handleInputChange}
                    {...props}
                />
                <button
                    type="button"
                    className="numeric-input-button right"
                    onClick={handleIncrement}
                    disabled={max !== undefined && value !== "" && Number(value) >= max}
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.5 8H13.5" stroke="#565656" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8 2.5V13.5" stroke="#565656" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
            {typeof error === "string" && <p className="error-message">{error}</p>}
        </div>
    );
});

NumericInput.displayName = "NumericInput";

export default NumericInput;
