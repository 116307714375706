import AddSecondaryIcon from "@/assets/img/ico-add-2.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import WidgetCard from "@/components/cards/WidgetCard";
import BarChart from "@/components/charts/BarChart";
import DoughnutChart from "@/components/charts/DoughnutChart";
import Search from "@/components/common/Search";
import Table from "@/components/common/Table";
import { toCurrency } from "@/utils";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { data } from "react-router";

const StatisticsTab = () => {

    return (
        <div className="row row-statistic">
            <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-12 flex flex-col statistic-navbar">
                <div className="card-widget w-full h-full card-widget-scroller">
                    <div className="card-widget-header">
                        <div className="card-widget-header-item">
                            <h5 className="heading card-widget-title btn btn--xs">Projects</h5>
                        </div>
                        <div className="card-widget-header-item">
                            <div className="card-widget-actions">
                                <Search />
                            </div>
                        </div>
                    </div>
                    <div className="card-widget-body">
                        <ul className="projects-list">

                            <li className="project-item">
                                <label className="fake-btn">
                                    <input type="radio" name="project-0" hidden checked />
                                    <div className="project-link btn">
                                        <div className="project-link-content">
                                            <p className="project-link-title">Modern Kitchen Remodel</p>
                                            <p className="project-link-desk">Customer Agreement</p>
                                        </div>
                                    </div>
                                </label>
                            </li>
                            <li className="project-item">
                                <label className="fake-btn">
                                    <input type="radio" name="project-0" hidden />
                                    <div className="project-link btn">
                                        <div className="project-link-content">
                                            <p className="project-link-title">Office Renovation</p>
                                            <p className="project-link-desk">Project Development</p>
                                        </div>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-12 col-12 flex flex-col statistic-toolbar">
                <div className="row gutters-3">
                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-4 flex flex-col">
                        <WidgetCard
                            title="Total Budget"
                            subtitle={(<p className="card-widget-desk">Actual budget for the project.</p>)}
                            headerColumn
                            bodyClassName="btn btn--xs"
                        >
                            <div className="board-custom-text">
                                <span className="board-text-char-currency">$</span>
                                <span className="board-text-val-currency">40,550.00</span>
                            </div>
                        </WidgetCard>
                    </div>
                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-4 flex flex-col">
                        <WidgetCard
                            title="Total Profit"
                            subtitle={(<p className="card-widget-desk">Our profit from the project.</p>)}
                            headerColumn
                            bodyClassName="btn btn--xs"
                        >
                            <div className="board-custom-text">
                                <span className="board-text-char-currency">$</span>
                                <span className="board-text-val-currency">10,000.00</span>
                            </div>
                        </WidgetCard>
                    </div>
                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-4 flex flex-col">
                        <WidgetCard
                            title="Material Costs"
                            subtitle={(<p className="card-widget-desk">Costs for the project materials.</p>)}
                            headerColumn
                            bodyClassName="btn btn--xs"
                        >
                            <div className="board-custom-text">
                                <span className="board-text-char-currency">$</span>
                                <span className="board-text-val-currency">20,250.00</span>
                            </div>
                        </WidgetCard>
                    </div>
                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 flex flex-col">
                        <BudgetExpensesWidget />
                    </div>
                    <div className="col-xxl-4 col-xl-6 col-lg-12 col-md-12 flex flex-col">
                        <WidgetCard
                            title="Payment Status"
                            subtitle={(<p className="card-widget-desk">Costs for the project materials.</p>)}
                            headerColumn
                            bodyClassName="btn btn--xs"
                        >
                            <ul className="payment-simple-list-statues">
                                <li className="payment-status-item">
                                    <div className="payment-status--row">
                                        <p className="payment-status--title btn--xs"
                                            title="Deposit">Deposit</p>
                                        <div className="pill-custom pill--custom-1 pill--sm">
                                            <span>Paid</span>
                                        </div>
                                    </div>
                                </li>
                                <li className="payment-status-item">
                                    <div className="payment-status--row">
                                        <p className="payment-status--title btn--xs"
                                            title="Phase 1 Payment">Phase 1 Payment</p>
                                        <div className="pill-custom pill--custom-7 pill--sm">
                                            <span>Pending</span>
                                        </div>
                                    </div>
                                </li>
                                <li className="payment-status-item">
                                    <div className="payment-status--row">
                                        <p className="payment-status--title btn--xs"
                                            title="Phase 2 Payment">Phase 2 Payment</p>
                                        <div className="pill-custom pill--custom-7 pill--sm">
                                            <span>Pending</span>
                                        </div>
                                    </div>
                                </li>
                                <li className="payment-status-item">
                                    <div className="payment-status--row">
                                        <p className="payment-status--title btn--xs"
                                            title="Final Payment">Final Payment</p>
                                        <div className="pill-custom pill--custom-7 pill--sm">
                                            <span>Pending</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </WidgetCard>
                    </div>
                    <div className="col-xxl-4 col-xl-6 col-lg-12 col-md-12 flex flex-col">
                        <ExpenseCategoriesWidget />
                    </div>
                    <div className="col-12 flex flex-col">
                        <ChangeOrders />
                    </div>
                </div>
            </div>
        </div>
    );

};


function BudgetExpensesWidget () {

    const chartData = {
        labels: ["JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOW",
            "DEC"],
        datasets: [{
            barPercentage: 0.7,
            categoryPercentage: 0.7,
            borderRadius: 5,
            label: "Budget",
            backgroundColor: "#177E89",
            borderColor: "#177E89",
            data: [28,
                48,
                40,
                19,
                86,
                27,
                90]
        }, {
            barPercentage: 0.7,
            categoryPercentage: 0.7,
            borderRadius: 5,
            label: "Expenses",
            backgroundColor: "#891719",
            borderColor: "#891719",
            data: [65,
                59,
                80,
                81,
                56,
                55,
                40]
        }]
    };
    return (
        <WidgetCard
            title="Budget vs Expenses"
            subtitle={(<p className="card-widget-desk">Costs for the project materials.</p>)}
            headerColumn
            bodyClassName="btn btn--xs"
        >
            <BarChart data={chartData} />
        </WidgetCard>
    );

}

function ExpenseCategoriesWidget () {

    const data = {
        labels: ["Materials", "Labor", "Permits & Fees", "Miscellaneous"],
        data: [50000, 70000, 10000, 20000],
        format: (val: number) => toCurrency(val)
    };

    const total = {
        label: "Total Budget",
        format: (val: number) => toCurrency(val)
    };

    return (
        <WidgetCard
            title="Expense Categories"
            headerColumn
        >
            <DoughnutChart data={data} total={total} variant="secondary" />
        </WidgetCard>
    );

}

interface ChangeOrdersData {
    orderId: string;
    description: string;
    impactOnbudget: number;
    date: string;
    status: "Implemented" | "Pending"
}
function ChangeOrders () {

    const data: ChangeOrdersData[] = [{
        orderId: "CO-001",
        description: "Added kitchen island",
        impactOnbudget: 3500,
        date: "Oct 12, 2024",
        status: "Implemented"
    }, {
        orderId: "CO-002",
        description: "Added kitchen island",
        impactOnbudget: -2500,
        date: "Oct 12, 2024",
        status: "Pending"
    }];

    const columns: ColumnDef<ChangeOrdersData>[] = [{
        header: "Change Order ID",
        accessorKey: "orderId",
        minSize: 228,

        cell: ({ row: { original } }) => <p className="font-500 nowrap">{original.orderId}</p>

    },
    {
        header: "Description",
        accessorKey: "description",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.description}</p>

    },
    {
        header: "Impact on Budget ($)",
        accessorKey: "impactOnbudget",
        minSize: 228,
        cell: ({ row: { original } }) => <p className={clsx("font-500 nowrap", {
            "color-secondary": original.impactOnbudget >= 0,
            "color-status-6": original.impactOnbudget < 0
        })}>{original.impactOnbudget >= 0
                ? "+"
                : "-"} {Math.abs(original.impactOnbudget)}</p>

    },
    {
        header: "Date Issued",
        accessorKey: "date",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.date}</p>

    },
    {
        header: "Status",
        accessorKey: "status",
        cell: ({ row: { original } }) => <div className={clsx("pill-custom pill--sm", {
            "pill--custom-1": original.status === "Implemented",
            "pill--custom-7": original.status === "Pending"
        })}>
            <span>{original.status}</span>
        </div>

    }];

    return (
        <div className="card-widget card-widget--md w-full h-full card-widget-scroller card-widget-sticky">
            <div className="card-widget-header">
                <div className="card-widget-header-item">
                    <h5 className="heading card-widget-title btn btn--xs">Change Orders</h5>
                </div>
                <div className="card-widget-header-item">
                    <div className="card-widget-actions">
                        <button type="button" className="btn btn--secondary-advance-1 btn--xs rounded-full">
                            <span className="ico"><StarsIcon /></span>
                            <span>Ask Your CFO</span>
                        </button>
                        <button type="button" className="btn btn--primary-advance-1 btn--square btn--xs rounded-full">
                            <span className="ico"><AddSecondaryIcon /></span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-widget-body">
                <Table columns={columns} data={data} />
            </div>
        </div>
    );

}


export default StatisticsTab;
