import Button from "@/components/common/Button";
import { ActionButton, VoidFunctionWithoutArguments } from "@/types";
import PlusIcon from "@/assets/img/plus.svg?react";
import IconCheck from "@/assets/img/ico-check-white.svg?react";

interface CreateCompleteProps {
    actionButtons: ActionButton[];
    title: string;
    description: string;
    handleCancelClick?: VoidFunctionWithoutArguments;
}

const CreationComplete: React.FC<CreateCompleteProps> = ({
    title,
    description,
    actionButtons,
    handleCancelClick
}) => {

    return (
        <div className="pt-6 pb-1 px-6">
            <div
                className="flex justify-center mb-5"
            >
                <span className="ico ico--md rounded-full bg-secondary"><IconCheck /></span>
            </div>

            <h4 className="heading flex-auto text-center p-2 mb-4">{title}</h4>

            <p className="text-center color-control-3 pb-4">{description}</p>

            <div className="modal__footer justify-center px-0">
                {actionButtons.map((curButton, i) => 
                    <Button
                        key={i}
                        onClick={curButton.handler}
                        uiType="custom-1"
                        className="mx-2"
                    >
                        {curButton.text}
                        <span className="ms-2 flex items-center"><PlusIcon /></span>
                    </Button>
                )

                }
                <Button
                    uiType="primary-advance-1"
                    onClick={() => {

                        if (handleCancelClick) {

                            handleCancelClick(); // TODO: Delete it after true complete action implementation

                        }

                    }}
                >
                    <span>Complete</span>
                </Button>
            </div>
        </div>
    );

};

export default CreationComplete;
