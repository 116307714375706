import { useTabs } from "@/hooks/useTabs";
import { useCallback, useEffect } from "react";

import CloseIcon from "@/assets/img/close-icon.svg?react";
import { clientSchema } from "@/validation/clientSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { IClientFormData, VoidFunctionWithoutArguments } from "@/types";
import ClientDetails from "../tabs/ClientDetails";
import Address from "../tabs/Address";
import Contacts from "../tabs/Contacts";
import AccountManager from "../tabs/AccountManager";
import StepsNavigation from "@/components/common/StepsNavigation";
import { ClientType, ContactMethod, RiskLevel, Status } from "@/types/enums";
import { useCreateClientMutation, useUpdateClientMutation } from "@/api/clientsApi";
import EntityFooter from "@/components/modals/NewEntityModal/EntityFooter";
import { normalizePhone } from "@/utils";

interface ClientDataProps {
    handleCancel: VoidFunctionWithoutArguments;
    handleCompleteClick: VoidFunctionWithoutArguments;
    data: IClientFormData;
    title?: string;
}

const detailsFields = ["photo",
    "clientType",
    "riskLevel",
    "fullName",
    "companyName",
    "insurance",
    "status"] as const
const addressFields = [
    "address",
] as const
const contactsFields = ["email", "phone", "phoneCountry", "contactMethod"] as const
const accountFields = ["userId"] as const

const tabs = [{
    label: "Client Details",
    id: "details",
    fields: detailsFields
}, {
    label: "Address",
    id: "address",
    fields: addressFields
}, {
    label: "Contacts",
    id: "contacts",
    fields: contactsFields
}, {
    label: "Account Manager",
    id: "account-manager",
    fields: accountFields
}] as const;

type TabId = typeof tabs[number]["id"];


const ClientData: React.FC<ClientDataProps> = ({
    handleCancel,
    handleCompleteClick,
    data,
    title = 'New Client'
}) => {
    const clientId = data?.id
    const [create, { isLoading: isCreating }] = useCreateClientMutation();
    const [update, { isLoading: isUpdating }] = useUpdateClientMutation();
    const isLoading = isUpdating || isCreating
    const { activeTab, setActiveTab, activeTabIndex, destroy } = useTabs(tabs, "mt");

    const currentSchema = clientSchema.pick(tabs[activeTabIndex].fields);
    const resolver = yupResolver(currentSchema);
    const formState = useForm({
        resolver,
        defaultValues: data || {
            fullName: "",
            companyName: "",
            insurance: true,
            riskLevel: RiskLevel.LOW,
            clientType: ClientType.RESIDENTAL,
            status: Status.ACTIVE,
            address: {
                street: "8502 Preston Rd",
                building: "2A",
                zip: "09876",
                unit: "12",
            },
            contactMethod: ContactMethod.EMAIL
        }
    });
    const { register, handleSubmit, formState: { errors }, reset } = formState;


    const onSubmit = async (data: any) => {
        console.log("Form data:", data);
        let res;
        if (activeTabIndex < tabs.length - 1) {

            setActiveTab(tabs[activeTabIndex + 1].id);

        } else {
            const normalizedData = { ...data, phone: normalizePhone(data.phone) }
            if (clientId) {
                res = await update({ clientId, body: normalizedData }).unwrap()
            } else {
                res = await create(normalizedData).unwrap()
            }
            if (res?.success) {
                handleCompleteClick();
            }
            console.log("Final Submit:", data);

        }

    };

    useEffect(() => {

        return () => destroy();

    }, []);

    useEffect(() => {

        console.log("errors", errors);

    }, [errors]);


    const tabComponents: Record<TabId, JSX.Element | null> = {
        details: <ClientDetails formState={formState} />,
        address: <Address formState={formState} />,
        contacts: <Contacts formState={formState} />,
        "account-manager": <AccountManager formState={formState} />
    };

    const handleGoBack = useCallback(() => {
        setActiveTab(tabs[activeTabIndex - 1].id);
    }, [activeTabIndex]);

    const renderTab = () => {
        return tabComponents[activeTab];
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__header">
                <h4 className="heading flex-auto">{title}</h4>
                <div className="modal__header-actions">
                    <button type="button" onClick={handleCancel} className="btn btn--custom-1 btn--md rounded-full btn--square">
                        <span className="ico"><CloseIcon /></span>
                    </button>
                </div>
            </div>

            <div className="modal__body pt-0">
                <div className="w-full">
                    <StepsNavigation
                        activeStep={activeTab}
                        setActiveStep={setActiveTab}
                        steps={tabs}
                    />
                    <div className="modal-tabs">
                        {renderTab()}
                    </div>
                </div>
            </div>

            <EntityFooter
                isLoading={isLoading}
                handleGoBack={handleGoBack}
                handleCancel={handleCancel}
                isFirstStep={activeTab === "details"}
                completeText="Complete"
                nextStepText="Next Step"
                isFinalStep={activeTab === "account-manager"}
            />
        </form>
    );

};

export default ClientData;
