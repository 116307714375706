import { ModalProps } from "@/components/modals/Modal";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";

import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import SupplierData from "./components/SupplierData";

interface NewClientModal extends ModalProps {
    data: any
}



const NewSupplierModal = ({ data, ...props }: NewClientModal) => {
    const { currentStage,
        handleCompleteClick,
        handleContinueClick,
        handleCancel,
        handleGoToSelection
    } = useNewEntityModal();

    const completeActionButtons = [{
        text: "Add Supplier",
        handler: () => { }
    }];
    
    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New Supplier Created!"
            completeDescription="Congratulations on successfully creating a new supplier! You can now create new supplier or return to the home page."
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
            handleBackToSelectionClick={handleGoToSelection}
            handleCompleteClick={handleCompleteClick}
        >
            <SupplierData 
                data={data} 
                handleCancel={handleCancel} 
                handleCompleteClick={handleCompleteClick} 
            />
        </NewEntityModal>
    );
};

export default NewSupplierModal;
