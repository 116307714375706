
import BoxIcon from "@/assets/img/ico-box.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import projectImageThumb from "@/assets/img/project-ico.jfif";
import projectImage from "@/assets/img/project-img-1.jpg";

import DropdownActions from "@/components/common/DropdownActions";
import Table from "@/components/common/Table";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";

interface Project {
    project: string;
    status: {
        label: string;
        className: string;
    };
    client: string;
    budget: string;
    expenses: string;
    recieved: string;
    outstanding: string;
}

interface InventoryTabProps {
    isGridView: boolean
    isTableView: boolean
}
const OverviewTab = ({ isGridView, isTableView }: InventoryTabProps) => {

    const data: Project[] = [{

        project: "Modern Kitchen Remodel",
        status: {
            label: "On Track",
            className: "pill--custom-1"
        },
        client: "John Doe",
        budget: "$50,000",
        expenses: "$32,000",
        recieved: "$30,000",
        outstanding: "$2,000"
    }, {

        project: "Modern Kitchen Remodel",
        status: {
            label: "On Track",
            className: "pill--custom-1"
        },
        client: "John Doe",
        budget: "$50,000",
        expenses: "$32,000",
        recieved: "$30,000",
        outstanding: "$2,000"
    }, {

        project: "Modern Kitchen Remodel",
        status: {
            label: "On Track",
            className: "pill--custom-1"
        },
        client: "John Doe",
        budget: "$50,000",
        expenses: "$32,000",
        recieved: "$30,000",
        outstanding: "$2,000"
    }];

    const actions = (arg: Project) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => console.log(arg)
    }];
    const columns: ColumnDef<Project>[] = [{
        header: "Project Name",
        accessorKey: "project",
        minSize: 228,
        cell: ({ row: { original } }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{original.project}</span>
        </a>

    },
    {
        header: "Client",
        accessorKey: "client",
        cell: ({ row: { original } }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{original.client}</span>
        </a>

    },
    {
        header: "Budget ($)",
        accessorKey: "budget",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.budget}</p>

    },
    {
        header: "Expenses ($)",
        accessorKey: "expenses",
        cell: ({ row: { original } }) => <p className="font-500">{original.expenses}</p>

    },
    {
        header: "Payments Received ($)",
        accessorKey: "recieved",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.recieved}</p>

    },
    {
        header: "Outstanding ($)",
        accessorKey: "outstanding",
        cell: ({ row: { original } }) => <p className="color-status-6">{original.outstanding}</p>

    },
    {
        header: "Status",
        accessorKey: "status",
        cell: ({ row: { original } }) => <div className={clsx("pill-custom pill--sm", original.status.className)}>
            <span>{original.status.label}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            variant="secondary"
            actions={actions(original)}
        />

    }];

    if (isGridView) {

        return (
            <div className="row gutters-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">
                    <div className="card-widget card-widget-product w-full h-full">
                        <div className="card-widget-body">
                            <div className="card-details-wrpr">
                                <div className="card-details--item">
                                    <div className="card-details--head">
                                        <div className="user-option-block user-option-block--sm">
                                            <div className="user-block-relatives">
                                                <div className="ico ico--lg user-block-img-wrpr">
                                                    <img src={projectImage} alt="project" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-details-actions">
                                            <DropdownActions actions={[]} />
                                        </div>
                                    </div>
                                    <div className="card-details--body">
                                        <div className="card-details-heading">
                                            <p className="heading user-block-title text--xl">
                                                Modern Kitchen Remodel
                                            </p>
                                        </div>

                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.7884 11.8708V10.7884C10.7884 10.2142 10.5603 9.66358 10.1543 9.2576C9.74836 8.85161 9.19773 8.62354 8.62358 8.62354H5.37636C4.80222 8.62354 4.25159 8.85161 3.84561 9.2576C3.43963 9.66358 3.21155 10.2142 3.21155 10.7884V11.8708" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M4.83521 4.29421C4.83521 4.86835 5.06328 5.41898 5.46926 5.82496C5.87525 6.23095 6.42588 6.45902 7.00002 6.45902C7.57416 6.45902 8.12479 6.23095 8.53078 5.82496C8.93676 5.41898 9.16483 4.86835 9.16483 4.29421C9.16483 3.72006 8.93676 3.16944 8.53078 2.76345C8.12479 2.35747 7.57416 2.12939 7.00002 2.12939C6.42588 2.12939 5.87525 2.35747 5.46926 2.76345C5.06328 3.16944 4.83521 3.72006 4.83521 4.29421Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </span>
                                                    <p className="project-variable--text">
                                                        Client Title
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-statuses">
                                                    <div className="pill-custom pill--custom-1 pill--sm"><span>On Track</span></div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="card-details--item">
                                    <div className="card-details--body">
                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Budget ($):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">$50,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Expenses ($):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">$32,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Payments Received ($):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">$30,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Outstanding ($):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">$2,000</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="card-details--actions">
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span>Change Status</span>
                                                </a>
                                            </div>
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span className="ico"><EditIcon /></span>
                                                    <span>Edit</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    return <Table columns={columns} data={data} selectable />;

};

export default OverviewTab;
