import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Spinner from "../common/Spinner";

export interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    title: string;
    isLoading?: boolean;
    wrapperClassName?: string;
    contentClassName?: string;
    dialogClassName?: string;
    animationDuration?: number;
    closeOnClickOutside?: boolean
}

const Modal = ({
    isOpen,
    onClose,
    isLoading = false,
    children,
    contentClassName,
    dialogClassName = "modal__dialog--400",
    wrapperClassName,
    animationDuration = 150,
    closeOnClickOutside = true
}: ModalProps) => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {

        if (isOpen) {

            setIsVisible(true);
            const timeout = setTimeout(() => setIsVisible(true), animationDuration);
            return () => clearTimeout(timeout);

        } else {

            const timeout = setTimeout(() => setIsVisible(false), animationDuration);
            return () => clearTimeout(timeout);

        }

    }, [isOpen, animationDuration]);

    return (
        <div
            className={clsx("modal modal-1", {
                "modal--show": isVisible,
                "modal--hide": !isVisible
            }, wrapperClassName)}
            onClick={closeOnClickOutside
                ? onClose
                : null}
            style={{ transitionDuration: `${animationDuration}ms` }}
        >
            <div
                className={clsx("modal__dialog", dialogClassName)}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={clsx("modal__content", contentClassName)}>
                    {isLoading
                        ? <div className="modal__body p-0">
                            <div className="flex items-center justify-center min-h-300">
                                <Spinner />
                            </div>
                        </div>
                        : children
                    }
                </div>
            </div>
        </div>
    );

};

export default Modal;
