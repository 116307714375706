import { LoginRequest } from "@/types/request";
import type { ResponseType, User } from "../types";
import { baseApi } from "./baseApi";
import { usersApi } from "./usersApi";


const base = "api/auth";

export const authApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<
            ResponseType<{ token: string }>,
            LoginRequest
        >({
            query: (body) => ({
                url: `${base}/signin`,
                method: "POST",
                body
            })
        }),
        logout: builder.query<
            ResponseType<null>,
            void
        >({
            query: (query) => `${base}/logout`,
            async onQueryStarted (_, { dispatch, queryFulfilled }) {

                try {

                    await queryFulfilled;
                    dispatch(usersApi.util.invalidateTags(["me"]));

                    dispatch(usersApi.util.resetApiState());

                } catch (err) {

                    console.error("Failed to logout:", err);

                }

            }
        })

    })
});

export const {
    useLoginMutation,
    useLazyLogoutQuery
} = authApi;
