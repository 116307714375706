import UploadPhotoGrid from "@/components/common/UploadPhotoGrid";
import { ProjectFormRequest } from "@/validation/projectSchema";
import { UseFormReturn } from "react-hook-form";


interface PhotosProps {
    formState: UseFormReturn<ProjectFormRequest>
}

const Photos = ({ formState }: PhotosProps) => {

    const { register, watch, setValue, formState: { errors, defaultValues } } = formState;


    return (
        <div className="row g-20">
            <div className="col-12">
                <UploadPhotoGrid
                    label={'Project photos (20 max)'}
                    photos={defaultValues.photos as string[] || []}
                    maxPhotos={6}
                    onChange={(files) => setValue('photos', files)}
                />
            </div>
        </div>
    );

};

export default Photos;
