import { useEffect, useState } from "react";
import { useSearchParams } from "react-router";

export interface Tab<T> {
    readonly id: T
    readonly label: string
}


export const useTabs = <T extends string>(tabs: readonly Tab<T>[], query: string = "t") => {

    type TabId = typeof tabs[number]["id"];

    const [searchParams, setSearchParams] = useSearchParams();

    const defaultTab = tabs[0].id as TabId;
    const [activeTab, setActiveTab] = useState<TabId>((searchParams.get(query) as TabId) || defaultTab);

    useEffect(() => {

        setSearchParams((prev) => {
            const copy = new URLSearchParams(prev) 
            copy.set(query, activeTab);
            return copy;

        });

    }, [activeTab, setSearchParams]);

    const destroy = (params?: string) => {
        setSearchParams((prev) => {

            const copy = new URLSearchParams(prev) 
            copy.delete(query, activeTab);
            if(params) {
                copy.delete(params);
            }
            return copy;
        });

    };
    return {
        activeTab,
        setActiveTab,
        activeTabObject: tabs.find(({ id }) => activeTab === id),
        activeTabIndex: tabs.findIndex(({ id }) => activeTab === id),
        destroy
    };

};
