import { ICONS } from "@/utils/teamsIcons";
import { useRef, useState } from "react";
import Dropdown from "./Dropdown";
import clsx from "clsx";
import ChevronDownIcon from "@/assets/img/ico-chevron-down.svg?react";

interface IconSelectProps {
    initialIcon?: keyof typeof ICONS;
    onSelect?: (icon: keyof typeof ICONS) => void;
}

const IconSelect: React.FC<IconSelectProps> = ({ initialIcon = 'pm', onSelect }) => {
    const [selectedIcon, setSelectedIcon] = useState<keyof typeof ICONS>(initialIcon);
    const dropdownRef = useRef<{ close: () => void } | null>(null);
    
    const handleIconSelect = (icon: keyof typeof ICONS) => {
        setSelectedIcon(icon);
        if (onSelect) {
            onSelect(icon);
        }

        dropdownRef.current?.close();
    };

    const SelectedIconComponent = ICONS[selectedIcon];

    return (
        <Dropdown
            button={
                <div className="flex items-center cursor-pointer">
                    <span className="flex icon-dropdown justify-center rounded-12 items-center">
                        <SelectedIconComponent className="icon" />
                    </span>
                    <span className="ico icon ps-3">
                        <ChevronDownIcon />
                    </span>
                </div>
            }
            buttonClassName="flex items-center"
            bodyClassName="p-2 bg-white shadow rounded"
            dropdownRef={dropdownRef}
        >
            <div className="grid grid-cols-3 gap-2">
                {Object.entries(ICONS).map(([key, IconComponent]) => (
                    <div
                        key={key}
                        className={clsx(
                            "p-4 mb-1 cursor-pointer border rounded-12 flex justify-center items-center border-0_5",
                            selectedIcon === key ? "border-secondary" : "border-pale"
                        )}
                        onClick={() => handleIconSelect(key as keyof typeof ICONS)}
                    >
                        <IconComponent className="icon" />
                    </div>
                ))}
            </div>
        </Dropdown>
    )
}

export default IconSelect