import clsx from "clsx";
import React from "react";

interface CircleProgressIcon {
    progress: number
    total: number
}

const CircleProgressIcon = ({ progress, total }: CircleProgressIcon) => {

    return (
        <div className={clsx("progress-wrpr", { "--inactive": !progress })} style={{ "--filled-value--prc": progress / 12 * 100 } as React.CSSProperties}>
            {progress >= total
                ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0ZM16.7696 8.70711C17.1601 8.31658 17.1601 7.68342 16.7696 7.29289C16.3791 6.90237 15.7459 6.90237 15.3554 7.29289L9.0625 13.5858L6.70711 11.2304C6.31658 10.8399 5.68342 10.8399 5.29289 11.2304C4.90237 11.6209 4.90237 12.2541 5.29289 12.6446L8.35539 15.7071C8.74592 16.0976 9.37908 16.0976 9.76961 15.7071L16.7696 8.70711Z" fill="#177E89" />
                </svg>
                : <svg className="doughnut-progress ico">
                    <circle className="doughnut-wrpr"></circle>
                    <circle className="doughnut-line-progress"></circle>
                </svg>
            }
            <div className="progress-texts">
                <span className="progress-completed">{progress}</span>
                <span className="progress-left">/{total}</span>
            </div>
        </div>
    );

};

export default CircleProgressIcon;
