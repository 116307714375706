import Select from "@/components/common/Select";
import { languages } from "@/constants";
import React from "react";
import blackThemeBg from "@/assets/img/black-theme--bg.svg";
import whiteThemeBg from "@/assets/img/white-theme--bg.svg";

const Customization = () => {

    return (
        <>
            <div className="tab-body__item-border">
                <div className="tab-body__item">
                    <div className="settings-item-group">
                        <div className="settings-item__label-group">
                            <label className="settings-item__label">Interface Theme</label>
                            <p className="settings-item__descripton">Select or customize your UI theme</p>
                        </div>
                        <div className="settings-item__options">
                            <div className="row gutters-1">
                                <div className="col-md-4 col-sm-6 col-12">
                                    <label className="fake-btn">
                                        <input type="radio" name="theme-mode" checked id="white-2" hidden />
                                        <div className="mode-button">
                                            <div
                                                className="mode-button-image"
                                                style={{
                                                    "--image-theme": whiteThemeBg,
                                                    "--color-theme": "var(--color-control-7)"
                                                } as React.CSSProperties}
                                            ></div>
                                            <div className="mode-button-activate">
                                                <span className="ico mode-ico--activate"></span>
                                                <span className="mode-text--activate">Light Mode</span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    <label className="fake-btn">
                                        <input type="radio" name="theme-mode" id="back" hidden />
                                        <div className="mode-button">
                                            <div
                                                className="mode-button-image"
                                                style={{
                                                    "--image-theme": blackThemeBg,
                                                    "--color-theme": "var(--color-control-10)"
                                                } as React.CSSProperties}
                                            ></div>
                                            <div className="mode-button-activate">
                                                <span className="ico mode-ico--activate"></span>
                                                <span className="mode-text--activate">Dark Mode</span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-body__item">
                <div className="settings-item-group">
                    <div className="settings-item__label-group">
                        <label className="settings-item__label">Language</label>
                        <p className="settings-item__descripton">Default language for the whole platform</p>
                    </div>
                    <div className="settings-item__options">

                        <div className="row gx-16 gy-18">
                            <div className="col-12">
                                <Select
                                    label="Language"
                                    options={languages}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default Customization;
