import TabNavigation from "@/components/common/TabNavigation";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { useTabs } from "@/hooks/useTabs";
import { routerConfig } from "@/routes";
import { useLocation } from "react-router";

import DropdownActions from "@/components/common/DropdownActions";
import BoardSteps from "./components/BoardSteps";
import AboutProject from "./tabs/AboutProject";
import BudgetFinancials from "./tabs/BudgetFinancials";
import Documents from "./tabs/Documents";
import Materials from "./tabs/Materials";
import Notes from "./tabs/Notes";
import Overview from "./tabs/Overview";
import Tasks from "./tabs/Tasks";
import Team from "./tabs/Team";
import Timeline from "./tabs/Timeline";

import BellIcon from "@/assets/img/Bell--Streamline-Lucide.svg?react";
import ChevronDownIcon from "@/assets/img/Chevron-Down--Streamline-Lucide.svg?react";
import FireIcon from "@/assets/img/Fire--Streamline-Bootstrap.svg?react";
import ChevronNextIcon from "@/assets/img/ico-chevron-next-2.svg?react";
import ChevronPrevIcon from "@/assets/img/ico-chevron-prev-2.svg?react";
import DotsIcon from "@/assets/img/ico-dots.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import PlusIcon from "@/assets/img/plus.svg?react";

import projectImage from "@/assets/img/project-img-3.jpg";
import userImage1 from "@/assets/img/user-1.png";
import Dropdown from "@/components/common/Dropdown";

interface ProjectPageProps { }

const tabs = [{ id: "overview",
    label: "Overview" },
{ id: "about-project",
    label: "About Project" },
{ id: "tasks",
    label: "Tasks" },
{ id: "timeline",
    label: "Timeline" },
{ id: "budget-financials",
    label: "Budget & Financials" },
{ id: "materials",
    label: "Materials" },
{ id: "team",
    label: "Team" },
{ id: "documents",
    label: "Documents" },
{ id: "notes",
    label: "Notes" }] as const;

type TabId = typeof tabs[number]["id"];


const ProjectPage = () => {

    const { pathname } = useLocation();
    const { activeTab, setActiveTab } = useTabs(tabs);

    useBreadcrumbs([routerConfig.projects, { path: pathname,
        name: "Modern Kitchen Remodel" }]);

    const columns = [{ title: "To Do",
        taskCount: 3 }, { title: "In Progress",
        taskCount: 3 }, { title: "On Review",
        taskCount: 2 }, { title: "Completed",
        taskCount: 4 }];
    const tasks = {
        "To Do": [{
            priority: "High Priority",
            title: "Install wall cabinets",
            dueDate: "Nov 10, 2024",
            progress: 0,
            totalProgress: 12,
            documents: 5,
            comments: 5,
            users: [userImage1, userImage1]
        }]
    };

    const tabComponents: Record<TabId, JSX.Element> = {
        overview: <Overview />,
        "about-project": <AboutProject />,
        tasks: <Tasks columns={columns} tasks={tasks} />,
        timeline: <Timeline />,
        "budget-financials": <BudgetFinancials />,
        materials: <Materials />,
        team: <Team />,
        documents: <Documents />,
        notes: <Notes />
    };

    const renderTab = () => {

        return tabComponents[activeTab];

    };

    return (
        <main className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="block-container navigation-block">
                    <div className="heading--row">
                        <div className="heading-option">
                            <div className="project-img-46x46">
                                <img src={projectImage} alt="project" />
                            </div>
                            <div className="flex flex-col gap-8">
                                <div className="project-title-group">
                                    <span className="project-title-percent">28%</span>
                                    <h5>Modern Kitchen Remodel</h5>
                                    <div className="project-title-decor">
                                        <FireIcon />
                                    </div>
                                </div>
                                <p className="text--sm color-control-2">8502 Preston Rd. Inglewood, Maine</p>
                            </div>
                        </div>
                        <div className="heading-option">
                            <div className="flex items-center gap-8">
                                <a href="#" className="btn btn--custom-1 btn--xs rounded-full btn--square">
                                    <span className="ico">
                                        <PlusIcon />
                                    </span>
                                </a>
                                <ul className="user-list">
                                    <li className="user-item user-item-30x30">
                                        <img src={userImage1} alt="user" />
                                    </li>
                                    <li className="user-item user-item-30x30">
                                        <img src={userImage1} alt="user" />
                                    </li>
                                    <li className="user-item user-item-30x30">
                                        <img src={userImage1} alt="user" />
                                    </li>
                                    <li className="user-item" style={{ display: "none" }}><img src={userImage1} alt="user" /></li>
                                    <li className="user-item" style={{ display: "none" }}><img src={userImage1} alt="user" /></li>
                                </ul>
                                <span className="users-counter">+24 users</span>
                            </div>
                            <span className="option-separator"></span>

                            <Dropdown
                                button={(
                                    <button type="button" className="permit-expedition dropdown-btn" data-role="button">
                                        <div className="label-project-stage">Project Stage:</div>
                                        <div className="permit-expedition__arrow-group">
                                            <span>Permit Expedition</span>
                                            <div className="permit-expedition__arrow">
                                                <ChevronDownIcon />
                                            </div>
                                        </div>
                                    </button>
                                )}
                                bodyClassName="dropdown__table"
                            >
                                content
                            </Dropdown>
                            <button type="button" className="btn btn--secondary-advance-1 ico--md rounded-full">
                                <span className="ico"><StarsIcon /></span>
                            </button>

                            <a href="#" className="btn btn--square ico--md btn--custom-1 rounded-full max-w-max">
                                <span className="ico"><BellIcon /></span>
                            </a>


                            <DropdownActions
                                button={(
                                    <button type="button" className="btn btn--square btn--xs btn--custom-1-trsp rounded-full max-w-max dropdown-btn dropdown-btn--burger" data-role="button">
                                        <span className="ico"><DotsIcon /></span>
                                    </button>
                                )}
                                actions={[]}
                            />
                        </div>
                    </div>
                </div>

                <div className="block-container">
                    <BoardSteps />
                </div>

                <div className="tabs tabs-fullpage">
                    <div className="tabs-row-wrpr block-container">
                        <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} variant="secondary" />
                        <div className="group-date-controls">
                            <button type="button" className="btn btn--xl btn--square btn--custom-1-trsp btn-date-control">
                                <span className="ico"><ChevronPrevIcon /></span>
                            </button>
                            <div className="btn btn--xl date-value-view-wrpr">
                                <span className="date-value-view--text">October 2024</span>
                            </div>
                            <button type="button" className="btn btn--xl btn--square btn--custom-1-trsp btn-date-control">
                                <span className="ico"><ChevronNextIcon /></span>
                            </button>
                        </div>
                    </div>
                    <div className="tabs-body-content">
                        <div className="tab tab--bg active-tab" data-id="tasks">
                            {renderTab()}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

};

export default ProjectPage;
