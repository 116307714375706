import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { useTabs } from "@/hooks/useTabs";
import { routerConfig } from "@/routes";
import { getFullUrl } from "@/utils";
import { useLocation, useParams } from "react-router";
import Overview from "./tabs/Overview";

import userImage from "@/assets/img/ava-image-2.png";
import AddIcon from "@/assets/img/ico-add.svg?react";
import ChevronNextIcon from "@/assets/img/ico-chevron-next-2.svg?react";
import ChevronPrevIcon from "@/assets/img/ico-chevron-prev-2.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import BellIcon from "@/assets/img/ico-notification.svg?react";
import AssistantButton from "@/components/common/AssistantButton";
import TabNavigation from "@/components/common/TabNavigation";


const tabs = [{ id: "overview",
    label: "Overview" },
{ id: "members",
    label: "Members" },
{ id: "projects",
    label: "Projects" },
{ id: "tasks",
    label: "Tasks" },
{ id: "history",
    label: "History" },
{ id: "documents",
    label: "Documents" },
{ id: "notes",
    label: "Notes" }] as const;

type TabId = typeof tabs[number]["id"];

const UserPage = () => {

    const { pathname } = useLocation();
    const { teamId, userId } = useParams();
    if (!teamId) return;
    useBreadcrumbs([routerConfig.team, { path: getFullUrl([routerConfig.team.path, teamId]),
        name: "Concept Design Team" }, { path: pathname,
        name: "Cameron Williamson" }]);


    const { activeTab, setActiveTab } = useTabs(tabs);

    const tabComponents: Record<TabId, JSX.Element> = {
        overview: <Overview />,
        members: <></>,
        projects: <></>,
        tasks: <></>,
        history: <></>,
        documents: <></>,
        notes: <></>
    };

    const renderTab = () => {

        return tabComponents[activeTab];

    };
    return (
        <main className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="block-container">
                    <div className="heading--row">
                        <div className="heading-option">
                            <div className="user-option-block">
                                <div className="ico ico--xl user-block-img-wrpr">
                                    <img src={userImage} alt="user" />
                                </div>
                                <div className="user-block-content-info">
                                    <div className="user-block-titled-info">
                                        <h5 className="heading user-block-title">Bessie Cooper</h5>
                                    </div>
                                    <div className="user-block-desk-info">
                                        <p className="user-role--text">Interior Designer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="heading-option">
                            <div className="flex-controls gap--xs">
                                <a href="#" className="btn btn--primary-advance-1 btn--md rounded-full">
                                    <span className="ico"><AddIcon /></span>
                                    <span>New Task</span>
                                </a>
                                <a href="#" className="btn btn--custom-1 btn--md rounded-full">
                                    <span className="ico"><EditIcon /></span>
                                    <span>Edit</span>
                                </a>
                            </div>
                            <span className="option-separator"></span>
                            <div className="flex-controls gap--xs">
                                <AssistantButton />
                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn--square">
                                    <span className="ico"><BellIcon /></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tabs tabs-fullpage">
                    <div className="tabs-row-wrpr block-container">
                        <TabNavigation
                            variant="secondary"
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab} />
                        <div className="group-date-controls">
                            <button type="button" className="btn btn--xl btn--square btn--custom-1-trsp btn-date-control">
                                <span className="ico"><ChevronPrevIcon /></span>
                            </button>
                            <div className="btn btn--xl date-value-view-wrpr">
                                <span className="date-value-view--text">October 2024</span>
                            </div>
                            <button type="button" className="btn btn--xl btn--square btn--custom-1-trsp btn-date-control">
                                <span className="ico"><ChevronNextIcon /></span>
                            </button>
                        </div>
                    </div>
                    <div className="tabs-body-content">
                        <div className="tab tab--bg active-tab">
                            {renderTab()}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

};

export default UserPage;
