

import DownloadIcon from "@/assets/img/ico-download.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";

import userImage from "@/assets/img/img-user-1.png";
import WidgetCard from "@/components/cards/WidgetCard";
import DropdownActions from "@/components/common/DropdownActions";
import ProgressSteps from "@/components/common/ProgressSteps";
import { contactMethodMap, DATE_FORMAT, riskLevelMap, statusMap } from "@/constants";
import { routerConfig } from "@/routes";
import { Client } from "@/types";
import { capitalize, formatAddress, getFullUrl } from "@/utils";
import clsx from "clsx";
import { format } from "date-fns";
import { Link } from "react-router";

interface OverviewProps {
    client: Client
}

const Overview = ({ client }: OverviewProps) => {

    return (
        <div className="block-container tab-body-fullpage">
            <div className="row gutters-3">
                <div className="col-xl-4 col-lg-6 col-md-12 flex flex-col">
                    <AboutClient data={client} />
                </div>
                <div className="col-xl-8 col-lg-6 col-md-12 flex flex-col">
                    <div className="row gutters-3">
                        <div className="col-xl-6 col-lg-12 flex flex-col">
                            <CurrentProject />
                        </div>
                        <div className="col-xl-6 col-lg-12 flex flex-col">
                            <OutstandingPayments />
                        </div>
                        <div className="col-xl-6 col-lg-12 flex flex-col">
                            <LastNotes />
                        </div>
                        <div className="col-xl-6 col-lg-12 flex flex-col">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};


function OutstandingPayments() {


    return (
        <WidgetCard title="Outstanding Payments">
            <div className="empty-block text-center justify-center">
                <span className="ico empty-ico mx-auto">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.30243 9.36523H8.95587" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="square"></path>
                        <path d="M6.30243 12.9092H13.3814" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="square"></path>
                        <path d="M6.30243 16.4531H13.3814" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="square"></path>
                        <path d="M19.5882 21.7601H5.41178C4.70804 21.7601 4.03312 21.4806 3.53551 20.983C3.03789 20.4854 2.75833 19.8104 2.75833 19.1067V3.16748L5.07778 4.93954L7.48072 3.16748L9.83728 4.93954L12.2031 3.16748L14.5597 4.93954L16.9255 3.16748V19.1067C16.928 19.8113 17.2096 20.4862 17.7087 20.9835C18.2078 21.4809 18.8836 21.7602 19.5882 21.7601Z" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="square"></path>
                        <path d="M18.6976 10.2466H22.2417V19.1069C22.2016 19.7847 21.9041 20.4216 21.41 20.8873C20.9159 21.3531 20.2626 21.6125 19.5836 21.6125C18.9046 21.6125 18.2512 21.3531 17.7572 20.8873C17.2631 20.4216 16.9656 19.7847 16.9255 19.1069V10.2466H18.6976Z" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10"></path>
                    </svg>
                </span>
                <div className="empty-heading">
                    <p className="empty-heading-title">No Outstanding Payments</p>
                    <p className="empty-heading-desk">
                        You don’t have any outstanding payment <br /> from this client.
                    </p>
                </div>
            </div>
        </WidgetCard>
    );

}

function LastNotes() {

    return (
        <WidgetCard title="Last Notes" showMore showAdd>
            <div className="table-custom table-custom-2">
                <div className="table-custom-body">
                    <table className="table table-2" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <div className="table-user-info-texts">
                                        <a href="#" className="btn btn-link color-secondary tdu">
                                            <span className="text--md font-400">Note Title 1</span>
                                        </a>
                                        <p className="color-control-2 text--sm font-400">01.02.2024</p>
                                    </div>
                                </td>
                                <td className="--dop-class-last-sticky">
                                    <div className="dropdown" data-position="bottom-end" data-dropdown="dropdown">
                                        <button type="button" className="btn btn--square btn--xs
                                                                                btn--custom-1-trsp rounded-full max-w-max dropdown-btn
                                                                                dropdown-btn--burger" data-role="button">
                                            <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path d="M9 9.84375C9.46599 9.84375 9.84375 9.46599 9.84375 9C9.84375 8.53401 9.46599 8.15625 9 8.15625C8.53401 8.15625 8.15625 8.53401 8.15625 9C8.15625 9.46599 8.53401 9.84375 9 9.84375Z" fill="currentColor"></path>
                                                    <path d="M9 5.0625C9.46599 5.0625 9.84375 4.68474 9.84375 4.21875C9.84375 3.75276 9.46599 3.375 9 3.375C8.53401 3.375 8.15625 3.75276 8.15625 4.21875C8.15625 4.68474 8.53401 5.0625 9 5.0625Z" fill="currentColor"></path>
                                                    <path d="M9 14.625C9.46599 14.625 9.84375 14.2472 9.84375 13.7812C9.84375 13.3153 9.46599 12.9375 9 12.9375C8.53401 12.9375 8.15625 13.3153 8.15625 13.7812C8.15625 14.2472 8.53401 14.625 9 14.625Z" fill="currentColor"></path>
                                                </g>
                                                <defs>
                                                    <clipPath>
                                                        <rect width="18" height="18" fill="white"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            </span>
                                        </button>
                                        <div className="dropdown__body dropdown__table" data-role="dropdown">
                                            <div className="dropdown-block">
                                                <div className="dropdown-block--body">
                                                    <ul className="dropdown-list">
                                                        <li className="dropdown-list--item">
                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                </span>
                                                                <span className="info">Edit Info</span>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-list--item">
                                                            <div className="btn btn--xs drp-list-wrapp-separator">
                                                                <hr className="drp-list-separator" />
                                                            </div>
                                                        </li>
                                                        <li className="dropdown-list--item">
                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                </span>
                                                                <span className="info">Edit Info</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <div className="table-user-info-texts">
                                        <a href="#" className="btn btn-link color-secondary tdu">
                                            <span className="text--md font-400">Note Title 1</span>
                                        </a>
                                        <p className="color-control-2 text--sm font-400">01.02.2024</p>
                                    </div>
                                </td>
                                <td className="--dop-class-last-sticky">
                                    <div className="dropdown" data-position="bottom-end" data-dropdown="dropdown">
                                        <button type="button" className="btn btn--square btn--xs
                                                                                btn--custom-1-trsp rounded-full max-w-max dropdown-btn
                                                                                dropdown-btn--burger" data-role="button">
                                            <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path d="M9 9.84375C9.46599 9.84375 9.84375 9.46599 9.84375 9C9.84375 8.53401 9.46599 8.15625 9 8.15625C8.53401 8.15625 8.15625 8.53401 8.15625 9C8.15625 9.46599 8.53401 9.84375 9 9.84375Z" fill="currentColor"></path>
                                                    <path d="M9 5.0625C9.46599 5.0625 9.84375 4.68474 9.84375 4.21875C9.84375 3.75276 9.46599 3.375 9 3.375C8.53401 3.375 8.15625 3.75276 8.15625 4.21875C8.15625 4.68474 8.53401 5.0625 9 5.0625Z" fill="currentColor"></path>
                                                    <path d="M9 14.625C9.46599 14.625 9.84375 14.2472 9.84375 13.7812C9.84375 13.3153 9.46599 12.9375 9 12.9375C8.53401 12.9375 8.15625 13.3153 8.15625 13.7812C8.15625 14.2472 8.53401 14.625 9 14.625Z" fill="currentColor"></path>
                                                </g>
                                                <defs>
                                                    <clipPath>
                                                        <rect width="18" height="18" fill="white"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            </span>
                                        </button>
                                        <div className="dropdown__body dropdown__table" data-role="dropdown">
                                            <div className="dropdown-block">
                                                <div className="dropdown-block--body">
                                                    <ul className="dropdown-list">
                                                        <li className="dropdown-list--item">
                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                </span>
                                                                <span className="info">Edit Info</span>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-list--item">
                                                            <div className="btn btn--xs drp-list-wrapp-separator">
                                                                <hr className="drp-list-separator" />
                                                            </div>
                                                        </li>
                                                        <li className="dropdown-list--item">
                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                </span>
                                                                <span className="info">Edit Info</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <div className="table-user-info-texts">
                                        <a href="#" className="btn btn-link color-secondary tdu">
                                            <span className="text--md font-400">Note Title 1</span>
                                        </a>
                                        <p className="color-control-2 text--sm font-400">01.02.2024</p>
                                    </div>
                                </td>
                                <td className="--dop-class-last-sticky">
                                    <div className="dropdown" data-position="bottom-end" data-dropdown="dropdown">
                                        <button type="button" className="btn btn--square btn--xs
                                                                                btn--custom-1-trsp rounded-full max-w-max dropdown-btn
                                                                                dropdown-btn--burger" data-role="button">
                                            <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path d="M9 9.84375C9.46599 9.84375 9.84375 9.46599 9.84375 9C9.84375 8.53401 9.46599 8.15625 9 8.15625C8.53401 8.15625 8.15625 8.53401 8.15625 9C8.15625 9.46599 8.53401 9.84375 9 9.84375Z" fill="currentColor"></path>
                                                    <path d="M9 5.0625C9.46599 5.0625 9.84375 4.68474 9.84375 4.21875C9.84375 3.75276 9.46599 3.375 9 3.375C8.53401 3.375 8.15625 3.75276 8.15625 4.21875C8.15625 4.68474 8.53401 5.0625 9 5.0625Z" fill="currentColor"></path>
                                                    <path d="M9 14.625C9.46599 14.625 9.84375 14.2472 9.84375 13.7812C9.84375 13.3153 9.46599 12.9375 9 12.9375C8.53401 12.9375 8.15625 13.3153 8.15625 13.7812C8.15625 14.2472 8.53401 14.625 9 14.625Z" fill="currentColor"></path>
                                                </g>
                                                <defs>
                                                    <clipPath>
                                                        <rect width="18" height="18" fill="white"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            </span>
                                        </button>
                                        <div className="dropdown__body dropdown__table" data-role="dropdown">
                                            <div className="dropdown-block">
                                                <div className="dropdown-block--body">
                                                    <ul className="dropdown-list">
                                                        <li className="dropdown-list--item">
                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                </span>
                                                                <span className="info">Edit Info</span>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-list--item">
                                                            <div className="btn btn--xs drp-list-wrapp-separator">
                                                                <hr className="drp-list-separator" />
                                                            </div>
                                                        </li>
                                                        <li className="dropdown-list--item">
                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                </span>
                                                                <span className="info">Edit Info</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <div className="table-user-info-texts">
                                        <a href="#" className="btn btn-link color-secondary tdu">
                                            <span className="text--md font-400">Note Title 1</span>
                                        </a>
                                        <p className="color-control-2 text--sm font-400">01.02.2024</p>
                                    </div>
                                </td>
                                <td className="--dop-class-last-sticky">
                                    <div className="dropdown" data-position="bottom-end" data-dropdown="dropdown">
                                        <button type="button" className="btn btn--square btn--xs
                                                                                btn--custom-1-trsp rounded-full max-w-max dropdown-btn
                                                                                dropdown-btn--burger" data-role="button">
                                            <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path d="M9 9.84375C9.46599 9.84375 9.84375 9.46599 9.84375 9C9.84375 8.53401 9.46599 8.15625 9 8.15625C8.53401 8.15625 8.15625 8.53401 8.15625 9C8.15625 9.46599 8.53401 9.84375 9 9.84375Z" fill="currentColor"></path>
                                                    <path d="M9 5.0625C9.46599 5.0625 9.84375 4.68474 9.84375 4.21875C9.84375 3.75276 9.46599 3.375 9 3.375C8.53401 3.375 8.15625 3.75276 8.15625 4.21875C8.15625 4.68474 8.53401 5.0625 9 5.0625Z" fill="currentColor"></path>
                                                    <path d="M9 14.625C9.46599 14.625 9.84375 14.2472 9.84375 13.7812C9.84375 13.3153 9.46599 12.9375 9 12.9375C8.53401 12.9375 8.15625 13.3153 8.15625 13.7812C8.15625 14.2472 8.53401 14.625 9 14.625Z" fill="currentColor"></path>
                                                </g>
                                                <defs>
                                                    <clipPath>
                                                        <rect width="18" height="18" fill="white"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            </span>
                                        </button>
                                        <div className="dropdown__body dropdown__table" data-role="dropdown">
                                            <div className="dropdown-block">
                                                <div className="dropdown-block--body">
                                                    <ul className="dropdown-list">
                                                        <li className="dropdown-list--item">
                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                </span>
                                                                <span className="info">Edit Info</span>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-list--item">
                                                            <div className="btn btn--xs drp-list-wrapp-separator">
                                                                <hr className="drp-list-separator" />
                                                            </div>
                                                        </li>
                                                        <li className="dropdown-list--item">
                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                                </span>
                                                                <span className="info">Edit Info</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </WidgetCard>
    );

}

function CurrentProject() {

    return (
        <WidgetCard title="Current Project ">
            <div className="card-details-wrpr btn btn--xs">
                <div className="card-details--item">
                    <div className="card-details--head items-center">
                        <div className="user-option-block user-option-block--sm">
                            <div className="user-block-relatives">
                                <div className="ico ico--lg user-block-img-wrpr">
                                    <img src={userImage} />
                                </div>
                                <div className="ico user-ico-hot-wrpr">
                                    <span className="ico user-ico-hot --active">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 18C12.7283 18 15.75 15.75 15.75 11.8125C15.75 10.125 15.1875 7.3125 12.9375 5.0625C13.2188 6.75 11.5312 7.3125 11.5312 7.3125C12.375 4.5 10.125 0.5625 6.75 0C7.15163 2.25 7.3125 4.5 4.5 6.75C3.09375 7.875 2.25 9.82012 2.25 11.8125C2.25 15.75 5.27175 18 9 18ZM9 16.875C7.13587 16.875 5.625 15.75 5.625 13.7812C5.625 12.9375 5.90625 11.5312 7.03125 10.4062C6.89062 11.25 7.875 11.8125 7.875 11.8125C7.45312 10.4062 8.4375 8.15625 10.125 7.875C9.92363 9 9.84375 10.125 11.25 11.25C11.9531 11.8125 12.375 12.7845 12.375 13.7812C12.375 15.75 10.8641 16.875 9 16.875Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="user-block-content-info">
                                <div className="user-block-titled-info">
                                    <p className="heading user-block-title text--lg">Project Name 1</p>
                                </div>
                                <div className="user-block-desk-info">
                                    <p className="user-project--text">Client Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-details--body">
                        <div className="presence-sections-wrpr">
                            <div className="presence-titled-row">
                                <p className="precence-title">Customer Agreement</p>
                                <p className="precence-dates">due to <span>01.02.2024</span></p>
                            </div>
                            <ProgressSteps activeIndex={3} totalSections={8} />
                        </div>
                        <ul className="project-list">
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.91761 4.83496H11.3296C11.3296 4.83496 12.4121 4.83496 12.4121 5.91737V11.3294C12.4121 11.3294 12.4121 12.4118 11.3296 12.4118H5.91761C5.91761 12.4118 4.83521 12.4118 4.83521 11.3294V5.91737C4.83521 5.91737 4.83521 4.83496 5.91761 4.83496Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M2.67036 9.16474C2.07503 9.16474 1.58795 8.67766 1.58795 8.08234V2.6703C1.58795 2.07497 2.07503 1.58789 2.67036 1.58789H8.0824C8.67772 1.58789 9.1648 2.07497 9.1648 2.6703" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <p className="project-variable--text">Tasks:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text">12 / 32</p>
                                </div>
                            </li>
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.62359 1.58789H3.75276C3.46568 1.58789 3.19037 1.70193 2.98738 1.90492C2.78439 2.10791 2.67035 2.38323 2.67035 2.6703V11.3296C2.67035 11.6166 2.78439 11.8919 2.98738 12.0949C3.19037 12.2979 3.46568 12.412 3.75276 12.412H10.2472C10.5343 12.412 10.8096 12.2979 11.0126 12.0949C11.2156 11.8919 11.3296 11.6166 11.3296 11.3296V4.29391L8.62359 1.58789Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M8.0824 1.58789V3.75271C8.0824 4.03978 8.19644 4.31509 8.39943 4.51808C8.60242 4.72107 8.87773 4.83511 9.1648 4.83511H11.3296" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M5.91761 5.37646H4.83521" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M9.16483 7.54102H4.83521" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M9.16483 9.70605H4.83521" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <p className="project-variable--text">Documents:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text">3</p>
                                </div>
                            </li>
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.5647 2.81653L9.43553 5.60373" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M11.8708 4.83522C11.8706 4.64541 11.8205 4.45898 11.7255 4.29465C11.6305 4.13031 11.494 3.99385 11.3296 3.89894L7.54119 1.73413C7.37664 1.63913 7.18999 1.58911 6.99998 1.58911C6.80998 1.58911 6.62333 1.63913 6.45878 1.73413L2.67035 3.89894C2.50597 3.99385 2.36943 4.13031 2.27444 4.29465C2.17945 4.45898 2.12935 4.64541 2.12915 4.83522V9.16485C2.12935 9.35467 2.17945 9.54109 2.27444 9.70543C2.36943 9.86976 2.50597 10.0062 2.67035 10.1011L6.45878 12.2659C6.62333 12.361 6.80998 12.411 6.99998 12.411C7.18999 12.411 7.37664 12.361 7.54119 12.2659L11.3296 10.1011C11.494 10.0062 11.6305 9.86976 11.7255 9.70543C11.8205 9.54109 11.8706 9.35467 11.8708 9.16485V4.83522Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M2.2915 4.29395L6.99998 6.99996L11.7084 4.29395" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M7 12.412V7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <p className="project-variable--text">Materials:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text">123</p>
                                </div>
                            </li>
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.7884 11.8708V10.7884C10.7884 10.2142 10.5603 9.66358 10.1543 9.2576C9.74836 8.85161 9.19773 8.62354 8.62358 8.62354H5.37636C4.80222 8.62354 4.25159 8.85161 3.84561 9.2576C3.43963 9.66358 3.21155 10.2142 3.21155 10.7884V11.8708" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M4.83521 4.29421C4.83521 4.86835 5.06328 5.41898 5.46926 5.82496C5.87525 6.23095 6.42588 6.45902 7.00002 6.45902C7.57416 6.45902 8.12479 6.23095 8.53078 5.82496C8.93676 5.41898 9.16483 4.86835 9.16483 4.29421C9.16483 3.72006 8.93676 3.16944 8.53078 2.76345C8.12479 2.35747 7.57416 2.12939 7.00002 2.12939C6.42588 2.12939 5.87525 2.35747 5.46926 2.76345C5.06328 3.16944 4.83521 3.72006 4.83521 4.29421Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <p className="project-variable--text">Users:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text">24 users</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </WidgetCard>
    );

}


function AboutClient({ data }: { data: Client }) {

    return (
        <WidgetCard title="About Client" showEdit>
            <div className="table-custom table-custom-2">
                <div className="table-custom-body">
                    <table className="table table-2" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Status</p></td>
                                <td style={{ width: "60%" }}>
                                    <div className={clsx("pill-custom pill--sm", statusMap[data.status].className)}>
                                        <span>{statusMap[data.status].label}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Company</p></td>
                                <td style={{ width: "60%" }}>{data.companyName}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Email</p></td>
                                <td style={{ width: "60%" }}>
                                    <a href="#" className="btn btn-link color-secondary">
                                        <span className="font-400">{data.email}</span>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Phone Number</p></td>
                                <td style={{ width: "60%" }}>
                                    <a href="#" className="btn btn-link color-secondary">
                                        <span className="font-400">{data.phone}</span>
                                    </a>
                                </td>
                            </tr>
                            <tr style={{ verticalAlign: "top" }}>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Address</p></td>
                                <td style={{ width: "60%" }}>{formatAddress(data.address, ['street', 'building', 'unit'])}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Insurance</p></td>
                                <td style={{ width: "60%" }}>{data.insurance ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Client Since</p></td>
                                <td style={{ width: "60%" }}>{format(data.createdAt, DATE_FORMAT)}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Account Manager</p></td>
                                <td style={{ width: "60%" }}>
                                    <Link to={getFullUrl([routerConfig.users.path, data.userId])} className="btn btn-link color-secondary">
                                        <span className="font-400">{'TODO'}</span>
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Client Type</p></td>
                                <td style={{ width: "60%" }}>
                                    <div className="pill-custom pill--custom-3 pill--sm">
                                        <span>{capitalize(data.clientType)}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Contact Method</p></td>
                                <td style={{ width: "60%" }}>{contactMethodMap[data.contactMethod]}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Risk Level</p></td>
                                <td style={{ width: "60%" }}>
                                    <div className={clsx("pill-custom pill--sm", riskLevelMap[data.riskLevel].className)}>
                                        <span>{riskLevelMap[data.riskLevel].label}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </WidgetCard>
    );

}

function Documents() {

    return (
        <WidgetCard title="Documents" showAdd showMore>
            <div className="table-custom table-custom-2">
                <div className="table-custom-body">
                    <table className="table table-2" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <div className="table-user-info-texts">
                                        <a href="#" className="btn btn-link color-secondary tdu">
                                            <span className="text--md font-400">Modern Kitchen Remodel Concept</span>
                                        </a>
                                        <p className="color-control-2 text--sm font-400">01.02.2024</p>
                                    </div>
                                </td>
                                <td className="--dop-class-last-sticky">
                                    <div className="actions-table">
                                        <a href="#" download className="btn btn btn--square btn--xs btn-download">
                                            <span className="ico"><DownloadIcon /></span>
                                        </a>
                                        <DropdownActions
                                            actions={[{
                                                icon: <EditIcon />,
                                                label: "Edit",
                                                fn: () => console.log("Edit")
                                            }]}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </WidgetCard>
    );

}


export default Overview;
