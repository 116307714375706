import PageLoader from "@/components/layout/PageLoader";
import { useSelectMe } from "@/hooks/redux/selectHooks/selectMe";
import { routerConfig } from "@/routes";
import { useGetMeQuery } from "@/api/usersApi";
import { Navigate, Outlet, useLocation } from "react-router";

const ProtectedLayout = () => {

    const { pathname } = useLocation();
    const me = useSelectMe();
    const token = localStorage.getItem("authToken");

    const { data, error, isLoading, isUninitialized, isFetching } = useGetMeQuery(undefined, {
        skip: !token || !!me,
        refetchOnMountOrArgChange: true
    });

    if (!me && (isUninitialized || isLoading || isFetching) && token) {

        return (
            <div className="wrapper">
                <PageLoader />
            </div>
        );

    }

    if (!me && pathname !== routerConfig.login.path) {

        return <Navigate to={routerConfig.login.path} />;

    }

    return <Outlet />;

};

export default ProtectedLayout;
