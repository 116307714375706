import { Breadcrumb, clearBreadcrumbs, ModalState, setBreadcrumbs, setMe, setModal } from "@/redux/appSlice";
import { useAppDispatch } from "@/redux/store";
import { User } from "@/types";

function useDispatchAction () {

    const dispatch = useAppDispatch();

    const setBreadcrumbsData = (breadcrumbs: Breadcrumb[]) => dispatch(setBreadcrumbs(breadcrumbs));
    const clearBreadcrumbsData = () => dispatch(clearBreadcrumbs());
    const setModalData = (modal: ModalState) => dispatch(setModal(modal));
    const setUser = (user: User | null) => dispatch(setMe(user));

    return { setBreadcrumbsData,
        clearBreadcrumbsData,
        setModalData,
        setUser };

}

export default useDispatchAction;
