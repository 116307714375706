import NumericInput from "@/components/common/NumericInput";
import React from "react";
import {Controller, Control, FieldErrors} from "react-hook-form";
import Select from "@/components/common/Select";
import InfoIcon from "@/assets/img/ico-inf.svg?react";

interface RepeatFieldsProps {
    control: Control<any>;
    errors: any;
}

const RepeatFields: React.FC<RepeatFieldsProps> = ({control, errors}) => {
    return (
        <div className="flex flex-col gap-16 mt-16">
            <div className="row">
                <div className="col-4 flex items-center justify-end">
                    <span className="tab-custom-btn-2 text--md">Repeats every</span>
                </div>
                <div className="col-4 flex items-center">
                    <Controller
                        name="repeatInterval"
                        control={control}
                        render={({field}) => (
                            <NumericInput
                                value={field.value || 0}
                                onChange={field.onChange}
                                error={errors.repeatInterval?.message}
                                min={0}
                                max={999}
                            />
                        )}
                    />
                </div>
                <div className="col-4">
                    <Controller
                        name="repeatEvery"
                        control={control}
                        render={({field}) => (
                            <Select
                                options={[
                                    {label: "Days", value: "days"},
                                    {label: "Weeks", value: "weeks"},
                                    {label: "Months", value: "months"},
                                ]}
                                value={field.value}
                                onChange={(option) => field.onChange(option.value)}
                                error={errors.repeatEvery?.message}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-4 flex items-center justify-end">
                    <span className="tab-custom-btn-2 text--md">Ends after</span>
                </div>
                <div className="col-4 flex items-center">
                    <Controller
                        name="repeatEndsAfter"
                        control={control}
                        render={({field}) => (
                            <NumericInput
                                value={field.value || 0}
                                onChange={field.onChange}
                                error={errors.repeatEndsAfter?.message}
                                min={0}
                                max={999}
                            />
                        )}
                    />
                </div>
                <div className="col-4 flex items-center">events</div>
            </div>
            <div className="card card-secondary card-team-grid ">
                <div className="card-body flex flex-col gap-12">
                    <div className="flex items-center gap--md color-control-3 text--md">
                        <InfoIcon/>
                        <div>
                            <span className="font-600">10 events</span> <span className="font-400"> will be created at the following dates:</span>
                        </div>
                    </div>
                    <div className="flex gap-8">
                        <div className="btn btn--custom-1 btn--sm rounded-full">Friday, 06 Jan 2025</div>
                        <div className="btn btn--custom-1 btn--sm rounded-full">Wednesday, 12 Jan 2025</div>
                        <div className="btn btn--secondary btn--sm rounded-full">+8</div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default RepeatFields;
