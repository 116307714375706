import { modalComponents, ModalID, ModalProps } from "@/providers/ModalProvider";
import { useEffect } from "react";
import { useSearchParams } from "react-router";
import { toast } from "react-toastify";
import useDispatchAction from "./redux/dispatchHooks/useDispatchAction";


const useModal = () => {

    const { setModalData } = useDispatchAction();

    const isValidModal = (modal: string | null): modal is ModalID => {

        return modal
            ? Object.hasOwn(modalComponents, modal)
            : false;
    };

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const modalId = searchParams.get("modal");
        if (!modalId) return;
        if (isValidModal(modalId)) {

            openModal(modalId);

        } else {
            toast.error("Modal is not found");
        }
    }, []);
    
    const openModal = <T extends ModalID>(id: T, props?: ModalProps[T]) => {

        if (!isValidModal(id)) {

            toast.error(`Modal with id "${id}" is not valid`);
            return;

        }

        setModalData({ id,
            props });

        setSearchParams((prev) => {
            const copy = new URLSearchParams(prev);
            copy.set("modal", id);
            return copy;

        });

    };

    const closeModal = () => {
        setModalData(null);
       
        setSearchParams((prev) => {
            const copy = new URLSearchParams(prev);
            copy.delete("modal");
            return copy;

        }); 

    };

    return {
        openModal,
        closeModal
    };

};

export default useModal;
