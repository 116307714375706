import { Document, Material } from "@/types";
import { ProjectFormRequest } from "@/validation/projectSchema";
import { Controller, UseFormReturn } from "react-hook-form";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import ProjIcon from "@/assets/img/img-project-name-3.jpg";
import DragFileInput from "@/components/common/DragFileInput";
import DocumentsTable from "@/components/common/DocumentsTable";
import { SupplierSchemaRequest } from "@/validation/supplierSchema";
import Button from "@/components/common/Button";
import MaterialsTable from "@/components/common/MaterialsTable";


interface OrdersProps {
    formState: UseFormReturn<SupplierSchemaRequest>
}

const Orders = ({ formState }: OrdersProps) => {

    const { register, watch, setValue, control, formState: { errors, defaultValues } } = formState;

    const actions = (arg: Material) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => console.log(arg)
    }];

    const materials: Array<File> | string[] = watch('orders');


        const ordersList: Material[] = [
            {
            name: 'Oak Hardwood Flooring',
            fileName: ProjIcon,
            quantity: 10,
            status: 'In Stock',
            },
            {
            name: 'Ceramic Tiles - White Gloss',
            fileName: ProjIcon,
            quantity: 5,
            status: 'In Stock',
            },
            {
            name: 'Glass Windows - Double Glazed',
            fileName: ProjIcon,
            quantity: 20,
            status: 'In Stock',
            },
        ];
    // const contractsList = contracts ? Array.from(contracts)
    //     .map((contractFile, i) => ({id: i, name: typeof contractFile === 'string' 
    //             ? contractFile 
    //             : contractFile.name
    //         })) : [];

    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="flex items-center justify-between flex-wrap mb-20">
                    <h5>Add orders manually or upload CSV file</h5>
                    <Button
                        size='medium'
                        uiType='primary-advance-1'
                    >
                        Add Manually
                    </Button>
                </div>
                <Controller 
                    name='materials'
                    control={control}
                    render={({ field: { value, onChange } }) => 
                        <DragFileInput externalHandler={onChange} />
                    }
                />

                <MaterialsTable
                    data={ordersList} 
                    actions={actions} 
                />
            </div>
        </div>
    );

};
export default Orders;
