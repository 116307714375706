import React, { useId } from "react";
import clsx from "clsx";

interface RadioOption<T> {
    label: string;
    value: T;
}

interface RadioGroupProps<T> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> {
    options: RadioOption<T>[];
    value: T;
    onChange: (value: T) => void;
}

const RadioGroup = <T, >({
    options,
    value,
    onChange,
    ...props
}: RadioGroupProps<T>) => {

    const id = useId();

    return (
        <div className="tabs-navigation">
            <div className="tabs-navigation-list --list-2">
                {options.map((option) => <label
                    key={option.value as string}
                    className={clsx("btn btn--md tab-custom-btn-2", {
                        "active-tab": value === option.value
                    })}
                    style={{ cursor: "pointer" }}
                >
                    <input
                        type="radio"
                        value={option.value as string}
                        name={`name-${id}`}
                        checked={value === option.value}
                        onChange={() => onChange(option.value)}
                        className="hidden"
                        {...props}
                    />
                    <span>{option.label}</span>
                </label>)}
            </div>
        </div>
    );

};

export default RadioGroup;
