import { Status, UserRole } from "@/types/enums";
import * as yup from "yup";

export const userSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    photo: yup.mixed().nullable(),
    phone: yup.
        string().
        matches(/^[+]*[0-9]+$/, "Phone number must be only digits and optionally start with a '+'").
        required("Phone number is required"),
    phoneCountry: yup.string().
        // .oneOf(['UA', 'US', 'GB', 'Other'], "Invalid country code")
        required("Country code is required"),
    address: yup.string().required("Address is required"),
    email: yup.
        string().
        email("Invalid email format").
        required("Email is required"),
    departmentId: yup.number().required("Department is required"),
    status: yup.string().oneOf(Object.values(Status), "Invalid status"),
    profession: yup.string().required("Role is required"),
    skills: yup.array(yup.string())
});

export type UserFormRequest = yup.InferType<typeof userSchema>;
