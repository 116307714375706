import clsx from "clsx";
import { forwardRef } from "react";
import type {
    DropdownIndicatorProps,
    GroupBase,
    OptionProps,
    OptionsOrGroups,
    PlaceholderProps,
    Props,
    PropsValue,
    SingleValueProps,
    StylesConfig,
    ValueContainerProps
} from "react-select";
import ReactSelect, { components } from "react-select";

import ArrowIcon from "@/assets/img/ico-caretdown.svg?react";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";

const DropdownIndicator = (props: DropdownIndicatorProps<any, false, GroupBase<any>>) => components.DropdownIndicator &&
    <components.DropdownIndicator {...props}>
        <div className="ico">
            <ArrowIcon />
        </div>
    </components.DropdownIndicator>;


const Placeholder = (props: PlaceholderProps<any>) => <components.Placeholder {...props} />;


const SingleValue = (props: SingleValueProps<any>) => <components.SingleValue {...props} />;

const Option = (props: OptionProps<any>) => <components.Option {...props} />;


const ValueContainer = (props: ValueContainerProps<any>) => <components.ValueContainer {...props} />;


export interface OptionCustomProps<T> {
    label: string;
    value: T;
}

interface SelectProps<T, > extends Omit<Props<OptionCustomProps<T>, false>, "value"> {
    value?: PropsValue<OptionCustomProps<any>> | undefined
    error?: string;
    options?: OptionCustomProps<T>[];
    onChange?: (option: OptionCustomProps<T> | null) => void;
    wrapperClassName?: string;
    label?: string;
    disabled?: boolean;
    variant?: "primary";
    fetchFunk: LoadOptions<OptionCustomProps<any>, GroupBase<OptionCustomProps<any>>, any>
}


const SelectAsynkPagination = <T, >(
    {
        value,
        onChange,
        fetchFunk,
        options,
        wrapperClassName,
        label,
        error,
        disabled,
        variant = "primary",
        ...rest
    }: SelectProps<T>
) => {

    const customStyles: StylesConfig<OptionCustomProps<any>, false> = {
        control: (base, { menuIsOpen }) => ({
            ...base,
            background: disabled
                ? "var(--color-control-9)!important"
                : "var(--select-bg)",
            borderWidth: "1px ",
            borderStyle: "solid",
            borderRadius: "10px",
            paddingLeft: "var(--select-padding-left)",
            paddingRight: 8,
            fontSize: "var(--select-font-size)",
            lineHeight: "var(--select-line-height)",
            color: menuIsOpen
                ? "var(--primary)"
                : "var(--select-color)",
            height: "calc(var(--input-height) - 2)",
            boxShadow: "none",
            borderColor: error
                ? "var(--danger, #ff4d4f)!important"
                : "var(--select-border-color, transparent)"
        }),
        singleValue: (base) => ({
            ...base,
            color: "var(--select-color)",
            height: "calc(var(--input-height) - 2)"
        }),
        valueContainer: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
            height: "calc(var(--input-height) - 2)"
        }),
        input: (base) => ({
            ...base,
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0,
            height: "calc(var(--input-height) - 2)"
        }),
        // indicatorsContainer: () => ({ minWidth: 32 }),
        indicatorSeparator: () => ({ display: "none" }),

        /*
         * dropdownIndicator: (base) => ({
         *     ...base,
         *     width: 'var(--select-arrow-size)',
         *     height: 'calc(var(--input-height) - 2)',
         * }),
         */
        menu: (base) => ({
            ...base,
            borderRadius: 10,
            minWidth: 150,
            // right: 0,
            overflow: "hidden"
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999
        }),
        option: (base, props) => ({
            ...base,
            background: props.isFocused
                ? "#F4F5FF"
                : "transparent",
            color: props.isSelected
                ? "var(--primary)"
                : "var(--select-color)",
            // paddingRight: 40,
            cursor: "pointer"
        })
    };

    const loadOptionsFeth = async (
        search: string,
        loadedOptions: OptionsOrGroups<
            OptionCustomProps<T>,
            GroupBase<OptionCustomProps<T>>
        >,
        additional: any,
    ) => {
        const page = additional?.page || 1
        return fetchFunk(search, loadedOptions, { page })
    }

    // const findValue = (
    //     options: OptionCustomProps<T>[],
    //     value: T
    // ): OptionCustomProps<T> | undefined => {

    //     return options.find((option) => option.value === value);

    // };
    const variants = {
        primary: "select--outline input--md"
    };
    return (
        <div className={clsx("form-group", variants[variant], wrapperClassName)}>
            {label && <label className="label">{label}</label>}
            <AsyncPaginate
                classNamePrefix={"react-select"}
                value={value || undefined}
                onChange={onChange}
                options={options}
                styles={customStyles}
                menuPortalTarget={document.body}
                additional={{ page: 1 }}
                debounceTimeout={300}
                loadOptions={loadOptionsFeth}
                className="w-full"
                isDisabled={disabled}
                components={{
                    DropdownIndicator,
                    Placeholder,
                    SingleValue,
                    ValueContainer,
                    Option
                }}
                {...rest}
            />
            {error &&
                <label className="form-label">
                    <div
                        className="label--text"
                        style={{ color: "var(--danger)" }}
                    >
                        <span>{error}</span>
                    </div>
                </label>
            }
        </div>
    );

};

export default SelectAsynkPagination;
