

import AddSecondaryIcon from "@/assets/img/ico-add-2.svg?react";
import DownloadIcon from "@/assets/img/ico-download.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import WidgetCard from "@/components/cards/WidgetCard";
import DropdownActions from "@/components/common/DropdownActions";
import Table from "@/components/common/Table";
import { Tab, useTabs } from "@/hooks/useTabs";
import { ColumnDef } from "@tanstack/react-table";

const Documents = () => {

    return (
        <div className="block-container tab-body-fullpage">
            <div className="row g-20">
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 flex-col ">
                    <div className="row g-20">
                        <div className="col-12">
                            <WidgetCard title="Recent Documents">
                                <div className="table-custom table-custom-2">
                                    <div className="table-custom-body">
                                        <table className="table table-2" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "100%" }}>
                                                        <div className="table-user-info-texts">
                                                            <a href="#" className="btn btn-link color-secondary tdu">
                                                                <span className="text--md font-400">Modern Kitchen Remodel Concept</span>
                                                            </a>
                                                            <p className="color-control-2 text--sm font-400">01.02.2024</p>
                                                        </div>
                                                    </td>
                                                    <td className="--dop-class-last-sticky">
                                                        <div className="actions-table">
                                                            <a href="#" download className="btn btn btn--square btn--xs btn-download">
                                                                <span className="ico"><DownloadIcon /></span>
                                                            </a>
                                                            <DropdownActions actions={[]} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </WidgetCard>
                        </div>
                        <div className="col-12">
                            <WidgetCard title="Featured">
                                <div className="table-custom table-custom-2">
                                    <div className="table-custom-body">
                                        <table className="table table-2" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "100%" }}>
                                                        <div className="table-user-info-texts">
                                                            <a href="#" className="btn btn-link color-secondary tdu">
                                                                <span className="text--md font-400">Modern Kitchen Remodel Concept</span>
                                                            </a>
                                                            <p className="color-control-2 text--sm font-400">01.02.2024</p>
                                                        </div>
                                                    </td>
                                                    <td className="--dop-class-last-sticky">
                                                        <div className="actions-table">
                                                            <a href="#" download className="btn btn btn--square btn--xs btn-download">
                                                                <span className="ico"><DownloadIcon /></span>
                                                            </a>
                                                            <DropdownActions actions={[]} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </WidgetCard>
                        </div>
                    </div>

                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 col-12 flex-col ">
                    <DocumentsWidget />
                </div>
            </div>
        </div>
    );

};


interface Document {
    fileName: string;
    category: string;
    uploadDate: string;
}

function DocumentsWidget () {

    const tabs = [{
        label: "All Documents",
        id: "all-Documents"
    }, {
        label: "Categories",
        id: "categories"
    }] as const;
    const { activeTab, setActiveTab } = useTabs(tabs, "fdt");
    const data: Document[] = [{
        fileName: "Document Name 1",
        category: "Contracts & Agreements",
        uploadDate: "01.02.2024, 12:00"
    }];

    const actions = (arg: Document) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => console.log(arg)
    }];

    const columns: ColumnDef<Document>[] = [{
        header: "File Name",
        accessorKey: "fileName",
        minSize: 228,

        cell: ({ row: { original } }) => <a href="#" className="btn btn-link color-secondary tdu"><span className="text--md font-400">{original.fileName}</span></a>

    }, {
        header: "Category",
        accessorKey: "category",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.category}</p>

    }, {
        header: "Upload Date",
        accessorKey: "uploadDate",
        minSize: 228,
        cell: ({ row: { original } }) => <p className="font-500 nowrap">{original.uploadDate}</p>

    }, {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            variant="secondary"
            actions={actions(original)}
        />

    }];

    return (
        <div className="card-widget card-widget--md w-full h-full card-widget-scroller card-widget-sticky">
            <div className="card-widget-header">
                <div className="card-widget-header-item">
                    <h5 className="heading card-widget-title btn btn--xs">Documents</h5>
                    <div className="tabs-nav-wrapper">
                        {tabs.map(({ id, label }) => <label key={id} className="fake-btn">
                            <input
                                type="radio"
                                name="tab-table-3"
                                onChange={() => setActiveTab(id)} checked={activeTab === id}
                                hidden
                            />
                            <div className="tab-link-2 btn btn--xs">
                                <span className="text--sm">{label}</span>
                            </div>
                        </label>)}
                    </div>
                </div>
                <div className="card-widget-header-item">
                    <div className="card-widget-actions">
                        <button type="button" className="btn btn--secondary-advance-1 btn--xs rounded-full">
                            <span className="ico"><StarsIcon /></span>
                            <span>Ask Your COO</span>
                        </button>
                        <button type="button" className="btn btn--primary-advance-1 btn--square btn--xs rounded-full">
                            <span className="ico"><AddSecondaryIcon /></span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-widget-body">
                <Table columns={columns} data={data} />
            </div>
        </div>
    );

}

export default Documents;
