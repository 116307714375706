import DatePickerInline from "@/components/common/DatePickerInline";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { routerConfig } from "@/routes";
import React from "react";
import HomeFilter from "@/pages/HomePage/components/HomeFilter";
import HomeProjects from "@/pages/HomePage/components/HomeProjects";
import HomeTeams from "@/pages/HomePage/components/HomeTeams";

const HomePage = () => {

    useBreadcrumbs([routerConfig.dashboard]);

    return (
        <main className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="block-container-fullpage tabs">
                    <HomeFilter/>
                    <div className="block-container tabs-fullpage container-type">
                        <div className="row row-statistic">
                            <HomeProjects/>
                            <HomeTeams/>
                            <div className="col-xl-4 col-lg-4 col-md-12 col-12 flex flex-col lg-h-full">
                                <div className="card-widget w-full h-full card-widget-scroller">
                                    <div className="card-widget-body gap-18">
                                        <div className="calendar-inline-wrap">
                                            <DatePickerInline />
                                        </div>
                                        <a href="#" className="btn btn--primary-advance-1  btn--md rounded-full">
                                            <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.12915 9H13.8708" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M9 4.12915V13.8708" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            </span>
                                            <span>Add to Calendar</span>
                                        </a>

                                        <ul className="card-details--projects-info-list gap-18">
                                            <li className="card-details--project-info--item">
                                                <a href="#" className="btn btn--project-row">
                                                    <div className="event-project-status
                                                                            --info-4"></div>
                                                    <div className="project-titled-wrpr">
                                                        <div className="project-info-wrapper-block">
                                                            <div className="project-info-item-block --only">
                                                                <p className="project-title-2">Project Name 1</p>
                                                                <p className="user-role--date">
                                                                    <span className="date-variable-text">Design Concept</span>

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btn--project-row--ico ico">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.14444 11.7112L9.85555 8.00007L6.14444 4.28896" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="card-details--project-info--item">
                                                <a href="#" className="btn btn--project-row">
                                                    <div className="event-project-status
                                                                            --info-3"></div>
                                                    <div className="project-titled-wrpr">
                                                        <div className="project-info-wrapper-block">
                                                            <div className="project-info-item-block --only">
                                                                <p className="project-title-2">Project Name 1</p>
                                                                <p className="user-role--date">
                                                                    <span className="date-variable-text">Design Concept</span>

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btn--project-row--ico ico">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.14444 11.7112L9.85555 8.00007L6.14444 4.28896" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="card-details--project-info--item">
                                                <a href="#" className="btn btn--project-row">
                                                    <div className="event-project-status
                                                                            --info-2"></div>
                                                    <div className="project-titled-wrpr">
                                                        <div className="project-info-wrapper-block">
                                                            <div className="project-info-item-block --only">
                                                                <p className="project-title-2">Project Name 1</p>
                                                                <p className="user-role--date">
                                                                    <span className="date-variable-text">Design Concept</span>

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btn--project-row--ico ico">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.14444 11.7112L9.85555 8.00007L6.14444 4.28896" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

};

export default HomePage;
