import { ModalID } from "@/providers/ModalProvider";
import { authApi } from "@/api/authApi";
import { usersApi } from "@/api/usersApi";
import { RootState } from "./store";
import { User } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { localStorageService } from "@/services/localStorageService";
import { LocalStorageEntities } from "@/types/enums";

export interface Breadcrumb {
    [path: string]: string | string[];
}

export interface ModalState<T = any> {
    id: ModalID
    props: T | null
}

export interface AppState {
    breadcrumbs: Breadcrumb[],
    modal: ModalState | null
    user: User | null
}

const initialState: AppState = {
    breadcrumbs: [],
    modal: null,
    user: null
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setMe: (state, action: PayloadAction<User | null>) => {

            state.user = action.payload;

        },
        setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {

            state.breadcrumbs = action.payload;

        },
        clearBreadcrumbs: (state) => {

            state.breadcrumbs = [];

        },
        setModal (state, action: PayloadAction<ModalState | null>) {

            state.modal = action.payload;

        }
    },
    extraReducers (builder) {

        builder.
            addMatcher(usersApi.endpoints.getMe.matchFulfilled, (state, action) => {
                state.user = action.payload.data;
            }).
            addMatcher(usersApi.endpoints.getMe.matchRejected, () => {
                localStorageService.remove(LocalStorageEntities.AuthToken);
            }).
            addMatcher(authApi.endpoints.login.matchFulfilled, (_, action) => {
                if (!action.payload?.data?.token) return;
                localStorageService.save(LocalStorageEntities.AuthToken, action.payload.data.token);
            }).
            addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
                state.user = null;
                localStorageService.remove(LocalStorageEntities.AuthToken);
            });

    }
});

export const getMe = (state: RootState) => state.app.user;
export const selectBreadcrumbs = (state: RootState) => state.app.breadcrumbs;
export const selectModal = (state: RootState) => state.app.modal;

export const { setBreadcrumbs, clearBreadcrumbs, setModal, setMe } = appSlice.actions;

export default appSlice.reducer;
