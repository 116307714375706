import { ModalProps } from "@/components/modals/Modal";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";
import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import ProjectData from "./components/ProjectData";
import { useProjectCsvData } from "@/hooks/useProjectCsvData";
import { useState } from "react";
import { Project } from "@/types";
import ModalLoader from "@/components/layout/ModalLoader";

interface NewProjectModal extends ModalProps {
}

const completeActionButtons = [{ text: "Assign Team",
    handler: () => { } }, { text: "Add Task",
    handler: () => { } }];

const NewProjectModal = ({ ...props }: NewProjectModal) => {
    const [data, setData] = useState<Project | undefined>();

    const { currentStage,
            handleCompleteClick,
            handleContinueClick,
            handleCancel,
            handleGoToSelection,
    } = useNewEntityModal();

    const { isProcessing, getProjectDataFromCsv } = useProjectCsvData();

    const handleParseProject = async (file: File) => {
        try {
            const parsedProject = await getProjectDataFromCsv(file);

            setData(parsedProject);
            handleContinueClick();
        } catch(error) {
            console.error(error);
        }
    }

    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New Project Created!"
            completeDescription="Congratulations on successfully creating a new project! You can now start managing task, assign team or return to the home page."
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
            handleBackToSelectionClick={handleGoToSelection}
            handleCompleteClick={handleCompleteClick}
            handleParseClick={handleParseProject}
            exampleFileLink="csvProjectExample.csv"
        >
            {isProcessing ? <ModalLoader /> 
                : 
                <ProjectData 
                    data={data} 
                    handleCancel={handleCancel} 
                    handleCompleteClick={handleCompleteClick}
                />
            }
        </NewEntityModal>
    );

};

export default NewProjectModal;
