import { useLazyGetAllUsersQuery } from "@/api/usersApi";
import { departmentOptions } from "@/constants/options";
import { csvService } from "@/services/csvService";
import { ISelecOption, ITeamFormData, User } from "@/types";
import { Status } from "@/types/enums";
import { checkAreTextsEqual, checkAreNamesEqual, checkIsNameSimilarToUserName, getFullName } from "@/utils";
import { ICONS } from "@/utils/teamsIcons";
import { useState } from "react";

export const useTeamCsvData = () => {
    const [getUsers] = useLazyGetAllUsersQuery();
    const getDepartments = (): Promise<ISelecOption<number>[]> => new Promise(
        (resolve) => resolve(departmentOptions)); //TODO: Implement the api query after implementation on backend.
    
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const getTeamDataFromCsv = async (file: File) => {
        try {
            setIsProcessing(true);

            const usersResponse = await getUsers().unwrap();
            const users = usersResponse.data;

            const teamDataRows = await csvService.teamParser(file);

            if (teamDataRows.length === 0) {
                return null;
            }

            const teamParsedData = teamDataRows[0];
    
            const departments = await getDepartments();
            const departmentId = departments.find(department => checkAreNamesEqual(department.label, 
                teamParsedData.departmentName))?.value;

            const teamLeadId = users.find(user => checkIsNameSimilarToUserName(user.firstName, 
                user.lastName, teamParsedData.teamLead))?.id;

            const members = teamParsedData.members?.split('/');

            const membersIdsSet = new Set<number>();

            const membersList = new Array<User>();

            members.forEach(member => {
                const targetMember = users.find(user => checkIsNameSimilarToUserName(user.firstName, 
                    user.lastName, member) || checkAreTextsEqual(user.email, member));
                
                if (targetMember) {
                    const memberId = targetMember.id;
                    
                    if (!membersIdsSet.has(memberId)) {
                        // membersList.push({
                        //     id: targetMember.id,
                        //     status: targetMember.status,
                        //     profession: targetMember.profession,
                        // });

                        membersList.push(targetMember);

                        membersIdsSet.add(memberId);
                    }
                }
            });
            
            const status = csvService.getStatusValue(teamParsedData.status);

            const iconKey = teamParsedData.icon?.trim().toLowerCase();

            const parsedTeam: ITeamFormData = {
                name: teamParsedData.name || '',
                DepartmentId: departmentId,
                TeamLeadId: teamLeadId,
                icon: ICONS[iconKey] ?  iconKey : Object.keys(ICONS)[0],
                Members: membersList,
                status: status as Status,
            }

            return parsedTeam;
        } catch(error) {
            console.error(error);
        } finally {
            setIsProcessing(false);
        }
    }
        
    return {
        isProcessing,
        getTeamDataFromCsv,
    }
}