import BellIcon from "@/assets/img/Bell--Streamline-Lucide.svg?react";
import CircleHelpIcon from "@/assets/img/Circle-Help--Streamline-Lucide.svg?react";
import AddIcon from "@/assets/img/ico-add.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import InviteIcon from "@/assets/img/icon-invite-user.svg?react";
import Breadcrumbs from "../../common/Breadcrumbs";
import DropdownProfile from "./DropdownProfile";
import Button from "@/components/common/Button";
import {useNavigate} from "react-router";
import {routerConfig} from "@/routes";

const Header = () => {
    const navigate = useNavigate();

    return (
        <header className="header">
            <div className="header__wrap">
                <div className="header__container">
                    <div className="header__body">
                        <Breadcrumbs/>
                        <div className="header__right-tools">
                            <div className="flex items-center ">
                                <Button
                                    className="notification-link ico active"
                                    size="tiny"
                                    uiType="transparent"
                                    isWithoutPaddings
                                    onClick={() => navigate(routerConfig.notifications.path)}
                                    isSquare
                                >
                                    <BellIcon/>
                                </Button>

                                <Button
                                    className="ico"
                                    uiType="transparent"
                                    size="tiny"
                                    isWithoutPaddings
                                    onClick={() => navigate(routerConfig.support.path)}
                                    isSquare
                                >
                                    <CircleHelpIcon/>
                                </Button>
                            </div>
                            <div className="flex items-center gap-8 hidden-420">
                                <Button
                                    size="tiny"
                                    uiType="custom-1"
                                    className="nowrap"
                                >
                                    <span className="ico">
                                        <InviteIcon/>
                                    </span>
                                    <span className="hidden-576">Invite User</span>
                                </Button>

                                <Button
                                    size='medium'
                                    uiType="secondary-advance-1"
                                    isSquare
                                >
                                    <span className="ico"><StarsIcon/></span>
                                </Button>

                                <Button
                                    size='medium'
                                    uiType="primary-advance-1"
                                    isSquare
                                >
                                    <span className="ico"><AddIcon/></span>
                                </Button>

                            </div>
                            <DropdownProfile/>
                        </div>
                        <div className="header__burger">
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );

};


export default Header;
