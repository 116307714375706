function get(key: string): string | null {
    const value = localStorage.getItem(key);

    return value;
}

function save(key: string, value: any): void {
    let valueToSave = value;

    if (value instanceof Object) {
        valueToSave = JSON.stringify(value);
    }

    localStorage.setItem(key, valueToSave);

    return value;
}

function remove(key: string): void {
    localStorage.removeItem(key);
}

export const localStorageService = { get, save, remove };
