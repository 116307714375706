import clsx from "clsx";
import React, { forwardRef } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    type?: string;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    error?: boolean | string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
    label,
    type = "text",
    placeholder = "",
    className = "input--xl",
    inputClassName = "input--outline",
    error,
    ...props
}, ref) => {

    return (
        <div className={clsx("form-group", className)}>
            {label && <label className="label">{label}</label>}
            <input
                className={clsx("input", inputClassName, { "input-error": error,
                    "input-disabled": props.disabled })}
                type={type}
                placeholder={placeholder}
                ref={ref}
                {...props}
            />
            {typeof error === "string" && <p className="error-message">{error}</p>}
        </div>
    );

});

Input.displayName = "Input";

export default Input;
