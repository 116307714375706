import Input from "@/components/common/Input";
import Select, {OptionCustomProps} from "@/components/common/Select";
import {clientTypeOptions} from "@/constants/options";
import {UseFormReturn, Controller} from "react-hook-form";
import {EventFormRequest} from "@/validation/eventSchema";
import {useEffect} from "react";
import {useGetAllProjectsQuery} from "@/api/projectsApi";
import Textarea from "@/components/common/Textarea";

interface EventDetailsProps {
    formState: UseFormReturn<EventFormRequest>;
}

const Details = ({formState}: EventDetailsProps) => {
    const {
        control,
        register,
        formState: {errors},
    } = formState;

    const {data: projectsResponse} = useGetAllProjectsQuery({limit: "noLimit"});

    const projectOptions: OptionCustomProps<any>[] = projectsResponse?.data?.map((project) => ({
        label: project.name,
        value: project.id,
    }));

    const pipelineOptions: OptionCustomProps<any>[] = projectsResponse?.data?.map((_, index) => (
        {label: "pipeline" + index, value: index}
    ));


    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Detail Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("eventName")}
                            placeholder="Enter detail name here..."
                            error={errors.eventName?.message}
                        />
                    </div>
                </div>
            </div>


            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Project</span>
                    </div>
                    <div className="col-8">
                        <Controller
                            name="project"
                            control={control}
                            render={({field}) => (
                                <Select
                                    options={projectOptions || []}
                                    onChange={(option) => field.onChange(option.value)}
                                    value={projectOptions?.find((opt) => +opt.value === +field.value)?.value}
                                    error={errors?.project?.message}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Pipeline Phase</span>
                    </div>
                    <div className="col-8">
                        <Controller
                            name="pipelinePhase"
                            control={control}
                            render={({field}) => (<Select
                                options={pipelineOptions || []}
                                onChange={(option) => field.onChange(option.value)}
                                value={pipelineOptions?.find((opt) => opt.value === field.value)?.value}
                                error={errors?.pipelinePhase?.message}
                            />)
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-start">
                        <span className="fw-500 text--md ">Description</span>
                    </div>
                    <div className="col-8">
                        <Controller
                            name="description"
                            control={control}
                            render={({field}) => (
                                <Textarea
                                    {...field}
                                    className="input--md textarea-custom"
                                    placeholder="Enter description here..."
                                    error={errors?.description?.message}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

export default Details;
