
import Button from "@/components/common/Button";
import Input from "@/components/common/Input";
import RadioGroup from "@/components/common/RadioGroup";
import Select from "@/components/common/Select";
import UploadPhoto from "@/components/common/UploadPhoto";
import { clientTypeOptions, insuranceOptions, riskLevelOptions, statusOptions } from "@/constants/options";
import { ClientFormRequest } from "@/validation/clientSchema";
import { UseFormReturn } from "react-hook-form";


interface ClientDetailsProps {
    formState: UseFormReturn<ClientFormRequest>
}

const ClientDetails = ({ formState }: ClientDetailsProps) => {

    const { register, watch, setValue, formState: { errors, defaultValues } } = formState;

    const handleClientTypeChange = (option: { label: string; value: string }) => {

        setValue("clientType", option.value as ClientFormRequest["clientType"]);

    };
    const handleRiskLevelChange = (value: string) => {

        setValue("riskLevel", value as ClientFormRequest["riskLevel"]);

    };
    const handleInsuranceChange = (value: boolean) => {

        setValue("insurance", value as ClientFormRequest["insurance"]);

    };
    const handleStatusChange = (value: string) => {

        setValue("status", value as ClientFormRequest["status"]);

    };

    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Photo</span>
                    </div>
                    <div className="col-8">
                        <UploadPhoto
                            onChange={(file) => setValue("photo", file)}
                            value={watch("photo") as File | string}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Client Type</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={clientTypeOptions}
                            onChange={handleClientTypeChange}
                            value={watch("clientType")}
                            error={errors.clientType?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Risk Level</span>
                    </div>
                    <div className="col-8">
                        <div className="tabs-navigation">
                            <div className="tabs-navigation-list --list-2">
                                <RadioGroup
                                    options={riskLevelOptions}
                                    onChange={handleRiskLevelChange}
                                    value={watch("riskLevel")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Full Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("fullName")}
                            placeholder="Enter full name..."
                            error={errors.fullName?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Company Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("companyName")}
                            placeholder="Enter company name..."
                            error={errors.companyName?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Insurance</span>
                    </div>
                    <div className="col-8">
                        <RadioGroup
                            options={insuranceOptions}
                            onChange={handleInsuranceChange}
                            value={watch("insurance")}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Status</span>
                    </div>
                    <div className="col-8">
                        <RadioGroup
                            options={statusOptions}
                            onChange={handleStatusChange}
                            value={watch("status")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ClientDetails;
