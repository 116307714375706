import React, { useState } from "react";
import AddIcon from "@/assets/img/ico-add.svg?react";

interface UploadPhotoGridProps {
	photos: (string | File)[];
	maxPhotos?: number;
	onChange: (files: File[]) => void;
	label?: string
}

const UploadPhotoGrid: React.FC<UploadPhotoGridProps> = ({
	photos,
	maxPhotos = 20,
	onChange,
	label
}) => {
	const [images, setImages] = useState<(string | File)[]>(photos);

	const handleAddPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;

		if (files) {
			const newFiles = Array.from(files).slice(0, maxPhotos - images.length);
			const updatedImages = [...images, ...newFiles];
			setImages(updatedImages);
			onChange(newFiles);
		}
	};

	return (
		<div className="photo-grid">
			{label && <label className="label fw-500 color-default">{label}</label>}
			<div className="photos">
				{images.map((photo, index) => (
					<div key={index} className="photo-item">
						{typeof photo === "string" ? (
							<img src={photo} alt={`Photo ${index + 1}`} />
						) : (
							<img src={URL.createObjectURL(photo)} alt={`Photo ${index + 1}`} />
						)}
					</div>
				))}
				{images.length < maxPhotos && (
					<label className="add-photo-btn">
						<input
							type="file"
							hidden
							multiple
							onChange={handleAddPhoto}
						/>
						<div className="col-group gap--xs items-center justify-center">
							<div className="btn btn--md btn--custom-1 rounded-full btn--square">
								<span className="ico">
									<AddIcon />
								</span>
							</div>
							<p className="text--sm">Add Photo</p>
						</div>
					</label>
				)}
			</div>
		</div>
	);
};

export default UploadPhotoGrid;
