
import Input from "@/components/common/Input";
import RadioGroup from "@/components/common/RadioGroup";
import Select, { OptionCustomProps } from "@/components/common/Select";
import UploadPhoto from "@/components/common/UploadPhoto";
import { departmentOptions, statusOptions } from "@/constants/options";
import { professionsApi, useGetAllProfessionsQuery, useLazyGetAllProfessionsQuery } from "@/api/professionsApi";
import { UserFormRequest } from "@/validation/userSchema";
import { Controller, UseFormReturn } from "react-hook-form";
import SelectAsynkPagination from "@/components/common/SelectAsynkPagination";


interface UserDetailsProps {
    formState: UseFormReturn<UserFormRequest>
}

const UserDetails = ({ formState }: UserDetailsProps) => {
    const [getProfessionals] = useLazyGetAllProfessionsQuery()

    const { register, watch, setValue, control, formState: { errors, defaultValues } } = formState;

    const handleDepartamentChange = (option: OptionCustomProps<number>) => {

        setValue("departmentId", option.value as UserFormRequest["departmentId"]);

    };
    const handleStatusChange = (value: string) => {

        setValue("status", value as UserFormRequest["status"]);

    };
    // const handleProfessionChange = (option: OptionCustomProps<string>) => {
    //     setValue("profession", option.value as UserFormRequest["profession"]);
    // };
    // console.log(formState.getValues())
    const loadOptionsUsers = async (
        search: string,
        loadedOptions: any,
        { page }: { page: number },
    ) => {
        const res = await getProfessionals({ page, search }).unwrap()

        return {
            options:
                res?.data.map((profession) => ({
                    value: profession.name,
                    label: profession.name,
                })) || [],
            hasMore: page != res?.count?.maxPages, // флаг, показывающий, есть ли еще данные
            additional: {
                page: page + 1, // увеличиваем номер страницы для следующего запроса
            },
        }
    }
    // const professionOptions: OptionCustomProps<string>[] = response?.data?.map(profession => ({ label: profession.name, value: profession.name })) || []

    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Photo</span>
                    </div>
                    <div className="col-8">
                        <UploadPhoto
                            onChange={(file) => setValue("photo", file)}
                            value={watch("photo") as File | string}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">First Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("firstName")}
                            placeholder="Enter last name..."
                            error={errors.firstName?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Last Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("lastName")}
                            placeholder="Enter last name..."
                            error={errors.lastName?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">User Role</span>
                    </div>
                    <div className="col-8">
                        <Controller
                            name="profession"
                            control={control}
                            render={({ field }) => (
                                <SelectAsynkPagination
                                    {...field}
                                    onChange={(option) => {
                                        field.onChange(option?.value)
                                    }}
                                    value={field.value ? { value: field.value, label: field.value } : undefined}
                                    defaultValue={defaultValues.profession ? { value: defaultValues.profession, label: defaultValues.profession } : undefined}
                                    error={errors.profession?.message}
                                    fetchFunk={loadOptionsUsers}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Department</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={departmentOptions}
                            onChange={handleDepartamentChange}
                            value={watch("departmentId")}
                            error={errors.departmentId?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Status</span>
                    </div>
                    <div className="col-8">
                        <RadioGroup
                            options={statusOptions}
                            onChange={handleStatusChange}
                            value={watch("status")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UserDetails;
