import Input from "@/components/common/Input";
import Select, {OptionCustomProps} from "@/components/common/Select";
import {
    cityOptions,
    countryOptions,
    frequencyOptions,
    locationOptions,
    riskLevelOptions,
    stateOptions
} from "@/constants/options";
import {EventFormRequest} from "@/validation/eventSchema";
import {Controller, UseFormReturn} from "react-hook-form";
import DatepickerOutline from "@/components/common/DatepickerOutline";
import {useEffect} from "react";
import TimepickerOutline from "@/components/common/TimepickerOutline";
import RadioGroup from "@/components/common/RadioGroup";
import {FrequencyEnum, LocationEnum} from "@/types/enums";
import NumericInput from "@/components/common/NumericInput";
import RepeatFields from "@/pages/SchedulingPage/NewEventModal/components/RepeatFields";

interface DateProps {
    formState: UseFormReturn<EventFormRequest>;
}

const DateAndLocation = ({formState}: DateProps) => {
    const {
        control,
        getValues,
        register,
        watch,
        setValue,
        resetField,
        formState: {errors, defaultValues},
    } = formState;

    useEffect(() => {
        console.log("Current form state:", getValues());
    }, [formState]);

    return (
        <div className="row g-20">
            <div className="row mb-20">
                <div className="col-4 flex items-center">
                    <span className="fw-500 text--md">Date</span>
                </div>
                <div className="col-8">
                    <Controller
                        name="date"
                        control={control}
                        render={({field}) => (
                            <DatepickerOutline
                                wrapperClassName="w-full"
                                onChange={(date) => field.onChange(date.toDateString())}
                                dateFormat="EEEE, dd MMMM yyyy"
                                selectedDate={field.value?.toString()}
                                error={errors?.date?.message}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-4 flex items-center">
                    <span className="fw-500 text--md">Time</span>
                </div>
                <div className="col-8 flex items-center gap-10">
                    <div className="flex items-center gap-10">
                        <div className="flex-1">
                            <Controller
                                name="timeFrom"
                                control={control}
                                render={({field}) => (
                                    <TimepickerOutline
                                        wrapperClassName="w-full"
                                        onChange={(time) => field.onChange(time)}
                                        selectedTime={field.value}
                                        error={errors?.timeFrom?.message}
                                    />
                                )}
                            />
                        </div>
                        <span>
                            <svg
                                width="12"
                                height="2"
                                viewBox="0 0 12 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect y="0.5" width="12" height="1" fill="#E0E0E0"/>
                            </svg>
                        </span>
                        <div className="flex-1">
                            <Controller
                                name="timeTo"
                                control={control}
                                render={({field}) => (
                                    <TimepickerOutline
                                        wrapperClassName="w-full"
                                        onChange={field.onChange}

                                        selectedTime={field?.value}
                                        error={errors?.timeTo?.message}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div
                        className={`col-4 flex ${watch("frequency") === FrequencyEnum.REPEAT ? "items-start" : "items-center"} `}>
                        <span className="fw-500 text--md">Frequency</span>
                    </div>
                    <div className="col-8">
                        <div className="tabs-navigation">
                            <div className="tabs-navigation-list --list-2">
                                <Controller
                                    name="frequency"
                                    control={control}
                                    render={({field}) => (
                                        <RadioGroup
                                            options={frequencyOptions}
                                            onChange={(value) => field.onChange(value)}
                                            value={field.value}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {watch("frequency") === FrequencyEnum.REPEAT &&
                            <RepeatFields control={control} errors={errors}/>
                        }
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-start">
                        <span className="fw-500 text--md">Location</span>
                    </div>
                    <div className="col-8">
                        <div className="tabs-navigation">
                            <div className="tabs-navigation-list --list-2">
                                <Controller
                                    name="location"
                                    control={control}
                                    render={({field}) => (
                                        <RadioGroup
                                            options={locationOptions}
                                            onChange={(value) => field.onChange(value)}
                                            value={field.value}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {watch("location") === LocationEnum.PHYSICAL ?
                            <div className="row mt-16">
                                <Input
                                    className="input--md"
                                    {...register("address")}
                                    placeholder="Enter address here..."
                                    error={errors.address?.message}
                                />
                            </div>
                            :
                            <div className="row mt-16">
                                <Input
                                    className="input--md"
                                    {...register("addressUrl")}
                                    placeholder="e.g., “https://meet.google.com/example”"
                                    error={errors.addressUrl?.message}
                                />
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
};

export default DateAndLocation;
