import { useTabs } from "@/hooks/useTabs";
import { useCallback, useEffect, useState } from "react";

import CloseIcon from "@/assets/img/close-icon.svg?react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Client, VoidFunctionWithoutArguments } from "@/types";
import StepsNavigation from "@/components/common/StepsNavigation";
import EntityFooter from "@/components/modals/NewEntityModal/EntityFooter";
import {  Status } from "@/types/enums";
import OrderDetails from "../tabs/SupplierDetails";
import { supplierSchema } from "@/validation/supplierSchema";
import Contacts from "../tabs/Contacts";
import Materials from "../tabs/Materials";
import Orders from "../tabs/Orders";

interface SupplierDataProps {
    handleCancel: VoidFunctionWithoutArguments;
    handleCompleteClick: VoidFunctionWithoutArguments;
    data: any;
    title?: string;
}

const detailsFields = ["photo",
    "supplierName",
    "type",
    "status"] as const
const contactsFields = ["contactName",
    "address",
    "phone",
    "phoneCountry",
    "website",
    "email"] as const
const tabs = [
    {
        label: "Details",
        id: "details",
        fields: detailsFields
    },
    {
        label: "Contacts",
        id: "contacts",
        fields: contactsFields
    },
    {
        label: "Materials",
        id: "materials",
        fields: []
    },
    {
        label: "Orders",
        id: "orders",
        fields: []
    },
] as const;

type TabId = typeof tabs[number]["id"];


const SupplierData: React.FC<SupplierDataProps> = ({
    handleCancel,
    handleCompleteClick,
    data,
    title = 'New Supplier'
}) => {
    
    const { activeTab, setActiveTab, activeTabIndex, destroy } = useTabs(tabs, "mt");

    const currentSchema = supplierSchema.pick(tabs[activeTabIndex].fields);
    const resolver = yupResolver(currentSchema);
    const formState = useForm({
        resolver,
        defaultValues: {
            status: Status.ACTIVE,
        }
    });
    const { register, handleSubmit, formState: { errors }, reset } = formState;

    const onSubmit = async (data: any) => {
        console.log("Form data:", data);
        
        if (activeTabIndex < tabs.length - 1) {
            setActiveTab(tabs[activeTabIndex + 1].id);
        } else {
            handleCompleteClick();
        }
    };

    
    useEffect(() => {

        console.log("errors", errors);

    }, [errors]);

    useEffect(() => {

        return () => {
            destroy('modal')
        };

    }, []);


    const tabComponents: Record<TabId, JSX.Element | null> = {
        details: <OrderDetails formState={formState} />,
        contacts: <Contacts formState={formState} />,
        materials: <Materials formState={formState} />,
        orders: <Orders formState={formState} />,
    };

    const handleGoBack = useCallback(() => {

        setActiveTab(tabs[activeTabIndex - 1].id);

    }, [activeTabIndex]);

    const renderTab = () => {

        return tabComponents[activeTab];

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__header">
                <h4 className="heading flex-auto">{title}</h4>
                <div className="modal__header-actions">
                    <button type="button" 
                    onClick={async () => {
                         
                        handleCancel()
                    }} 
                    className="btn btn--custom-1 btn--md rounded-full btn--square">
                        <span className="ico"><CloseIcon /></span>
                    </button>
                </div>
            </div>

            <div className="modal__body pt-0">
                <div className="w-full">
                    {tabs.length > 1 &&
                    <StepsNavigation
                        activeStep={activeTab}
                        setActiveStep={setActiveTab}
                        steps={tabs}
                    />}
                    <div className="modal-tabs">
                        {renderTab()}
                    </div>
                </div>
            </div>
            <EntityFooter
                // isLoading={isLoading}
                handleGoBack={handleGoBack}
                handleCancel={handleCancel}
                isFirstStep={activeTab === "details"}
                completeText="Complete"
                nextStepText="Next Step"
                isFinalStep={activeTab === "orders"}
            />
        </form>
    );

};

export default SupplierData;
