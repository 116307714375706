import { ModalProps } from "@/components/modals/Modal";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";

import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import { Client } from "@/types";
import OrderData from "./components/OrderData";

interface NewClientModal extends ModalProps {
    data: Client
}



const NewOrderModal = ({ data, ...props }: NewClientModal) => {
    const { currentStage,
        handleCompleteClick,
        handleContinueClick,
        handleCancel,
        handleGoToSelection
    } = useNewEntityModal();

    const completeActionButtons = [{
        text: "Add Order",
        handler: () => { }
    }];
    
    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New Order Created!"
            completeDescription="Congratulations on successfully creating a new order! You can now create new order or return to the home page."
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
            handleBackToSelectionClick={handleGoToSelection}
            handleCompleteClick={handleCompleteClick}
        >
            <OrderData data={data} handleCancel={handleCancel} handleCompleteClick={handleCompleteClick} />
        </NewEntityModal>
    );
};

export default NewOrderModal;
