import AddIcon from "@/assets/img/ico-add.svg?react";

import EditIcon from "@/assets/img/ico-edit.svg?react";



import Button from "@/components/common/Button";
import DatepickerOutline from "@/components/common/DatepickerOutline";
import DropdownActions from "@/components/common/DropdownActions";
import Filters from "@/components/common/Filters";
import Search from "@/components/common/Search";
import Table from "@/components/common/Table";
import TabNavigation from "@/components/common/TabNavigation";
import ViewModeSwitcher, { useViewMode } from "@/components/common/ViewModeSwitcher";
import PageLoader from "@/components/layout/PageLoader";
import { statusMap } from "@/constants";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import useModal from "@/hooks/useModal";
import { useTabs } from "@/hooks/useTabs";
import { routerConfig } from "@/routes";
import { useGetAllProjectsQuery } from "@/api/projectsApi";
import { IProjectBackendData } from "@/types";
import { formatAddress, getFullUrl } from "@/utils";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { Link } from "react-router";
import ProjectCard from "../../components/cards/ProjectCard";
import { ModalID } from "@/providers/ModalProvider";

const tabs = [{
    id: "total-projects",
    label: "Total Projects"
}, {
    id: "near-deadline",
    label: "Near Deadline"
}, {
    id: "overdue",
    label: "Overdue"
}] as const;

const ProjectsPage = () => {
    const { data: response, isLoading } = useGetAllProjectsQuery()
    const data = response?.data
    const { openModal } = useModal();
    useBreadcrumbs([routerConfig.projects]);
    const { activeTab, setActiveTab } = useTabs(tabs);
    const { isTableView, isGridView } = useViewMode();


    const actions = (arg: IProjectBackendData) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => openModal(ModalID.EditProject, { data: arg }),
    }];

    const columns: ColumnDef<IProjectBackendData>[] = [{
        header: "Project Name",
        accessorKey: "projectName",
        minSize: 228,
        cell: ({ row: { original } }) => <div className="table-user-info-texts">
            <Link to={getFullUrl([routerConfig.projects.path, original.id])} className="btn btn-link color-secondary tdu">
                <span className="text--md font-400">{original.name}</span>
            </Link>
            <p className="color-control-2 text--sm font-400">
                {formatAddress(original.address, ['country', 'city'])}
            </p>
        </div>

    },
    {
        header: "Client",
        accessorKey: "userName",
        cell: ({ row: { original } }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{'client'}</span>
        </a>

    },
    {
        header: "Users",
        accessorKey: "users",
        cell: ({ row: { original } }) => <div className="flex items-center gap--xs">
            {/* <ul className="user-list">
                {original.users.map((user) => <li key={user.id} className="user-item">
                    <img src={user.photo} alt={user.fullName} />
                </li>)}
            </ul>
            <span className="users-counter-2">{original.users.length}</span> */}
            Clients
        </div>

    },
    {
        header: "Tasks",
        accessorKey: "progress",
        cell: ({ row: { original } }) => <p className="font-500">
            {/* <span>{progress.completed}</span> / <span>{progress.total}</span> */}
            progress
        </p>

    },
    {
        header: "Documents",
        accessorKey: "documentCount",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.contracts?.length} docs</p>

    },
    {
        header: "Materials",
        accessorKey: "materialsCount",
        cell: ({ row: { original } }) => <p className="color-control-3">0</p>

    },
    {
        header: "Status",
        accessorKey: "status",
        cell: ({ row: { original } }) => <div className={clsx("pill-custom pill--sm", statusMap[original.status]?.className)}>
            <span>{statusMap[original.status]?.label}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            variant="secondary"
            actions={actions(original)}
        />

    }];


    if (isLoading) {
        return <PageLoader />
    }

    return (
        <main className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="rectangle-block-containers block-container-fullpage tabs">
                    <div className="block-container navigation-block">
                        <div className="heading--row">
                            <div className="heading-option">
                                <DatepickerOutline />
                                <span className="option-separator"></span>
                                <TabNavigation
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    renderTabCount={() => 3}
                                />
                            </div>
                            <div className="heading-option">
                                <ViewModeSwitcher />
                                <span className="option-separator"></span>
                                <div className="flex-controls gap--xs">
                                    <Filters />
                                    <Search />
                                    <Button
                                        uiType="primary-advance-1"
                                        onClick={() => openModal(ModalID.NewProject)}
                                    >
                                        <span className="ico"><AddIcon /></span>
                                        <span>New Project</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-container page-results">
                        <div className={clsx("view-page-block tabs-fullpage", { "active-page": isGridView })}>
                            <div className="tab active-tab" data-id="total-projects">
                                <div className="row gutters-4">
                                    {
                                        data.map((item) => <div key={item.id} className="col-12 col-sm-12 col-lg-4 col-xl-3 flex flex-col">
                                            <ProjectCard data={item} />
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={clsx("view-page-block tabs-fullpage", { "active-page": isTableView })}>
                            <Table columns={columns} data={data} selectable />
                        </div>
                    </div>
                </div>

            </div>
        </main>
    );

};


export default ProjectsPage;
