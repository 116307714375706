import AccountIcon from "@/assets/img/icon-account-settings.svg?react";
import DarkIcon from "@/assets/img/icon-dark-mode.svg?react";
import HelpIcon from "@/assets/img/icon-help-support.svg?react";
import ProfileIcon from "@/assets/img/icon-my-profile.svg?react";
import SignOutIcon from "@/assets/img/icon-sign-out.svg?react";
import StatisticsIcon from "@/assets/img/icon-statistics.svg?react";
import Image from "@/components/common/Image";
import {USER_ROLE} from "@/constants";
import {useSelectMe} from "@/hooks/redux/selectHooks/selectMe";
import {useLazyLogoutQuery} from "@/api/authApi";
import {getFullName} from "@/utils";
import {Link, useNavigate} from "react-router";
import Dropdown from "../../common/Dropdown";
import {useEffect} from "react";
import {router, routerConfig} from "@/routes";


const DropdownProfile = () => {

    const me = useSelectMe();
    const [logout] = useLazyLogoutQuery();
    const navigate = useNavigate();
    const handleLogout = () => {

        logout();
        navigate("/");

    };

    const routes = [{
        name: routerConfig.profile.name,
        path: routerConfig.profile.path,
        icon: <ProfileIcon/>
    }, {
        name: routerConfig.settings.name,
        path: routerConfig.settings.path,
        icon: <AccountIcon/>
    }, {
        name: routerConfig.statics.name,
        path: routerConfig.statics.path,
        icon: <StatisticsIcon/>
    }, {
        name: routerConfig.support.name,
        path: routerConfig.support.path,
        icon: <HelpIcon/>
    }];


    const handleModeChange = () => {
        document.body.classList.toggle("theme-dark");
        localStorage.setItem('kbf-theme', document.body.classList.contains('theme-dark') ? 'dark-theme' : 'light-theme');
    };

    useEffect(() => {
        const savedTheme = localStorage.getItem('kbf-theme')
        document.querySelector('body')?.classList.add(savedTheme)
        if (savedTheme === 'dark-theme') {
            handleModeChange();
        }
    }, [])

    return (
        <Dropdown
            button={(
                <button type="button" className="header__user-avatar ico ico--xs rounded-full dropdown-btn">
                    <Image src={me.photo} alt={getFullName(me)}/>
                </button>
            )}
            bodyClassName="dropdown__table"
        >
            <div className="dropdown-block">
                <div className="dropdown-block--body">
                    <ul className="dropdown-list">
                        <li className="dropdown-list--item">
                            <div className="dropdown-user-block">
                                <div className="user-img-32x32">
                                    <Image src={me.photo} alt={getFullName(me)}/>
                                </div>
                                <div className="dropdown-user-info">
                                    <span className="dropdown-user-name">{getFullName(me)}</span>
                                    {/* TODO: */}
                                    <span className="dropdown-user-job">{USER_ROLE["admin"]}</span>
                                </div>
                            </div>
                        </li>
                        <li className="dropdown-list--item">
                            <div className="btn btn--xs drp-list-wrapp-separator">
                                <hr className="drp-list-separator"/>
                            </div>
                        </li>
                        {routes.map((route) => <li key={route.path} className="dropdown-list--item">
                            <Link to={route.path} className="btn btn--xs btn-drp-link">
                                <span className="ico">{route.icon}</span>
                                <span className="info">{route.name}</span>
                            </Link>
                        </li>)}
                        <li className="dropdown-list--item">
                            <div className="btn btn--xs drp-list-wrapp-separator">
                                <hr className="drp-list-separator"/>
                            </div>
                        </li>
                        <li className="dropdown-list--item flex-row justify-between">
                            <p className="btn btn--xs btn-drp-link">
                                <span className="ico"><DarkIcon/></span>
                                <span className="info">Dark Mode</span>
                            </p>

                            <label className="switch-control-wrapper pr-9">
                                <label className="switch-control">
                                    <input onChange={handleModeChange} type="checkbox" hidden/>
                                    <div className="switch-btn">
                                        <div className="switch-point-wrapp">
                                            <span className="switch-point"></span>
                                        </div>
                                    </div>
                                </label>
                            </label>
                        </li>
                        <li className="dropdown-list--item">
                            <div className="btn btn--xs drp-list-wrapp-separator">
                                <hr className="drp-list-separator"/>
                            </div>
                        </li>
                        <li className="dropdown-list--item">
                            <button onClick={handleLogout} className="btn btn--xs btn-drp-link">
                                <span className="ico"><SignOutIcon/></span>
                                <span className="info">Sign Out</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </Dropdown>
    );

};

export default DropdownProfile;
