
import BoxIcon from "@/assets/img/ico-box.svg?react";
import DocumentIcon from "@/assets/img/ico-doc.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import GlobeIcon from "@/assets/img/ico-globe.svg?react";
import MailCharIcon from "@/assets/img/ico-mail-char.svg?react";
import MapPinIcon from "@/assets/img/ico-map-pin.svg?react";
import PhoneIcon from "@/assets/img/ico-phone.svg?react";
import UserIcon from "@/assets/img/ico-user.svg?react";
import projectImage from "@/assets/img/project-img-1.jpg";

import DropdownActions from "@/components/common/DropdownActions";
import Table from "@/components/common/Table";
import { ColumnDef } from "@tanstack/react-table";
import { ReactNode } from "react";


interface Supplier {
    supplier: string;
    contactPerson: string;
    contacts: { type: string; info: string; }[];
    materialType: string;
    ordersCount: number;
    status: string;
}

interface SuppliesTabProps {
    isGridView: boolean
    isTableView: boolean
}

type ContactsType = "address" | "email" | "phone" | "website"
const SuppliesTab = ({ isGridView, isTableView }: SuppliesTabProps) => {

    const contactIcons: Record<ContactsType, ReactNode> = {
        address: <MapPinIcon />,
        email: <MailCharIcon />,
        phone: <PhoneIcon />,
        website: <GlobeIcon />
    };

    const data: Supplier[] = [{
        supplier: "ABC Building Supplies",
        contactPerson: "Courtney Henry",
        contacts: [{ type: "address",
            info: "New York, NY" }, { type: "email",
            info: "james.cole@example.com" }, { type: "phone",
            info: "(646) 555-0198" }, { type: "website",
            info: "www.abcbuildings.com" }],
        materialType: "Flooring",
        ordersCount: 123,
        status: "Active"
    }, {
        supplier: "ABC Building Supplies",
        contactPerson: "Courtney Henry",
        contacts: [{ type: "address",
            info: "New York, NY" }, { type: "email",
            info: "james.cole@example.com" }, { type: "phone",
            info: "(646) 555-0198" }, { type: "website",
            info: "www.abcbuildings.com" }],
        materialType: "Flooring",
        ordersCount: 123,
        status: "Active"
    }];
    const actions = (arg: Supplier) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => console.log(arg)
    }];
    const columns: ColumnDef<Supplier>[] = [{
        header: "Supplier",
        accessorKey: "supplier",
        cell: ({ row: { original } }) => <p>{original.supplier}</p>

    },
    {
        header: "Contact Person",
        accessorKey: "contactPerson",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.contactPerson}</p>
    },
    {
        header: "Contacts",
        accessorKey: "contacts",
        cell: ({ row: { original } }) => <ul className="contacts-list">
            {original.contacts.map((contact, index) => <li className="contacts-list--item" key={index}>
                <div className="contact-info">
                    <span className={"ico color-control-2"}>
                        {contactIcons[contact.type as ContactsType]}
                    </span>
                    <span className="text--md color-control-3">{contact.info}</span>
                </div>
            </li>)}
        </ul>

    },
    {
        header: "Type",
        accessorKey: "materialType",
        cell: ({ row: { original } }) => <div className="pill-custom pill--custom-3 pill--sm">
            <span>{original.materialType}</span>
        </div>

    },
    {
        header: "Orders #",
        accessorKey: "ordersCount",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.ordersCount}</p>
    },
    {
        header: "Status",
        accessorKey: "status",
        cell: ({ row: { original } }) => <div className="pill-custom pill--custom-1 pill--sm">
            <span>{original.status}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            variant="secondary"
            actions={actions(original)}
        />

    }];

    if (isGridView) {

        return (
            <div className="row gutters-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">
                    <div className="card-widget card-widget-product w-full h-full">
                        <div className="card-widget-body">
                            <div className="card-details-wrpr">
                                <div className="card-details--item">
                                    <div className="card-details--head">
                                        <div className="user-option-block user-option-block--sm">
                                            <div className="user-block-relatives">
                                                <div className="ico ico--lg user-block-img-wrpr">
                                                    <img src={projectImage} alt="project" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-details-actions">
                                            <DropdownActions actions={[]} />
                                        </div>
                                    </div>
                                    <div className="card-details--body">
                                        <div className="card-details-heading">
                                            <p className="heading user-block-title text--xl">
                                                ABC Building Supplies
                                            </p>
                                        </div>

                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <UserIcon />
                                                    </span>
                                                    <p className="project-variable--text">Jane Cooper</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <BoxIcon />
                                                    </span>
                                                    <p className="project-variable--text">Materials:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">123</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <DocumentIcon />
                                                    </span>
                                                    <p className="project-variable--text">Orders:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">123</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-statuses">
                                                    <div className="pill-custom pill--custom-3 pill--sm">
                                                        <span>Flooring</span>
                                                    </div>
                                                    <div className="pill-custom pill--custom-1 pill--sm"><span>Active</span></div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="card-details--item">
                                    <div className="card-details--body">

                                        <ul className="project-list user-noname-desk contacts-list">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <MapPinIcon />
                                                    </span>
                                                    <p className="project-variable--text">New York, NY</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <MailCharIcon />
                                                    </span>
                                                    <p className="project-variable--text">james.cole@example.com</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <PhoneIcon />
                                                    </span>
                                                    <p className="project-variable--text">(646) 555-0198</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <GlobeIcon />
                                                    </span>
                                                    <p className="project-variable--text">www.abcbuildings.com</p>
                                                </div>
                                            </li>
                                        </ul>

                                        <div className="card-details--actions">
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span>Change Status</span>
                                                </a>
                                            </div>
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <EditIcon />
                                                    <span>Edit</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    return <Table columns={columns} data={data} selectable />;

};

export default SuppliesTab;
