import Modal, { ModalProps } from "@/components/modals/Modal";
import { useCallback } from "react";
import TeamData from "../NewTeamModal/components/TeamData";
import { ITeamBackendData, Team } from "@/types";
import { dtoService } from "@/services/dtoService";

interface EditTeamModalProps extends ModalProps {
    data: ITeamBackendData
}

const EditTeamModal: React.FC<EditTeamModalProps> = ({data, ...props}) => {
    const handleCancel = useCallback(() => {
            props.onClose();
        }, []);

    return (
        <Modal {...props}
            closeOnClickOutside={false}
            dialogClassName={"modal__dialog--700"}
            contentClassName={"modal--rounded"}
        >
            <TeamData
                data={dtoService.convertTeamFromBackend(data)} 
                handleCancel={handleCancel}
                handleCompleteClick={handleCancel}
                title="Edit Team"
            />
        </Modal>
    )
}

export default EditTeamModal
