import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "@/redux/store";

import "./index.css";
import "@/assets/css/bootstrap-grid.css";
import "@/assets/css/emoji-flags.css";
import "@/assets/css/reset.css";
import "@/assets/css/global.css";
import "@/assets/css/components/components.css";
import "@/assets/css/main-alpha.css";
import "@/assets/css/main-bravo.css";
import "@/assets/css/main-charlie.css";
import "@/assets/css/main-delta.css";
import "@/assets/css/media-alpha.css";
import "@/assets/css/media-bravo.css";
import "@/assets/css/media-charlie.css";
import "@/assets/css/media-delta.css";
import "@/assets/css/custom.css";

createRoot(document.getElementById("root")!).render(<Provider store={store}>
    <App />
</Provider>);
