import clsx from "clsx";
import React, { forwardRef } from "react";

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    placeholder?: string;
    className?: string;
    textareaClassName?: string;
    error?: boolean | string;
    labelClassName?: string
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({
    label,
    placeholder = "",
    className = "input--xl",
    labelClassName,
    textareaClassName = "input--outline",
    error,
    ...props
}, ref) => {

    return (
        <div className={clsx("form-group", className)}>
            {label && <label className={clsx("label", labelClassName)}>{label}</label>}
            <textarea
                className={clsx("textarea", textareaClassName, {
                    "input-error": error,
                    "input-disabled": props.disabled,
                })}
                placeholder={placeholder}
                ref={ref}
                {...props}
            />
            {typeof error === "string" && <p className="error-message">{error}</p>}
        </div>
    );

});

Textarea.displayName = "Textarea";

export default Textarea;
