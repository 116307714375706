import { useTabs } from "@/hooks/useTabs";
import { useCallback, useEffect } from "react";

import CloseIcon from "@/assets/img/close-icon.svg?react";
import StepsNavigation from "@/components/common/StepsNavigation";
import EntityFooter from "@/components/modals/NewEntityModal/EntityFooter";
import { useCreateClientMutation, useUpdateClientMutation } from "@/api/clientsApi";
import { Client, ITeamFormData, Team, VoidFunctionWithoutArguments } from "@/types";
import { clientSchema } from "@/validation/clientSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Members from "../tabs/Members";
import TeamDetails from "../tabs/TeamDetails";
import { teamSchema } from "@/validation/teamSchema";
import { Status } from "@/types/enums";
import { useCreateTeamMutation, useUpdateTeamMutation } from "@/api/teamsApi";
import { ICONS } from "@/utils/teamsIcons";

interface TeamDataProps {
    title?: string;
    handleCancel: VoidFunctionWithoutArguments;
    handleCompleteClick: VoidFunctionWithoutArguments;
    data?: ITeamFormData;
}

const detailsFields = [
    'name',
    'icon',
    'status',
    'TeamLeadId',
    'DepartmentId',
] as const
const memberFields = ['Members'] as const

const tabs = [{
    label: "Team Details",
    id: "details",
    fields: detailsFields
}, {
    label: "Members",
    id: "members",
    fields: memberFields
}] as const;

type TabId = typeof tabs[number]["id"];


const TeamData: React.FC<TeamDataProps> = ({
    handleCancel,
    handleCompleteClick,
    data,
    title,
}) => {
    const teamId = data?.id
    const [create, { isLoading: isCreating }] = useCreateTeamMutation()
    const [update, { isLoading: isUpdating }] = useUpdateTeamMutation()
    const isLoading = isCreating || isUpdating
    const { activeTab, setActiveTab, activeTabIndex, destroy } = useTabs(tabs, "mt");

    const currentSchema = teamSchema.pick(tabs[activeTabIndex].fields);
    const resolver = yupResolver(currentSchema);
    const formState = useForm({
        resolver,
        defaultValues: data || {
            status: Status.ACTIVE,
            icon: 'pm'
        }
    });
    const { register, handleSubmit, formState: { errors }, reset } = formState;


    const onSubmit = async (data: any) => {
        console.log("Form data:", data);
        let res;
        if (activeTabIndex < tabs.length - 1) {

            setActiveTab(tabs[activeTabIndex + 1].id);

        } else {
            if (teamId) {
                res = await update({ teamId, body: data }).unwrap()
            } else {
                res = await create(data).unwrap()
            }
            if(res?.success) {
                handleCompleteClick();
            }
            console.log("Final Submit:", data);

        }

    };

    useEffect(() => {

        return () => destroy();

    }, []);

    useEffect(() => {

        console.log("errors", errors);

    }, [errors]);


    const tabComponents: Record<TabId, JSX.Element | null> = {
        details: <TeamDetails formState={formState} />,
        members: <Members formState={formState} />,
    };

    const handleGoBack = useCallback(() => {

        setActiveTab(tabs[activeTabIndex - 1].id);

    }, [activeTabIndex]);

    const renderTab = () => {

        return tabComponents[activeTab];

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__header">
                <h4 className="heading flex-auto">{title || 'New Team'}</h4>
                <div className="modal__header-actions">
                    <button type="button" onClick={handleCancel} className="btn btn--custom-1 btn--md rounded-full btn--square">
                        <span className="ico"><CloseIcon /></span>
                    </button>
                </div>
            </div>

            <div className="modal__body pt-0">
                <div className="w-full">
                    <StepsNavigation
                        activeStep={activeTab}
                        setActiveStep={setActiveTab}
                        steps={tabs}
                    />
                    <div className="modal-tabs">
                        {renderTab()}
                    </div>
                </div>
            </div>
            <EntityFooter
                isLoading={isLoading}
                handleGoBack={handleGoBack}
                handleCancel={handleCancel}
                isFirstStep={activeTab === "details"}
                completeText="Complete"
                nextStepText="Next Step"
                isFinalStep={activeTab === "members"}
            />
        </form>
    );

};

export default TeamData;
