import { IProjectBackendData, ITeamBackendData, Project, Team } from "@/types";

function convertTeamFromBackend(team: ITeamBackendData): Team {
    const { id, name, icon, status, members, projects, teamLead, department } = team;
    return {
        id,
        name,
        icon,
        status,
        members,
        projects,
        teamLead,
        DepartmentId: department.id,
        Members: members,
        TeamLeadId: teamLead.id,
    }
}

function convertProjectFromBackend(project: IProjectBackendData): Project {
    const { id, cover, type, name, deadline, description, estVolume, status, photos, contracts, address, departmentId } = project;

    const photosUrls = photos.map(photo => photo.name);
    const contractsUrls = contracts.map(contract => contract.name);

    return {
        id,
        address,
        contracts: contractsUrls,
        cover,
        deadline,
        departmentId,
        description,
        estVolume,
        name,
        photos: photosUrls,
        status,
        type,
    }
}

export const dtoService = {
    convertTeamFromBackend,
    convertProjectFromBackend
}