import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import {useCreateClientMutation, useUpdateClientMutation} from "@/api/clientsApi";
import {Event, VoidFunctionWithoutArguments} from "@/types";
import {FrequencyEnum, LocationEnum} from "@/types/enums";

import CloseIcon from "@/assets/img/close-icon.svg?react";
import StepsNavigation from "@/components/common/StepsNavigation";
import EntityFooter from "@/components/modals/NewEntityModal/EntityFooter";

import {eventSchema} from "@/validation/eventSchema";

import Details from "../tabs/Details";
import DateAndLocation from "../tabs/DateAndLocation";
import Participants from "../tabs/Participants";

interface EventDataProps {
    handleCancel: VoidFunctionWithoutArguments;
    handleCompleteClick: VoidFunctionWithoutArguments;
    data: Event;
    title?: string;
}

const detailsFields = [
    "eventName",
    "project",
    "pipelinePhase",
    "description",
] as const;

const dateFields = [
    "date",
    "timeTo",
    "timeFrom",
    "frequency",
    "repeatInterval",
    "repeatEndsAfter",
    "repeatEvery",
    "location",
    "address",
    "addressUrl",
] as const;

const participantsFields = ["participants"] as const;

const tabs = [
    {
        label: "Details",
        id: "details",
        fields: detailsFields,
    },
    {
        label: "Date and Location",
        id: "date",
        fields: dateFields,
    },
    {
        label: "Participants",
        id: "participants",
        fields: participantsFields,
    },
] as const;

type TabId = typeof tabs[number]["id"];

const EventData: React.FC<EventDataProps> = ({
    handleCancel,
    handleCompleteClick,
    data,
    title = "New Event",
}) => {
    const clientId = data?.id;
    const [create, {isLoading: isCreating}] = useCreateClientMutation();
    const [update, {isLoading: isUpdating}] = useUpdateClientMutation();
    const isLoading = isUpdating || isCreating;

    const [activeTab, setActiveTab] = useState(tabs[0].id);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const currentSchema = eventSchema.pick(tabs[activeTabIndex]?.fields);

    const formState = useForm({
        resolver: yupResolver(currentSchema),
        defaultValues: data || {
            eventName: "",
            project: "",
            pipelinePhase: "",
            description: "",
            date: "",
            timeTo: "",
            timeFrom: "",
            frequency: FrequencyEnum.ONCE,
            repeatInterval: null,
            repeatEvery: null,
            repeatEndsAfter: null,
            addressUrl: null,
            location: LocationEnum.PHYSICAL,
            address: null,
            participants: [],
        },
    });
    const {
        handleSubmit,
        formState: {errors},
    } = formState;

    const onSubmit = async (formData: any) => {
        console.log("Form data:", formData);

        if (activeTabIndex < tabs.length - 1) {
            setActiveTab(tabs[activeTabIndex + 1].id as typeof activeTab);
            setActiveTabIndex((prev) => prev + 1);
            return;
        }


        const normaliedData = {...formData};
        try {
            let res;
            if (clientId) {
                res = await update({clientId, body: normaliedData}).unwrap();
            } else {
                res = await create(normaliedData).unwrap();
            }
            if (res?.success) {
                handleCompleteClick();
            }
            console.log("Final Submit:", normaliedData);
        } catch (err) {
            console.error("Error:", err);
        }
    };

    useEffect(() => {
        console.log("Validation errors", errors);
    }, [errors]);


    const tabComponents: Record<TabId, JSX.Element | null> = {
        details: <Details formState={formState}/>,
        date: <DateAndLocation formState={formState}/>,
        participants: <Participants formState={formState}/>,
    };

    const handleGoBack = useCallback(() => {
        setActiveTab(tabs[activeTabIndex - 1].id as typeof activeTab);
        setActiveTabIndex((prev) => prev - 1);
    }, [activeTabIndex]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__header">
                <h4 className="heading flex-auto">{title}</h4>
                <div className="modal__header-actions">
                    <button
                        type="button"
                        onClick={handleCancel}
                        className="btn btn--custom-1 btn--md rounded-full btn--square"
                    >
            <span className="ico">
              <CloseIcon/>
            </span>
                    </button>
                </div>
            </div>

            <div className="modal__body pt-0">
                <div className="w-full">
                    <StepsNavigation
                        activeStep={activeTab}
                        setActiveStep={(stepId) => {
                            setActiveTab(stepId as typeof activeTab);
                            const idx = tabs.findIndex((t) => t.id === stepId);
                            if (idx !== -1) setActiveTabIndex(idx);
                        }}
                        steps={tabs}
                    />
                    <div className="modal-tabs">{tabComponents[activeTab]}</div>
                </div>
            </div>

            <EntityFooter
                isLoading={isLoading}
                handleGoBack={handleGoBack}
                handleCancel={handleCancel}
                isFirstStep={activeTab === "details"}
                completeText="Complete"
                nextStepText="Next Step"
                isFinalStep={activeTab === "participants" as typeof activeTab}
            />
        </form>
    );
};

export default EventData;
