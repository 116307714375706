
import Button from "@/components/common/Button";
import Input from "@/components/common/Input";
import Select, { OptionCustomProps } from "@/components/common/Select";
import UploadPhoto from "@/components/common/UploadPhoto";
import { departmentOptions } from "@/constants/options";
import { useSelectMe } from "@/hooks/redux/selectHooks/selectMe";
import { useGetAllProfessionsQuery } from "@/api/professionsApi";
import { useUpdateMeMutation } from "@/api/usersApi";
import { UserFormRequest, userSchema } from "@/validation/userSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const fields = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'phoneCountry',
    'photo',
    'departmentId',
    'profession'
] as const

const Profile = () => {

    const [update, { isLoading }] = useUpdateMeMutation();
    const me = useSelectMe();
    const currentSchema = userSchema.pick(fields);
    const resolver = yupResolver(currentSchema);

    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            "firstName": me.firstName,
            "lastName": me.lastName,
            "email": me.email,
            "phone": me.phone,
            "phoneCountry": me.phoneCountry,
            "photo": me.photo,
            "profession": me.profession,
            'departmentId': 1
        },
        resolver
    });
    const { data: response } = useGetAllProfessionsQuery()

    const professionOptions: OptionCustomProps<string>[] = response?.data?.map(profession => ({ label: profession.name, value: profession.name })) || []


    const onSubmit = (data: UserFormRequest) => {

        update(data);

    };

    useEffect(() => {

        console.log(errors);

    }, [errors]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="tab-body__item-border">
                <div className="tab-body__item">
                    <div className="settings-item-group">
                        <div className="settings-item__label-group">
                            <label className="settings-item__label">Profile Picture</label>
                            <p className="settings-item__descripton">Min. 200x200 px, PNG or JPG</p>
                        </div>
                        <div className="settings-item__options">
                            <UploadPhoto
                                onChange={(file) => setValue("photo", file)}
                                value={watch("photo") as string | File}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-body__item-border">
                <div className="tab-body__item">
                    <div className="settings-item-group">
                        <div className="settings-item__label-group">
                            <label className="settings-item__label">Profile Info</label>
                            <p className="settings-item__descripton">Manage your profile information</p>
                        </div>
                        <div className="settings-item__options">
                            <div className="row gx-16 gy-18">
                                <div className="col-12">
                                    <Input
                                        label="Email"
                                        placeholder="Email"
                                        {...register("email")}
                                        error={errors.email?.message}
                                    />
                                </div>
                                <div className="col-12">
                                    <Input
                                        label="Phone Number"
                                        placeholder="Phone Number"
                                        {...register("phone")}
                                        error={errors.phone?.message}
                                    />
                                </div>
                                <div className="col-6">
                                    <Input
                                        label="First Name"
                                        placeholder="First Name"
                                        {...register("firstName")}
                                        error={errors.firstName?.message}
                                    />
                                </div>
                                <div className="col-6">
                                    <Input
                                        label="Last Name"
                                        placeholder="Last Name"
                                        {...register("lastName")}
                                        error={errors.lastName?.message}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-body__item-border">
                <div className="settings-item-group">
                    <div className="settings-item__label-group">
                        <label className="settings-item__label">Role</label>
                        <p className="settings-item__descripton">Manage role in company</p>
                    </div>
                    <div className="settings-item__options">
                        <div className="row gx-16 gy-18">
                            <div className="col-12">
                                <Select
                                    label="Role"
                                    options={professionOptions}
                                    value={watch('profession')}
                                    onChange={(option) => setValue('profession', option.value)}
                                    wrapperClassName="input--xl"
                                />
                            </div>
                            <div className="col-12">
                                <Select
                                    label="Department Location"
                                    value={watch('departmentId')}
                                    options={departmentOptions}
                                    wrapperClassName="input--xl"
                                    onChange={(option) => setValue('departmentId', option.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end w-full">
                <Button
                    loading={isLoading}
                    type="submit"
                >
                    Save Changes
                </Button>
            </div>
        </form>
    );

};

export default Profile;
