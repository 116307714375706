import { ModalProps } from "@/components/modals/Modal";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";
import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import UserData from "./components/UserData";
import { useState } from "react";
import { IUserFormData } from "@/types";
import { useUserCsvData } from "@/hooks/useUserCsvData";
import ModalLoader from "@/components/layout/ModalLoader";

interface NewUserModal extends ModalProps {

}

const completeActionButtons = [
    { 
        text: "Add Project",
        handler: () => { } 
    }, 
    { 
        text: "Add Task",
        handler: () => { } 
    }
];

const NewUserModal = ({ ...props }: NewUserModal) => {
    const [data, setData] = useState<IUserFormData | undefined>();

    const { currentStage,
        handleCompleteClick,
        handleContinueClick,
        handleCancel,
        handleGoToSelection
    } = useNewEntityModal();

    const {isProcessing, getUserDataFromCsv } = useUserCsvData()

    const handleParseUser = async (file: File) => {
        try {
            const parsedUser = await getUserDataFromCsv(file);

            setData(parsedUser);
            handleContinueClick();
        } catch(error) {
            console.error(error);
        }
    }

    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New User Created!"
            completeDescription="Congratulations on successfully creating a new user! You can now start managing their project, assign tasks or return to the home page"
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
            handleBackToSelectionClick={handleGoToSelection}
            handleCompleteClick={handleCompleteClick}
            handleParseClick={handleParseUser}
            exampleFileLink="csvUserExample.csv"
        >
            {isProcessing ?
                <ModalLoader />
                : 
                <UserData 
                    data={data} 
                    handleCancel={handleCancel} 
                    handleCompleteClick={handleCompleteClick} 
                />
            }
        </NewEntityModal>
    );

};

export default NewUserModal;
