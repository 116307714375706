import { AsyncVoidFunctionWithFileArgument, VoidFunctionWithoutArguments } from "@/types";
import EntityFooter from "./EntityFooter";
import CloseIcon from "@/assets/img/close-icon.svg?react";
import DragFileInput from "@/components/common/DragFileInput";
import Divider from "@/components/common/Divider";
import { useCallback, useMemo, useState } from "react";
import DocumentsTable from "@/components/common/DocumentsTable";
import { CsvDataTypes } from "@/types/enums";

interface UploadCsvProps {
    title?: string;
    exampleFileLink?: string;
    handleGoBack: VoidFunctionWithoutArguments;
    handleCancel: VoidFunctionWithoutArguments;
    handleCompleteClick: AsyncVoidFunctionWithFileArgument;
    parserType?: CsvDataTypes;
}

const UploadCsv: React.FC<UploadCsvProps> = ({
    title = 'Upload CSV file',
    exampleFileLink = '@/assets/csvUserExample.csv',
    handleGoBack,
    handleCancel,
    handleCompleteClick,
}) => {
    const [files, setFiles] = useState<File[] | null>(null);

    const handleUpload = useCallback((uploadedFiles: File[]) => {
        setFiles(uploadedFiles)
    }, []);

    const getDataForTable = useCallback((uploadedFile: File) => {
        return [{
            id: 1,
            name: uploadedFile.name
        }]
    }, []);

    const parseFileAndHandleComplete = async () => {
        if (!files || files.length < 1) {
            return;
        }

        handleCompleteClick(files[0]);
    }

    const actions = () => useMemo(() => [{
        label: "Delete",
        icon: <CloseIcon />,
        fn: () => setFiles(null)
    }], []);
    
    return (
        <div>
            <div className="modal__header">
                <h4 className="heading flex-auto">{title}</h4>
                <div className="modal__header-actions">
                    <button type="button" onClick={handleCancel} className="btn btn--custom-1 btn--md rounded-full btn--square">
                        <span className="ico"><CloseIcon /></span>
                    </button>
                </div>
            </div>

            <div className="p-4">
                <DragFileInput 
                    isMultiple={false}
                    externalHandler={handleUpload}
                />

                {files && 
                    <DocumentsTable 
                        data={getDataForTable(files[0])} 
                        actions={actions}
                    />
                }

                <Divider />

                <p className="text-400">
                    Please use
                    &nbsp;
                    <a 
                        href={exampleFileLink} 
                        download={'example.csv'} 
                        className="tdu color-secondary"
                    >
                        Example File
                    </a>
                    &nbsp;
                    to upload proper CSV file.
                </p>
            </div>

            <EntityFooter
                isLoading={false}
                handleGoBack={handleGoBack}
                handleCancel={() => {}}
                onSubmit={parseFileAndHandleComplete}
                completeText="Complete"
                nextStepText="Next Step"
                isFirstStep={false}
                isFinalStep={true}
            />
        </div>
    )
}

export default UploadCsv
