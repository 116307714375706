import EditIcon from "@/assets/img/ico-edit.svg?react";
import UserIcon from "@/assets/img/ico-user.svg?react";
import projectImage from "@/assets/img/project-img-1.jpg";
import DropdownActions from "@/components/common/DropdownActions";
import { routerConfig } from "@/routes";
import { getFullUrl } from "@/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router";

import Table from "@/components/common/Table";

interface BudgetProps {
	isGirdView: boolean
	isTableView: boolean
}

export interface Project {
	project: string;
	client: string;
	totalBudget: number;
	spentToDate: number;
	remainingBudget: number;
	paymentsReceived: number;
	outstandingPayments: number;
	budgetHealth: string;
	actions?: string;
}


const Budget = ({ isGirdView, isTableView }: BudgetProps) => {

    const data: Project[] = [{
        project: "Modern Kitchen Remodel",
        client: "John Doe",
        totalBudget: 50000,
        spentToDate: 32000,
        remainingBudget: 30000,
        paymentsReceived: 2000,
        outstandingPayments: 2000,
        budgetHealth: "On Track",
        actions: "Actions Dropdown"
    }];

    if (isGirdView) {

        return (
            <div className="row gutters-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">
                    <div className="card-widget card-widget-product w-full h-full">
                        <div className="card-widget-body">
                            <div className="card-details-wrpr">
                                <div className="card-details--item">
                                    <div className="card-details--head">
                                        <div className="user-option-block user-option-block--sm">
                                            <div className="user-block-relatives">
                                                <div className="ico ico--lg user-block-img-wrpr">
                                                    <img src={projectImage} alt="project" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-details-actions">
                                            <DropdownActions actions={[]} />
                                        </div>
                                    </div>
                                    <div className="card-details--body">
                                        <div className="card-details-heading">
                                            <p className="heading user-block-title text--xl">
                                                Modern Kitchen Remodel
                                            </p>
                                        </div>

                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <UserIcon />
                                                    </span>
                                                    <p className="project-variable--text">Client Title</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-statuses">
                                                    <div className="pill-custom pill--custom-1 pill--sm"><span>On Track</span></div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="card-details--item">
                                    <div className="card-details--body">
                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Total Budget ($):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">50,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Spent to Date ($):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">32,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Remaining Budget ($):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">18,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Payments Received ($):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">30,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Outstanding Payments ($):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">2,000</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="card-details--actions">
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span>Show More</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    const columns: ColumnDef<Project>[] = [{
        header: "Project",
        accessorKey: "project",
        minSize: 228,
        cell: ({ row: { original } }) => <div className="table-user-info-texts">
            <a href="#" className="btn btn-link color-secondary tdu">
                <span className="text--md font-400">{original.project}</span>
            </a>
        </div>

    },
    {
        header: "Client",
        accessorKey: "client",
        minSize: 150,
        cell: ({ row: { original } }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{original.client}</span>
        </a>

    },
    {
        header: "Total Budget ($)",
        accessorKey: "totalBudget",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.totalBudget}</p>

    },
    {
        header: "Spent to Date ($)",
        accessorKey: "spentToDate",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.spentToDate}</p>

    },
    {
        header: "Remaining Budget ($)",
        accessorKey: "remainingBudget",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.remainingBudget}</p>

    },
    {
        header: "Payments Received ($)",
        accessorKey: "paymentsReceived",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.paymentsReceived}</p>

    },
    {
        header: "Outstanding Payments ($)",
        accessorKey: "outstandingPayments",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.outstandingPayments}</p>

    },
    {
        header: "Budget Health",
        accessorKey: "budgetHealth",
        minSize: 180,
        cell: ({ row: { original } }) => <div className="pill-custom pill--custom-1 pill--sm">
            <span>{original.budgetHealth}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        cell: () => <DropdownActions variant="secondary" actions={[]} />

    }];


    return <Table columns={columns} data={data} selectable />;

};

export default Budget;
