
import DatepickerOutline from "@/components/common/DatepickerOutline";
import Input from "@/components/common/Input";
import RadioGroup from "@/components/common/RadioGroup";
import Select from "@/components/common/Select";
import UploadPhoto from "@/components/common/UploadPhoto";
import { clientTypeOptions, departmentOptions, projectTypeOptions, statusOptions } from "@/constants/options";
import { ProjectFormRequest } from "@/validation/projectSchema";
import { UseFormReturn } from "react-hook-form";


interface ProjectDetailsProps {
    formState: UseFormReturn<ProjectFormRequest>
}

const ProjectDetails = ({ formState }: ProjectDetailsProps) => {

    const { register, watch, setValue, formState: { errors, defaultValues } } = formState;

    const handleprojectTypeChange = (option: { label: string; value: string }) => {

        setValue("type", option.value as ProjectFormRequest["type"]);

    };
    const handleDepartamentChange = (option: { label: string; value: number }) => {

        setValue("departmentId", option.value as ProjectFormRequest["departmentId"]);

    };
    const handleStatusChange = (value: string) => {

        setValue("status", value as ProjectFormRequest["status"]);

    };

    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Photo</span>
                    </div>
                    <div className="col-8">
                        <UploadPhoto
                            onChange={(file) => setValue("cover", file as string | File)}
                            value={watch("cover") as string | File}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Project Type</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={projectTypeOptions}
                            onChange={handleprojectTypeChange}
                            value={watch("type")}
                            error={errors.type?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Project Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("name")}
                            placeholder="Enter project name..."
                            error={errors.name?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Deadline</span>
                    </div>
                    <div className="col-8">
                        <DatepickerOutline
                            wrapperClassName="w-full"
                            onChange={(date) => setValue("deadline", date.toISOString())}
                            selectedDate={watch('deadline')}
                            error={errors.deadline?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Estimated Project Volume</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("estVolume")}
                            placeholder="$150,000"
                            error={errors.estVolume?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Department</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={departmentOptions}
                            onChange={handleDepartamentChange}
                            value={watch("departmentId")}
                            error={errors.departmentId?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Status</span>
                    </div>
                    <div className="col-8">
                        <RadioGroup
                            options={statusOptions}
                            onChange={handleStatusChange}
                            value={watch("status")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ProjectDetails;
