import {  Status} from "@/types/enums";
import * as yup from "yup";

export const supplierSchema = yup.object().shape({
    photo: yup.mixed().optional(),
    supplierName: yup.string().required(),
    type: yup.string().required(),
    status: yup.string().oneOf(Object.values(Status)).required("Status is required"),
    contactName: yup.string().required(),
    address: yup.string().required(),
    phone: yup.string().required(),
    phoneCountry: yup.string().required(),
    email: yup.string().required(),
    website: yup.string().required(),
    materials: yup.array().of(yup.string()).optional(),
    orders: yup.array().of(yup.string()).optional(),
});

export type SupplierSchemaRequest = yup.InferType<typeof supplierSchema>;