
const BoardSteps = () => {

    return (
        <div className="main-boards-wrpr main-boards-wrpr--list">
            <ul className="main-board-list-wrpr">
                <li className="main-board-list-item --status-1 --finished">
                    <a href="#" className="btn main-board--btn">
                        <div className="board-content">
                            <p className="board-heading-text">Customer Agreement</p>
                            <div className="ico ico-board"></div>
                        </div>
                    </a>
                    <div className="main-board--btn--info">
                        <p className="main-board--btn--date">01.02.2024</p>
                    </div>
                </li>
                <li className="main-board-list-item --status-2 --finished">
                    <a href="#" className="btn main-board--btn">
                        <div className="board-content">
                            <p className="board-heading-text">Design Proposals</p>
                            <div className="ico ico-board"></div>
                        </div>
                    </a>
                    <div className="main-board--btn--info">
                        <p className="main-board--btn--date">01.02.2024</p>
                    </div>
                </li>
                <li className="main-board-list-item --status-3 --finished">
                    <a href="#" className="btn main-board--btn">
                        <div className="board-content">
                            <p className="board-heading-text">Project Development</p>
                            <div className="ico ico-board"></div>
                        </div>
                    </a>
                    <div className="main-board--btn--info">
                        <p className="main-board--btn--date">01.02.2024</p>
                    </div>
                </li>
                <li className="main-board-list-item --status-4 --active">
                    <a href="#" className="btn main-board--btn">
                        <div className="board-content">
                            <p className="board-heading-text">Permit Expedition</p>
                            <div className="ico ico-board"></div>
                        </div>
                    </a>
                    <div className="main-board--btn--info">
                        <p className="main-board--btn--date">Ongoing...</p>
                    </div>
                </li>
                <li className="main-board-list-item --status-5">
                    <a href="#" className="btn main-board--btn">
                        <div className="board-content">
                            <p className="board-heading-text">Building Rough-in</p>
                            <div className="ico ico-board"></div>
                        </div>
                    </a>
                </li>
                <li className="main-board-list-item --status-6">
                    <a href="#" className="btn main-board--btn">
                        <div className="board-content">
                            <p className="board-heading-text">Building Finishes</p>
                            <div className="ico ico-board"></div>
                        </div>
                    </a>
                </li>
                <li className="main-board-list-item --status-7">
                    <a href="#" className="btn main-board--btn">
                        <div className="board-content">
                            <p className="board-heading-text">Building Punch Out</p>
                            <div className="ico ico-board"></div>
                        </div>
                    </a>
                </li>
                <li className="main-board-list-item --status-8">
                    <a href="#" className="btn main-board--btn">
                        <div className="board-content">
                            <p className="board-heading-text">Completed</p>
                            <div className="ico ico-board"></div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    );

};

export default BoardSteps;
