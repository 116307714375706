import { PaginationQuery } from "@/types/request";
import { objectToFormData, objectToQueryString } from "@/utils";
import { TeamFormRequest } from "@/validation/teamSchema";
import type { ResponseType, Team, User } from "../types";
import { baseApi } from "./baseApi";


const base = "api/teams";

export const teamsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getTeamById: builder.query<
            ResponseType<Team>,
            { teamId: number }
        >({
            query: ({ teamId }) => `${base}/${teamId}`,
        }),
        getAllTeams: builder.query<
            ResponseType<Team[]>,
            PaginationQuery | void
        >({
            query: (query) => `${base}?${objectToQueryString(query)}`,
            providesTags: ["teams"]
        }),
        updateTeam: builder.mutation<
            ResponseType<User>,
            { teamId: number, body: TeamFormRequest }
        >({
            query: ({ teamId, body }) => ({
                url: `${base}/${teamId}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["teams"]
        }),
        createTeam: builder.mutation<
            ResponseType<User>,
            TeamFormRequest
        >({
            query: (body) => ({
                url: `${base}/store`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["teams"]
        })
    })
});

export const {
    useCreateTeamMutation,
    useGetAllTeamsQuery,
    useGetTeamByIdQuery,
    useUpdateTeamMutation
} = teamsApi;
