import { useLazyGetAllProfessionsQuery } from "@/api/professionsApi"
import { departmentOptions, skillsOptions } from "@/constants/options";
import { csvService } from "@/services/csvService";
import { ISelecOption, IUserFormData } from "@/types";
import { Status } from "@/types/enums";
import { checkAreTextsEqual, checkAreNamesEqual } from "@/utils";
import { useState } from "react";

export const useUserCsvData = () => {
    const [getProfessions] = useLazyGetAllProfessionsQuery();
    const getDepartments = (): Promise<ISelecOption<number>[]> => new Promise(
        (resolve) => resolve(departmentOptions)); //TODO: Implement the api query after implementation on backend.

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const getUserDataFromCsv = async (file: File) => {
        try {
            setIsProcessing(true);

            const professionsResponse = await getProfessions().unwrap();
            const professions = professionsResponse.data;
    
            const departments = await getDepartments();

            const userDataRows = await csvService.userParser(file);

            if (userDataRows.length === 0) {
                return null;
            }

            const userParsedData = userDataRows[0];

            const departmentId = departments.find(department => checkAreNamesEqual(department.label, 
                userParsedData.departmentName))?.value;
            
            const professionId = professions.find(profession => checkAreNamesEqual(profession.name, 
                userParsedData.profession))?.id;
            
            const status = csvService.getStatusValue(userParsedData.status);

            const skills = userParsedData.personalSkills.split('/');

            const skillsSet = new Set<string>();

            skills.forEach(skill => {
                const skillId = skillsOptions
                    .find(skillOption => checkAreTextsEqual(skillOption.label, skill))?.value;
                
                if (skillId) {
                    skillsSet.add(skillId);
                }
            })

            const parsedUser: IUserFormData = {
                departmentId: departmentId,
                email: userParsedData.email || '',
                firstName: userParsedData.firstName || '',
                lastName: userParsedData.lastName || '',
                phone: userParsedData.phone || '',
                photo: userParsedData.photo || '',
                profession: professionId ? userParsedData.profession : '',
                skills: Array.from(skillsSet) as string[],
                status: status as Status,
            }

            return parsedUser;
        } catch(error) {
            console.error(error);
        } finally {
            setIsProcessing(false);
        }
    }

    return {
        isProcessing,
        getUserDataFromCsv,
    }
}