import Modal, { ModalProps } from "../Modal";
import { ActionButton, AsyncVoidFunctionWithFileArgument, ReactChildren, VoidFunctionWithoutArguments } from "@/types";
import clsx from "clsx";
import CreationComplete from "./CreationComplete";
import OptionSelection from "./OptionSelection";
import { EntityCreationStages } from "@/types/enums";
import UploadCsv from "./UploadCsv";

interface NewEntityModal {
    props: ModalProps;
    children: ReactChildren;
    currentStage: EntityCreationStages;
    completeActionButtons: ActionButton[];
    completeTitle: string;
    completeDescription: string;
    exampleFileLink?: string;
    handleContinueClick: VoidFunctionWithoutArguments;
    handleCancel: VoidFunctionWithoutArguments;
    handleBackToSelectionClick?: VoidFunctionWithoutArguments;
    handleCompleteClick?: VoidFunctionWithoutArguments;
    handleParseClick?: AsyncVoidFunctionWithFileArgument;
}

const NewEntityModal: React.FC<NewEntityModal> = ({
    children,
    currentStage,
    completeActionButtons,
    completeTitle,
    completeDescription,
    exampleFileLink,
    handleCancel,
    handleContinueClick,
    handleBackToSelectionClick = () => {},
    handleCompleteClick = () => {},
    handleParseClick,
    props
}) => {

    return (
        <Modal {...props}
            closeOnClickOutside={currentStage !== EntityCreationStages.FillData}
            dialogClassName={clsx({
                "modal__dialog--390": currentStage === EntityCreationStages.SelectOption,
                "modal__dialog--480": currentStage === EntityCreationStages.Complete,
                "modal__dialog--700": currentStage === EntityCreationStages.FillData || currentStage === EntityCreationStages.UploadCsv,
            })}
            contentClassName={"modal--rounded"}
        >
            {currentStage === EntityCreationStages.SelectOption && <OptionSelection handleCancel={handleCancel} handleContinueClick={handleContinueClick} />}

            {currentStage === EntityCreationStages.FillData && children}

            {currentStage === EntityCreationStages.UploadCsv && 
                <UploadCsv 
                    handleGoBack={handleBackToSelectionClick} 
                    handleCancel={handleCancel}
                    handleCompleteClick={handleParseClick}
                    exampleFileLink={exampleFileLink}
                />
            }

            {currentStage === EntityCreationStages.Complete &&
                <CreationComplete
                    title={completeTitle}
                    description={completeDescription}
                    actionButtons={completeActionButtons}
                    handleCancelClick={handleCancel}
                />
            }
        </Modal>
    );

};

export default NewEntityModal;
