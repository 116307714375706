import Papa from "papaparse";
import { IAddressFormData, IClientCsvFileData, IProjectCsvData, ITeamCsvData, IUserCsvFileData } from "@/types";
import { CsvDataTypes, Status } from "@/types/enums";
import { City, Country, ICity, IState, State } from "country-state-city";
import { checkAreNamesSimilar, checkAreTextsEqual } from "@/utils";

const userMapping: { [key: string]: keyof IUserCsvFileData } = {
    "Address": "address",
    "Department": "departmentName",
    "Email": "email",
    "First Name": "firstName",
    "Last Name": "lastName",
    "Skills": "personalSkills",
    "Phone": "phone",
    "Photo": "photo",
    "Profession": "profession",
    "Role": "role",
    "Status": "status",
};

const clientMapping: { [key: string]: keyof IClientCsvFileData } = {
    "Photo": "photo",
    "Client type": "clientType",
    "Risk level": "riskLevel",
    "Full name": "fullName",
    "Company name": "companyName",
    "Insurance": "insurance",
    "Status": "status",
    "Email": "email",
    "Phone": "phone",
    "Contact method": "contactMethod",
    "Country": "country",
    "State": "state",
    "City": "city",
    "Street": "street",
    "Building": "building",
    "Zip code": "zip",
    "Unit": "unit",
    "Account manager": "accountManager",
}

const teamMapping: { [key: string]: keyof ITeamCsvData } = {
    "Icon": "icon",
    "Team name": "name",
    "Team lead": "teamLead",
    "Department": "departmentName",
    "Status": "status",
    "Members": "members",
}

const projectMapping: { [key: string]: keyof IProjectCsvData } = {
    "Cover": "cover",
    "Project type": "type",
    "Project name": "name",
    "Deadline": "deadline",
    "Estimated project volume": "estVolume",
    "Department": "departmentName",
    "Status": "status",
    "Description": "description",
    "Country": "country",
    "State": "state",
    "City": "city",
    "Street": "street",
    "Building": "building",
    "Zip code": "zip",
    "Unit": "unit",
    "Photos": "photos"
}


const csvToJson = <T>(file: File, dataMapper: Record<string, keyof T>): Promise<Partial<T>[]> => {
    return new Promise ((resolve, reject) => {
        Papa.parse(file, {
            header: true,
            complete: (results: any) => {
                const rawData:Record<string, string>[] = results.data;
        
                const data = rawData.map((row: Record<string, string>) => {
                const transformedRow: Partial<T> = {};

                for (const [csvField, objectField] of Object.entries(dataMapper)) {
                    transformedRow[objectField as string] = row[csvField];
                }

                return transformedRow;
                });

                resolve(data);
            },
            error: (error: Record<string, string>) => {
                reject(error);
            },
        });
    });
};

const userParser = async (file: File) => {
    const data = await csvToJson<IUserCsvFileData>(file, userMapping);

    return data;
}

const clientParser = async (file: File) => {
    const data = await csvToJson<IClientCsvFileData>(file, clientMapping);
    
    return data;
}

const teamParser = async (file: File) => {
    const data = await csvToJson<ITeamCsvData>(file, teamMapping);

    return data;
}

const projectParser = async (file: File) => {
    const data = await csvToJson<IProjectCsvData>(file, projectMapping);

    return data;
}

const selectParser = (dataType: CsvDataTypes): (file: File) => Promise<Record<string, string>[]> => {
    switch(dataType) {
        case CsvDataTypes.User:
            return userParser;
        default:
            return userParser;
    }
}

const getAddressFormDataFromParsedData = (
    street: string, 
    building: string, 
    zip: string, 
    unit: string, 
    country: string, 
    state: string, 
    city: string
): IAddressFormData => {
    const targetCountry = Country.getAllCountries().find(curCountry => checkAreNamesSimilar(
        curCountry.name, country));

    let targetState: IState | undefined;
    let targetCity: ICity | undefined;

    const address: IAddressFormData = {
        street,
        building,
        zip,
        unit,
        country: '',
        state: '',
        city: '',
    }

    if (targetCountry) {
        address.country = targetCountry.isoCode;

        const states = State.getStatesOfCountry(targetCountry.isoCode);
        
        targetState = states.find(curState => checkAreNamesSimilar(curState.name, state));
        
        if (state) {
            address.state = targetState.isoCode;

            const cities = City.getCitiesOfState(targetState.countryCode, targetState.isoCode);

            targetCity = cities.find(curCity => checkAreNamesSimilar(curCity.name, city));
            
            if (city) {
                address.city = targetCity.name;
            }
        }
    }

    return address;
}

const getStatusValue = (parsedStatus: string) => {
    let status = parsedStatus.toLocaleLowerCase();
    
    const isValidStatus = Object.values(Status).some((value) => checkAreTextsEqual(value, status));

    if (!isValidStatus) {
        status = 'active';
    }

    return status;
}

export const csvService = {
    csvToJson,
    selectParser,
    userParser,
    clientParser,
    teamParser,
    projectParser,
    getAddressFormDataFromParsedData,
    getStatusValue
}