import FullCalendar from "@fullcalendar/react";
import { CalendarApi } from "@fullcalendar/core";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import NextIcon from "@/assets/img/ico-next.svg?react";
import PrevIcon from "@/assets/img/ico-prev.svg?react";
import DropdownActions from "../common/DropdownActions";
import DotsIcon from "@/assets/img/ico-dots.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import FileIcon from "@/assets/img/File-Text--Streamline-Lucide.svg?react";
import CloudIcon from "@/assets/img/Message--Streamline-Tabler.svg?react";
import userImg from "@/assets/img/img-user-1.png";
import clsx from "clsx";
import { useEffect, useId, useRef, useState } from "react";
import CircleProgressIcon from "../common/CircleProgressIcon";

interface ExtendedProps {
    priority: string
    due: string
    completed: number
    total: number
}

interface Event {
    id: string;
    title: string;
    start: string;
    end?: string;
    extendedProps: ExtendedProps;
}

type Direction = "left" | "right"

const month = new Date().getMonth() + 1;
const events: Event[] = [{
    id: "1",
    start: `2024-${String(month)}-20T09:00:00`,
    end: `2024-${String(month)}-24T11:00:00`,
    title: "Install base cabinets",
    extendedProps: {
        priority: "High",
        due: "Nov 10, 2024",
        completed: 12,
        total: 12
    }
},
{
    id: "2",
    start: `2024-${String(month)}-20T10:00:00`,
    end: `2024-${String(month)}-25T12:00:00`,
    title: "Install base cabinets",
    extendedProps: {
        priority: "Low",
        due: "Nov 10, 2024",
        completed: 4,
        total: 12
    }
},
{
    id: "3",
    start: `2024-${String(month)}-20T11:00:00`,
    end: `2024-${String(month)}-24T13:00:00`,
    title: "Install base cabinets",
    extendedProps: {
        priority: "Medium",
        due: "Nov 10, 2024",
        completed: 0,
        total: 12
    }
},
{
    id: "4",
    start: `2024-${String(month)}-01T11:00:00`,
    end: `2024-${String(month)}-05T13:00:00`,
    title: "Install base cabinets",
    extendedProps: {
        priority: "Medium",
        due: "Nov 10, 2024",
        completed: 0,
        total: 12
    }
},
{
    id: "5",
    start: `2024-${String(month)}-01T11:00:00`,
    end: `2024-${String(month)}-03T13:00:00`,
    title: "Install base cabinets",
    extendedProps: {
        priority: "Medium",
        due: "Nov 10, 2024",
        completed: 0,
        total: 12
    }
},
{
    id: "6",
    start: `2024-${String(month)}-01T11:00:00`,
    end: `2024-${String(month)}-07T13:00:00`,
    title: "Install base cabinets",
    extendedProps: {
        priority: "Medium",
        due: "Nov 10, 2024",
        completed: 0,
        total: 12
    }
},
{
    id: "7",
    start: `2024-${String(month)}-11T11:00:00`,
    end: `2024-${String(month)}-16T13:00:00`,
    title: "Install base cabinets",
    extendedProps: {
        priority: "Medium",
        due: "Nov 10, 2024",
        completed: 0,
        total: 12
    }
}];


const ScheduleTimeline = () => {

    const id = useId();
    const calendarId = `timeline-${id}`;
    const calendarRef = useRef<FullCalendar>(null);
    const [calendarApi, setCalendarApi] = useState<CalendarApi | null>(null);

    const scrollBlockByWidth = (direction: Direction) => {

        const element = document.getElementById(calendarId);

        if (!element) return;
        const scrollableArea = element.querySelector(".fc-scroller") as HTMLDivElement;
        if (!scrollableArea) {

            console.error("fc-scroller is not found");
            return;

        }

        const blockWidth = scrollableArea.offsetWidth;

        scrollableArea.scrollBy({
            left: direction === "right"
                ? blockWidth
                : -blockWidth,
            behavior: "smooth"
        });

    };
    const handleScroll = (direction: Direction) => {

        scrollBlockByWidth(direction);

    };

    useEffect(() => {

        if (calendarApi) {

            calendarApi.scrollToTime(new Date().getTime());

        }

    }, [calendarApi]);

    useEffect(() => {

        const calendarApi = calendarRef.current?.getApi();
        if (calendarApi) {

            setCalendarApi(calendarApi);

        }

    }, [calendarRef]);

    return (
        <div className="relative calendar-timeline" id={calendarId}>
            <button onClick={() => handleScroll("left")} className="btn btn--custom-1 btn--square btn--xs rounded-full scroll-btn scroll-left">
                <span className="ico">
                    <PrevIcon />
                </span>
            </button>
            <button onClick={() => handleScroll("right")} className="btn btn--custom-1 btn--square btn--xs rounded-full scroll-btn scroll-right">
                <span className="ico">
                    <NextIcon />
                </span>
            </button>
            <FullCalendar
                ref={calendarRef}
                initialView="timelineMonth"
                scrollTime={new Date().getTime()}
                headerToolbar={false}
                initialDate={new Date()}
                slotLabelInterval={{
                    days: 1
                }}
                slotLabelFormat={{
                    weekday: "short",
                    day: "numeric"
                }}
                slotLabelContent={(arg) => <SlotLabel text={arg.text} />}
                editable
                droppable
                events={events}
                eventContent={(arg) => <EventContent title={arg.event.title} {...arg.event.extendedProps as ExtendedProps} />}
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                plugins={[resourceTimelinePlugin, interactionPlugin]}

            />
        </div>
    );

};

interface EventContentProps extends ExtendedProps {
    title: string
}
function EventContent ({ priority, title, completed, due, total }: EventContentProps) {

    return (
        <div className="timeline-event-content">
            <div className="card-widget card-widget-task">
                <div className="card-widget-body">
                    <div className="card-details-wrpr">
                        <div className="card-details--item">
                            <div className="card-details--head items-center">
                                <div className={clsx("pill-custom pill--xs pill--custom-9", {
                                    "pill--custom-11": priority === "Low",
                                    "pill--custom-10": priority === "Medium"
                                })}>
                                    <span>{priority}  Priority</span>
                                </div>
                                <DropdownActions
                                    button={(
                                        <button type="button" className="btn btn--square btn--xs  btn--custom-1-trsp rounded-full max-w-max dropdown-btn dropdown-btn--burger">
                                            <span className="ico"><DotsIcon /></span>
                                        </button>
                                    )}
                                    actions={[{
                                        icon: <EditIcon />,
                                        label: "Edit",
                                        fn: () => console.log("Edit")
                                    }]}
                                />
                            </div>
                            <div className="card-details--body">
                                <div className="user-block-content-info">
                                    <div className="user-block-titled-info">
                                        <p className="heading user-block-title text--lg">
                                            {title}
                                        </p>
                                    </div>
                                    <div className="user-block-desk-info">
                                        <p className="user-role--date">
                                            <span className="date-variable-text">Due:</span>
                                            <span>{due}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-details--footer">
                                <ul className="details-list-info">
                                    <li className="details-list--item">
                                        <CircleProgressIcon progress={completed} total={total} />
                                    </li>
                                    <li className="details-list--item">
                                        <div className="detail-text-wrpr">
                                            <span className="ico detail-text--ico">
                                                <FileIcon />
                                            </span>
                                            <span className="detail-text-values">5</span>
                                        </div>
                                    </li>
                                    <li className="details-list--item">
                                        <div className="detail-text-wrpr">
                                            <span className="ico detail-text--ico">
                                                <CloudIcon />
                                            </span>
                                            <span className="detail-text-values">5</span>
                                        </div>
                                    </li>
                                </ul>
                                <div className="user-list-wrpr">
                                    <ul className="user-list">
                                        <li className="user-item">
                                            <img src={userImg} alt="user" />
                                        </li>
                                        <li className="user-item">
                                            <img src={userImg} alt="user" />
                                        </li>
                                        <li className="user-item">
                                            <img src={userImg} alt="user" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

function SlotLabel ({ text }: { text: string }) {

    return (
        <div className="slot-label-content">
            {text.split(" ").map((item, index) => <span key={index}>{item}</span>)}
        </div>
    );

}

export default ScheduleTimeline;
