import { useSearchParams } from "react-router";

export const useQueryParams = () => {

    const [params] = useSearchParams();

    const getQueryParams = () => {

        const queryParams: { [key: string]: string | null } = {};
        params.forEach((value, key) => {

            queryParams[key] = value;

        });
        return queryParams;

    };

    return {
        ...getQueryParams()
    };

};
