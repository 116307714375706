import { Breadcrumb } from "@/redux/appSlice";
import { useEffect } from "react";
import useDispatchAction from "./redux/dispatchHooks/useDispatchAction";

const useBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {

    const { setBreadcrumbsData, clearBreadcrumbsData } = useDispatchAction();

    useEffect(() => {
        setBreadcrumbsData(breadcrumbs);
        return () => {

            clearBreadcrumbsData();

        };

    }, [breadcrumbs]);

};

export default useBreadcrumbs;
