import { AsynkPaginationQuery, PaginationQuery } from "@/types/request";
import { objectToQueryString } from "@/utils";
import type { Client, Profession, ResponseType } from "../types";
import { baseApi } from "./baseApi";


const base = "api/professions";

export const professionsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllProfessions: builder.query<
            ResponseType<Profession[]>,
            AsynkPaginationQuery | void
        >({
            query: (query) => `${base}?${objectToQueryString(query)}`,
            providesTags: ["professions"]
        }),

    })
});

export const {
    useLazyGetAllProfessionsQuery,
    useGetAllProfessionsQuery,
} = professionsApi;
