import { ModalProps } from "@/components/modals/Modal";
import ClientData from "./components/ClientData";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";

import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import { IClientFormData } from "@/types";
import { useClientCsvData } from "@/hooks/useClientCsvData";
import { useState } from "react";
import ModalLoader from "@/components/layout/ModalLoader";

interface NewClientModal extends ModalProps {}

const completeActionButtons = [{
    text: "Add Project",
    handler: () => { }
}, {
    text: "Add Note",
    handler: () => { }
}];

const NewClientModal = ({...props }: NewClientModal) => {
    const [data, setData] = useState<IClientFormData | undefined>();

    const { currentStage,
        handleCompleteClick,
        handleContinueClick,
        handleCancel,
        handleGoToSelection
    } = useNewEntityModal();

    const {isProcessing, getClientDataFromCsv } = useClientCsvData()

    const handleParseClient = async (file: File) => {
        try {
            const parsedClient = await getClientDataFromCsv(file);

            setData(parsedClient);
            handleContinueClick();
        } catch(error) {
            console.error(error);
        }
    }
    
    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New Client Created!"
            completeDescription="Congratulations on successfully creating a new client! You can now start managing their project or return to the home page."
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
            handleBackToSelectionClick={handleGoToSelection}
            handleCompleteClick={handleCompleteClick}
            handleParseClick={handleParseClient}
            exampleFileLink="csvClientExample.csv"
        >
            {isProcessing 
                ? <ModalLoader /> 
                : <ClientData 
                    data={data} 
                    handleCancel={handleCancel} 
                    handleCompleteClick={handleCompleteClick} 
                />
        }
        </NewEntityModal>
    );
};

export default NewClientModal;
