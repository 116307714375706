import AppLayout from "@/layouts/AppLayout";
import MainLayout from "@/layouts/MainLayout";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import AccountSettingsPage from "@/pages/AccountSettingsPage";
import BudgetBillingPage from "@/pages/BudgetBillingPage";
import ClientPage from "@/pages/ClientPage";
import ClientsPage from "@/pages/ClientsPage";
import DesignsProposalsPage from "@/pages/DesignsProposalsPage";
import HomePage from "@/pages/HomePage";
import LoginPage from "@/pages/LoginPage";
import NotFound from "@/pages/NotFound ";
import ProjectPage from "@/pages/ProjectPage";
import ProjectsPage from "@/pages/ProjectsPage";
import ReportsPage from "@/pages/ReportsPage";
import SchedulingPage from "@/pages/SchedulingPage";
import SuppliersInventoryPage from "@/pages/SuppliersInventoryPage";
import TeamManagementPage from "@/pages/TeamManagementPage";
import TeamPage from "@/pages/TeamPage";
import UserPage from "@/pages/UserPage";
import UsersPage from "@/pages/UsersPage";
import {getFullUrl} from "@/utils";
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router";

export const routerConfig = {
    login: {
        path: "/",
        name: "Login",
        types: ["public"]
    },
    dashboard: {
        path: "/dashboard",
        name: "Dashboard",
        types: ["sidebar"]
    },
    projects: {
        path: "/projects",
        name: "Projects",
        types: ["sidebar"]
    },
    clients: {
        path: "/clients",
        name: "Clients",
        types: ["sidebar"]
    },
    designs: {
        path: "/designs",
        name: "Designs & Proposals",
        types: ["sidebar"]
    },
    scheduling: {
        path: "/scheduling",
        name: "Scheduling",
        types: ["sidebar"]
    },
    suppliers: {
        path: "/suppliers",
        name: "Suppliers & Inventory",
        types: ["sidebar"]
    },
    budget: {
        path: "/budget",
        name: "Budget & Billing",
        types: ["sidebar"]
    },
    team: {
        path: "/team",
        name: "Team Management",
        types: ["sidebar"]
    },
    reports: {
        path: "/reports",
        name: "Reports",
        types: ["sidebar"]
    },
    users: {
        path: "/users",
        name: "All Users",
        types: ["sidebar"]
    },
    settings: {
        path: "/settings",
        name: "Account Settings",
        types: ["sidebar"]
    },
    support: {
        path: "/support",
        name: "Help & Support",
        types: ["dropdown"]
    },
    statics: {
        path: "/statistics",
        name: "Statistics",
        types: ["dropdown"]

    },
    profile: {
        path: "/profile",
        name: "My Profile",
        types: ["dropdown"]

    },
    notifications: {
        path: "/notifications",
        name: "Notifications",
        types: []
    }
};


export const router = createBrowserRouter(createRoutesFromElements(<Route element={<AppLayout/>}>
    <Route element={<ProtectedLayout/>}>
        <Route path={"/"} element={<LoginPage/>}/>
        <Route element={<MainLayout/>}>
            <Route path={routerConfig.dashboard.path} element={<HomePage/>}/>
            <Route path={routerConfig.projects.path} element={<ProjectsPage/>}/>
            <Route path={getFullUrl([routerConfig.projects.path, ":projectId"])} element={<ProjectPage/>}/>
            <Route path={routerConfig.clients.path} element={<ClientsPage/>}/>
            <Route path={getFullUrl([routerConfig.clients.path, ":clientId"])} element={<ClientPage/>}/>
            <Route path={routerConfig.designs.path} element={<DesignsProposalsPage/>}/>
            <Route path={routerConfig.scheduling.path} element={<SchedulingPage/>}/>
            <Route path={routerConfig.suppliers.path} element={<SuppliersInventoryPage/>}/>
            <Route path={routerConfig.budget.path} element={<BudgetBillingPage/>}/>
            <Route path={routerConfig.team.path} element={<TeamManagementPage/>}/>
            <Route path={getFullUrl([routerConfig.team.path, ":teamId"])} element={<TeamPage/>}/>
            <Route path={getFullUrl([routerConfig.team.path, ":teamId", ":userId"])} element={<UserPage/>}/>
            <Route path={routerConfig.reports.path} element={<ReportsPage/>}/>
            <Route path={routerConfig.users.path} element={<UsersPage/>}/>
            <Route path={routerConfig.settings.path} element={<AccountSettingsPage/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Route>
    </Route>
</Route>), {
    basename: "/"
});
