import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import enTranslation from './en/translation.json';
import { initializeLanguages } from './languagesLoading';
import { DOMAIN } from '@/constants';

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'en',
        debug: true,
        resources: {
        en: {
            translation: enTranslation,
        },
        },
        fallbackLng: 'en',
        supportedLngs: ['en'],
        returnNull: false,
        backend: {
        loadPath: `${DOMAIN}/translations/{{lng}}`,
        parse: (data: string) => {
            const parsedData = JSON.parse(data);
            if (parsedData.success) {
            return parsedData.data;
            }
            throw new Error('Failed to load translations from API');
        },
        },
        react: {
        useSuspense: true,
        },
        ns: ['translation'],
        defaultNS: 'translation',
    });

initializeLanguages();
