import Filters from "@/components/common/Filters";
import Search from "@/components/common/Search";
import ViewModeSwitcher, { useViewMode } from "@/components/common/ViewModeSwitcher";

import AddIcon from "@/assets/img/ico-add.svg?react";

import DatepickerOutline from "@/components/common/DatepickerOutline";
import TabNavigation from "@/components/common/TabNavigation";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { useTabs } from "@/hooks/useTabs";
import { routerConfig } from "@/routes";
import clsx from "clsx";
import InventoryTab from "./tabs/InventoryTab";
import SuppliesTab from "./tabs/SuppliesTab";
import MaterialOrdersTab from "./tabs/MaterialOrdersTab";
import useModal from "@/hooks/useModal";
import Button from "@/components/common/Button";
import { ModalID } from "@/providers/ModalProvider";


const tabs = [{
    id: "inventory",
    label: "Inventory"
}, {
    id: "suppliers",
    label: "Suppliers"
}, {
    id: "material-orders",
    label: "Material Orders"
}] as const;


const SuppliersInventoryPage = () => {

    useBreadcrumbs([routerConfig.suppliers]);
    const { openModal } = useModal();
    const { activeTab, setActiveTab } = useTabs(tabs);
    const { isTableView, isGridView } = useViewMode();

    return (
        <main className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="rectangle-block-containers block-container-fullpage tabs">
                    <div className="block-container navigation-block">
                        <div className="heading--row">
                            <div className="heading-option">
                                <DatepickerOutline />
                                <span className="option-separator"></span>
                                <TabNavigation
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    renderTabCount={() => 3}
                                />
                            </div>
                            <div className="heading-option">
                                <ViewModeSwitcher />
                                <span className="option-separator"></span>
                                <div className="flex-controls gap--xs">
                                    <Filters />
                                    <Search />
                                    <Button
                                        uiType="primary-advance-1"
                                        onClick={() => {
                                            if(activeTab === "inventory") {
                                                openModal(ModalID.NewMaterial)
                                            }
                                            if(activeTab === "material-orders") {
                                                openModal(ModalID.NewOrder)
                                            }
                                            if(activeTab === "suppliers") {
                                                openModal(ModalID.NewSupplier)
                                            }
                                        }}
                                    >
                                        <span className="ico"><AddIcon /></span>
                                        {activeTab === "inventory" && <span>New Material</span>}
                                        {activeTab === "suppliers" && <span>New Supplier</span>}
                                        {activeTab === "material-orders" && <span>New Order</span>}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-container page-results">
                        <div className="view-page-block tabs-fullpage active-page">
                            <div className={clsx("tab", { "active-tab": activeTab === "inventory" })}>
                                <InventoryTab {...{ isGridView,
                                    isTableView }} />
                            </div>
                            <div className={clsx("tab", { "active-tab": activeTab === "suppliers" })}>
                                <SuppliesTab {...{ isGridView,
                                    isTableView }} />
                            </div>
                            <div className={clsx("tab", { "active-tab": activeTab === "material-orders" })}>
                                <MaterialOrdersTab {...{ isGridView,
                                    isTableView }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

};


export default SuppliersInventoryPage;
