import Input from "@/components/common/Input";
import PhoneInput from "@/components/common/PhoneInput";
import RadioGroup from "@/components/common/RadioGroup";
import { ClientFormRequest } from "@/validation/clientSchema";
import { UserFormRequest } from "@/validation/userSchema";
import React from "react";
import { UseFormReturn } from "react-hook-form";

interface ContactsProps {
    formState: UseFormReturn<UserFormRequest>
}
const Contacts = ({ formState }: ContactsProps) => {

    const { register, watch, setValue, formState: { errors, defaultValues } } = formState;
    const handlePhoneNumberChange = (value: UserFormRequest["phone"]) => {
        setValue("phone", value);

    };
    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Email</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("email")}
                            placeholder="Enter email..."
                            error={errors.email?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Phone Number</span>
                    </div>
                    <div className="col-8">
                        <PhoneInput
                            className="input--md"
                            placeholder="Enter phone number..."
                            value={watch("phone")}
                            onChange={handlePhoneNumberChange}
                            onCountryChange={(value) => setValue('phoneCountry', value)}
                            error={errors.phone?.message}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Contacts;
