import React from "react";

const Notifications = () => {

    return (
        <>
            <div className="tab-body__item-border">
                <div className="tab-body__item">
                    <div className="settings-item-group">
                        <div className="settings-item__label-group">
                            <label className="settings-item__label">Project Management Notifications</label>
                            <p className="settings-item__descripton">All notifications related to project updates, tasks, and assignments</p>
                        </div>
                        <div className="settings-item__options">
                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">AI-generated scheduling updates</span>
                                            <p className="settings-item__descripton">Get notified when the AI updates the project schedule based on new data inputs.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>

                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">Project assignment alerts</span>
                                            <p className="settings-item__descripton">Receive notifications when you are assigned to a new project or your role in a project changes.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>

                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">Milestone completion alerts</span>
                                            <p className="settings-item__descripton">Get notified when key milestones are completed in your projects.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>

                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">AI-based delay detection</span>
                                            <p className="settings-item__descripton">Receive alerts when the AI detects potential delays and suggests adjustments.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-body__item-border">
                <div className="tab-body__item">
                    <div className="settings-item-group">
                        <div className="settings-item__label-group">
                            <label className="settings-item__label">Financial Notifications</label>
                            <p className="settings-item__descripton">Cash flow and budget alerts</p>
                        </div>
                        <div className="settings-item__options">
                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">Cash flow warnings</span>
                                            <p className="settings-item__descripton">Receive alerts when projected cash flow turns negative based on project timelines.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>

                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">Payment schedule reminders</span>
                                            <p className="settings-item__descripton">Get notified when payments are due for a project to avoid delays.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>

                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">Budget threshold alerts</span>
                                            <p className="settings-item__descripton">Receive warnings when a project’s expenses approach or exceed the budget limit.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-body__item-border">
                <div className="tab-body__item">
                    <div className="settings-item-group">
                        <div className="settings-item__label-group">
                            <label className="settings-item__label">Team and Resource Management</label>
                            <p className="settings-item__descripton">Helping users manage tasks and resources effectively</p>
                        </div>
                        <div className="settings-item__options">
                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">Resource overload warnings</span>
                                            <p className="settings-item__descripton">Receive warnings when team members are handling more tasks than their capacity allows.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>

                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">Task assignment notifications</span>
                                            <p className="settings-item__descripton">Get notified when new tasks are assigned to you or team members.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>

                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">Team availability notifications</span>
                                            <p className="settings-item__descripton">Receive alerts when specific team members are available for new tasks or projects.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-body__item-border">
                <div className="tab-body__item">
                    <div className="settings-item-group">
                        <div className="settings-item__label-group">
                            <label className="settings-item__label">Material and Supplier Notifications</label>
                            <p className="settings-item__descripton">Inform users about material orders and supplier delays</p>
                        </div>
                        <div className="settings-item__options">
                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">Material order placement</span>
                                            <p className="settings-item__descripton">Get notified when material orders are placed according to project schedules.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>

                            <div className="switch-control-item">
                                <label className="switch-control-wrapper">
                                    <label className="switch-control gap-20">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                        <div className="switch-text-col">
                                            <span className="settings-item__label">Supplier delays</span>
                                            <p className="settings-item__descripton">Receive alerts when suppliers delay the delivery of materials.</p>
                                        </div>
                                    </label>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-body__item">
                <div className="settings-item-group">
                    <div className="settings-item__label-group">
                        <label className="settings-item__label">General System Notifications</label>
                        <p className="settings-item__descripton">General notifications, including security and system updates</p>
                    </div>
                    <div className="settings-item__options">
                        <div className="switch-control-item">
                            <label className="switch-control-wrapper">
                                <label className="switch-control gap-20">
                                    <input type="checkbox" hidden checked />
                                    <div className="switch-btn">
                                        <div className="switch-point-wrapp">
                                            <span className="switch-point"></span>
                                        </div>
                                    </div>
                                    <div className="switch-text-col">
                                        <span className="settings-item__label">AI recommendations</span>
                                        <p className="settings-item__descripton">Receive new AI recommendations for optimizing project workflows.</p>
                                    </div>
                                </label>
                            </label>
                        </div>

                        <div className="switch-control-item">
                            <label className="switch-control-wrapper">
                                <label className="switch-control gap-20">
                                    <input type="checkbox" hidden checked />
                                    <div className="switch-btn">
                                        <div className="switch-point-wrapp">
                                            <span className="switch-point"></span>
                                        </div>
                                    </div>
                                    <div className="switch-text-col">
                                        <span className="settings-item__label">Security notifications</span>
                                        <p className="settings-item__descripton">Get alerted about important security updates like login attempts or password changes.</p>
                                    </div>
                                </label>
                            </label>
                        </div>

                        <div className="switch-control-item">
                            <label className="switch-control-wrapper">
                                <label className="switch-control gap-20">
                                    <input type="checkbox" hidden checked />
                                    <div className="switch-btn">
                                        <div className="switch-point-wrapp">
                                            <span className="switch-point"></span>
                                        </div>
                                    </div>
                                    <div className="switch-text-col">
                                        <span className="settings-item__label">System performance and updates</span>
                                        <p className="settings-item__descripton">Receive notifications about system updates, new features, and performance improvements.</p>
                                    </div>
                                </label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default Notifications;
