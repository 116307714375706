import clsx from "clsx";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeIcon from "@/assets/img/ico-time.svg?react";

type TimepickerOutlineProps = {
    selectedTime?: Date | null | string;
    onChange?: (time: string) => void;
    timeFormat?: string;
    placeholder?: string;
    className?: string;
    wrapperClassName?: string;
    error?: string | boolean;
};

const TimepickerOutline: React.FC<TimepickerOutlineProps> = ({
                                                                 selectedTime = null,
                                                                 onChange,
                                                                 timeFormat = "hh:mm aa",
                                                                 placeholder = "Select a time",
                                                                 className,
                                                                 wrapperClassName,
                                                                 error
                                                             }) => {
    const timeToDate = (time: string | null | undefined): Date | null => {
        if (!time) return null;
        const [hours, minutes] = time.split(":").map(Number);
        const isPM = time.toLowerCase().includes("pm");
        const date = new Date();

        date.setHours(isPM ? hours + 12 : hours);
        date.setMinutes(minutes || 0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date;
    };
    const dateToTime = (date: Date) => {
        if (!date) return "";
        const hours = date.getHours() % 12 || 12;
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const ampm = date.getHours() >= 12 ? "PM" : "AM";

        return `${hours}:${minutes} ${ampm}`;
    };

    return (

        <div className={clsx("form-group input--md input--icon-right", wrapperClassName)}>
            <div className="input-wrapper">
                <ReactDatePicker
                    selected={timeToDate(selectedTime as string)}
                    onChange={(date: Date) => onChange(dateToTime(date)) }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat={timeFormat}
                    placeholderText={placeholder}
                    className={clsx("input input--outline w-full", className)}
                    wrapperClassName="timepicker-1 w-full"
                    showIcon
                    icon={
                        <span className="ico color-secondary">
                            <TimeIcon/>
                        </span>
                    }
                />
            </div>
            {typeof error === "string" && <p className="error-message">{error}</p>}
        </div>
    );
};

export default TimepickerOutline;
