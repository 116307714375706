import PlusIcon from "@/assets/img/plus.svg?react";
import TaskCard from "@/components/cards/TaskCard";
import DropdownActions from "@/components/common/DropdownActions";
import { Task } from "@/types";
import React from "react";

interface PlaningColumn {
    title: string;
    taskCount: number;
}

interface TasksProps {
    columns: PlaningColumn[];
    tasks: Record<string, Task[]>;
}

const Tasks: React.FC<TasksProps> = ({ columns, tasks }) => {

    const columnWidth = `${100 / columns.length}%`;

    return (
        <div className="widget-planing--list --style-2 tab-body-padding">
            {columns.map((column) => <div
                key={column.title}
                className="planing-item"
                style={{
                    width: columnWidth,
                    maxWidth: columnWidth,
                    minWidth: "170px"
                }}
            >
                <div className="planing-header">
                    <div className="planing-header-titled">
                        <p className="planing-title text--sm">{column.title}</p>
                        <div className="pill-custom pill--xs">{column.taskCount}</div>
                    </div>
                    <div className="planing-header-actions">
                        <button className="btn btn--custom-1 btn--xs rounded-full btn--square">
                            <span className="ico"><PlusIcon /></span>
                        </button>
                        <DropdownActions
                            actions={[]}
                        />
                    </div>
                </div>
                <div className="planing-body">
                    <ul className="planing--cards-list">
                        {tasks[column.title]?.map((task, index) => <li key={index} className="planing-card-item">
                            <TaskCard data={task} />
                        </li>)}
                    </ul>
                </div>
            </div>)}
        </div>
    );

};

export default Tasks;
