import BoxIcon from "@/assets/img/ico-box.svg?react";
import ChatIcon from "@/assets/img/ico-chat.svg?react";
import TeamIcon from "@/assets/img/team-icon.svg?react";

export const ICONS = {
    pm: BoxIcon,
    expediter: ChatIcon,
    supply: TeamIcon,
};
// sale,idea,design,supply,expediter,pm
