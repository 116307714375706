import { LoginRequest } from "@/types/request";
import * as yup from "yup";

export const loginSchema: yup.ObjectSchema<LoginRequest> = yup.object({
    email: yup.string().email("invalidEmailText").
        required('requiredEmailText'),
    password: yup.string().min(6, "passwordRulesText").
        required("passwordRequiredText")
});

