import { PaginationQuery } from "@/types/request";
import type { IProjectBackendData, ResponseType } from "../types";
import { baseApi } from "./baseApi";
import { objectToFormData, objectToQueryString } from "@/utils";
import { ProjectFormRequest } from "@/validation/projectSchema";


const base = "api/projects";

export const projectsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getProjectById: builder.query<
            ResponseType<IProjectBackendData>,
            { projectId: string }
        >({
            query: ({ projectId }) => `${base}/${projectId}`,
        }),
        getAllProjects: builder.query<
            ResponseType<IProjectBackendData[]>,
            PaginationQuery | void
        >({
            query: (query) => `${base}?${objectToQueryString(query)}`,
            providesTags: ["projects"]
        }),
        createProject: builder.mutation<
            ResponseType<IProjectBackendData>,
            ProjectFormRequest
        >({
            query: (body) => ({
                url: `${base}`,
                method: "Post",
                body: objectToFormData(body)
            }),
            invalidatesTags: ['projects'],
        }),
        updateProject: builder.mutation<
            ResponseType<IProjectBackendData>,
            { projectId: number, body: ProjectFormRequest }
        >({
            query: ({ projectId, body }) => ({
                url: `${base}/${projectId}`,
                method: "PUT",
                body: objectToFormData(body)
            }),
            invalidatesTags: ['projects'],
        })

    })
});

export const {
    useCreateProjectMutation,
    useGetAllProjectsQuery,
    useGetProjectByIdQuery,
    useUpdateProjectMutation
} = projectsApi;
