import bgLogin from "@/assets/img/bg-login.svg";
import Button from "@/components/common/Button";
import Input from "@/components/common/Input";
import { routerConfig } from "@/routes";
import { useLoginMutation } from "@/api/authApi";
import { LoginRequest } from "@/types/request";
import { loginSchema } from "@/validation/loginSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router";
import LoginFormHeader from "./components/LoginFormHeader";
import PromoSection from "./components/PromoSection";
import { useSelectMe } from "@/hooks/redux/selectHooks/selectMe";
import { useTranslation } from "react-i18next";
import { getErrorText } from "@/utils";


const LoginPage = () => {
    const { t } = useTranslation('', { keyPrefix: 'loginPage' });
    const me = useSelectMe();
    const navigate = useNavigate();
    const [login, { isLoading, isSuccess, reset }] = useLoginMutation();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(loginSchema)
    });

    const onSubmit = (data: LoginRequest) => {
        login(data);
    };

    useEffect(() => {

        if (isSuccess) {

            navigate(routerConfig.dashboard.path);
            reset();

        }

    }, [isSuccess]);

    if (me) {

        return <Navigate to={routerConfig.dashboard.path} />;

    }

    return (
        <div className="wrapper wrapper-bg-2" style={{ backgroundImage: bgLogin }}>
            <main className="content">
                <div className="container container--login">
                    <div className="section-login">
                        <div className="row row-login">
                            <div className="col-md-6 col-12 row-login-item">
                                <form 
                                    onSubmit={handleSubmit(onSubmit)} 
                                    className="form form-login mx-auto"
                                >
                                    <LoginFormHeader />

                                    <div className="form-body">
                                        <div className="row gutters-2">
                                            <div className="col-12">
                                                <Input
                                                    label={t('emailLabel')}
                                                    placeholder={t('emailPlaceholder')}
                                                    className="input--xl"
                                                    {...register("email")}
                                                    error={errors.email?.message ? getErrorText(errors.email?.message) : ''}

                                                />
                                            </div>

                                            <div className="col-12">
                                                <Input
                                                    label={t('passwordLabel')}
                                                    placeholder={t('passwordPlaceholder')}
                                                    className="input--xl"
                                                    type="password"
                                                    {...register("password")}
                                                    error={errors.password?.message  ? getErrorText(errors.password?.message) : ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="row gutters-2">
                                            <div className="col-12">
                                                <div className="flex items-center gap--md justify-between flex-wrap">
                                                    <label className="check-wrapper gap--md">
                                                        <input type="checkbox" name="check" hidden />
                                                        <span className="check check--xs check-custom"></span>
                                                        <span className="text--lg color-control-3">
                                                            {t('rememberText')}
                                                        </span>
                                                    </label>
                                                    <a href="#" className="btn btn-link color-primary tdu">
                                                        <span className="text--lg">
                                                            {t('forgotPasswordLinkText')}?
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-footer">
                                        <div className="flex flex-col gap--md">
                                            <Button
                                                size='extra-large'
                                                uiType="primary-advance-2"
                                                type="submit"
                                                loading={isLoading}
                                            >
                                                <span>{t('loginButtonText')}</span>
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6 col-12 hidden-992 row-login-item">
                                <PromoSection />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );

};

export default LoginPage;
