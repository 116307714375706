import { ModalProps } from "@/components/modals/Modal";
import EventData from "./components/EventData";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";

import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import { Event } from "@/types";

interface INewEventModal extends ModalProps {
    data: Event
}

const completeActionButtons = [{
    text: "Add Event",
    handler: () => { }
}, {
    text: "Complete",
    handler: () => { }
}];

export const NewEventModal = ({ data, ...props }: INewEventModal) => {

    const { currentStage,
        handleCompleteClick,
        handleContinueClick,
        handleCancel,
        handleGoToSelection
    } = useNewEntityModal(false);

    console.log("currentStage", currentStage)
    console.log("data", data)

    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New Event Created!"
            completeDescription="Congratulations on successfully creating a new event! You can now create new event or return to the home page."
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
            handleBackToSelectionClick={handleGoToSelection}
            handleCompleteClick={handleCompleteClick}
        >
            <EventData data={data} handleCancel={handleCancel} handleCompleteClick={handleCompleteClick} />
        </NewEntityModal>
    );

};
