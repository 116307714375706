import Header from "@/components/layout/Header";
import Sidebar from "@/components/layout/Sidebar";
import { Outlet } from "react-router";

const MainLayout = () => {

    return (
        <>
            <Header />
            <Sidebar />
            <div className="wrapper header-fixed sidebar-gutters">
                <Outlet />
            </div>
        </>
    );

};

export default MainLayout;
