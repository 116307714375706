import { EntityCreationStages } from "@/types/enums";
import { useCallback, useState } from "react";
import useModal from "./useModal";
import { VoidFunctionWithoutArguments } from "@/types";

type UseNewEntityModal = (firstTab?: boolean) => {
    currentStage: EntityCreationStages;
    handleContinueClick: (isCsvLoading?: boolean) => void;
    handleCompleteClick: VoidFunctionWithoutArguments;
    handleCancel: VoidFunctionWithoutArguments;
    handleGoToSelection: VoidFunctionWithoutArguments;
};

export const useNewEntityModal: UseNewEntityModal = (firsTab = true) => {

    const { closeModal } = useModal();
    const [currentStage, setCurrentStage] = useState<EntityCreationStages>( firsTab ? EntityCreationStages.SelectOption : EntityCreationStages.FillData);

    const handleContinueClick = useCallback((isCsvLoading: boolean = false) => {
        let stage = EntityCreationStages.FillData;

        if (isCsvLoading) {
            stage = EntityCreationStages.UploadCsv
        }

        setCurrentStage(stage);

    }, []);

    const handleCompleteClick = useCallback(() => {

        setCurrentStage(EntityCreationStages.Complete);

    }, []);

    const handleCancel = () => {

        closeModal();

    };

    const handleGoToSelection = useCallback(() => {

        setCurrentStage(EntityCreationStages.SelectOption);

    }, []);

    return {
        currentStage,
        handleContinueClick,
        handleCompleteClick,
        handleCancel,
        handleGoToSelection,
    };

};
