import { Status, ProjectStatus, UserRole, ContactMethod, RiskLevel, MaterialStatus, MaterialOrderStatus, FrequencyEnum, LocationEnum } from "@/types/enums";

export const DOMAIN = import.meta.env.VITE_API_URL;

interface MapDetails {
    className: string;
    label: string;
}

export const DATE_FORMAT = "MMM d, yyyy";

export const projectStatusMap: Record<ProjectStatus, MapDetails> = {
    agreement: {
        className: "pill--custom-1",
        label: "Customer Agreement"
    },
    proposal: {
        className: "pill--custom-2",
        label: "Design Proposals"
    },
    development: {
        className: "pill--custom-3",
        label: "Project Development"
    },
    expedition: {
        className: "pill--custom-4",
        label: "Permit Expedition"
    },
    "rough-in": {
        className: "pill--custom-5",
        label: "Building Rough-in"
    },
    finishes: {
        className: "pill--custom-6",
        label: "Building Finishes"
    },
    "punch-out": {
        className: "pill--custom-7",
        label: "Building Punch Out"
    },
    completed: {
        className: "pill--custom-8",
        label: "Completed"
    }
};

export const statusMap: Record<Status, MapDetails> = {
    active: {
        className: "pill--custom-1",
        label: "Active"
    },
    inactive: {
        className: "pill--custom-8",
        label: "Inactive"
    }
};
export const statusMaterialMap: Record<MaterialStatus, MapDetails> = {
    "In Stock": {
        className: "pill--custom-1",
        label: "In Stock"
    },
    'Pending': {
        className: "pill--custom-8",
        label: "Pending"
    },
    "Out of Stock": {
        className: "pill--custom-8",
        label: "Out of Stock"
    }
};
export const statusMaterialOrderMap: Record<MaterialOrderStatus, MapDetails> = {
    "Delivered": {
        className: "pill--custom-1",
        label: "Delivered"
    },
    'In Progress': {
        className: "pill--custom-8",
        label: "In Progress"
    },
    "Out of Stock": {
        className: "pill--custom-8",
        label: "Out of Stock"
    }
};
export const riskLevelMap: Record<RiskLevel, MapDetails> = {
    low: {
        className: "pill--custom-1",
        label: "Low Risk"
    },
    high: {
        className: "pill--custom-8",
        label: "High Risk"
    },
    medium: {
        className: "pill--custom-5",
        label: "Medium Risk"
    },
};

export const contactMethodMap: Record<ContactMethod, string> = {
    [ContactMethod.EMAIL]: 'Email',
    [ContactMethod.PHONE]: 'Phone',
    [ContactMethod.SMS]: 'SMS'
};

export const languages = [{
    label: "English",
    value: "en"
}, {
    label: "Française",
    value: "fr"
}, {
    label: "Italiano",
    value: "it"
}];

export const USER_ROLE: Record<UserRole, string> = {
    admin: "Office Admin",
    user: "Regular user"
};

export const frequencyMap: Record<FrequencyEnum, MapDetails> = {
    once: {
        className: "pill--custom-1",
        label: "Only once"
    },
    repeat: {
        className: "pill--custom-8",
        label: "Repeat"
    },
};
export const locationMap: Record<LocationEnum, MapDetails> = {
    physical: {
        className: "pill--custom-1",
        label: "Physical"
    },
    virtual: {
        className: "pill--custom-8",
        label: "Virtual"
    },
};
