import { departmentOptions, projectTypeOptions } from "@/constants/options";
import { csvService } from "@/services/csvService";
import { IProjectFormData, ISelecOption } from "@/types";
import { ProjectTypes, Status } from "@/types/enums";
import { checkAreNamesEqual, checkAreTextsEqual } from "@/utils";
import { useState } from "react";

export const useProjectCsvData = () => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const getDepartments = (): Promise<ISelecOption<number>[]> => new Promise(
        (resolve) => resolve(departmentOptions)); //TODO: Implement the api query after implementation on backend.
    

    const getProjectDataFromCsv = async (file: File) => {
        try {
            setIsProcessing(true);
                
            const projectDataRows = await csvService.projectParser(file);

            if (projectDataRows.length === 0) {
                return null;
            }

            const projectParsedData = projectDataRows[0];
            
            const departments = await getDepartments();
            const departmentId = departments.find(department => checkAreNamesEqual(department.label, 
                projectParsedData.departmentName))?.value;

            const {street, building, zip, unit, country, state, city} = projectParsedData;

            const address = csvService.getAddressFormDataFromParsedData(
                street, building, zip, unit, country, state, city);
            
            const projectTypeId = projectTypeOptions.find(type => checkAreTextsEqual(
                type.value, projectParsedData.type))?.value;
            
            const deadline = isNaN(Date.parse(projectParsedData.deadline)) 
                ? '' : projectParsedData.deadline;

            const photos = projectParsedData.photos?.split(' ');

            const status = csvService.getStatusValue(projectParsedData.status);

            const parsedProject: IProjectFormData = {
                type: projectTypeId as ProjectTypes,
                name: projectParsedData.name,
                deadline,
                estVolume: Number.parseFloat(projectParsedData.estVolume),
                departmentId,
                status: status as Status,
                description: projectParsedData.description,
                cover: projectParsedData.cover,
                photos: photos || [],
                contracts: [],
                address,
            }

            return parsedProject;
        }  catch(error) {
            console.error(error);
        } finally {
            setIsProcessing(false);
        }

    }

    return {
        isProcessing,
        getProjectDataFromCsv,
    }
}